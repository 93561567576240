import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import { VueEasyJwt } from 'vue-easy-jwt'
const jwt = new VueEasyJwt()

Vue.use(VueRouter)

const routes = [
  /* Rutas Ecommerce */
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/sucursales',
    name: 'Sucursales',
    component: () => import(/* webpackChunkName: "about" */ '../views/InformacionEmpresa/Sucursales.vue')
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: () => import(/* webpackChunkName: "about" */ '../views/InformacionEmpresa/Contacto.vue')
  },
  {
    path: '/articulos/:categoria',
    name: 'Articulos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Articulos.vue')
  },
  {
    path: '/articulos/:categoria/:subCategoria',
    name: 'SubArticulos',
    component: () => import(/* webpackChunkName: "about" */ '../views/SubArticulos.vue')
  },
  {
    path: '/buscarArticulo/:busqueda',
    name: 'BuscarArticulo',
    component: () => import(/* webpackChunkName: "about" */ '../views/BuscarArticulo.vue')
  },
  {
    path: '/carritoDetalle',
    name: 'CarritoDetalle',
    component: () => import(/* webpackChunkName: "about" */ '../views/CheckOut/CarritoDetalle.vue')
  },
  {
    path: '/articuloDetalle/:keyFirebase',
    name: 'ArticuloDetalle',
    component: () => import(/* webpackChunkName: "about" */ '../views/UnArticulo.vue')
  },
  {
    path: '/misDatos',
    name: 'MisDatos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Cliente/misDatos.vue')
  },
  {
    path: '/misPedidos',
    name: 'MisPedidos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Cliente/misPedidos.vue')
  },
  {
    path: '/checkout',
    name: 'CheckOut',
    component: () => import(/* webpackChunkName: "about" */ '../views/CheckOut/CheckOut.vue')
  },
  {
    path: '/misPrestamos/:id',
    name: 'Mis Prestamos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Cliente/misPrestamos.vue')
  },
  
  /* Fin rutas Ecommerce */

  /* Rutas Admin */
  {
    path: '/admin',
    name: 'Administracion',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Inicio.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/estadisticas',
    name: 'Estadisticas',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Estadisticas.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/ventas',
    name: 'VentasIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/VentasAdmin.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/articulos',
    name: 'ArticulosAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ArticulosAdmin.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/clientes',
    name: 'ClientesAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ClientesAdmin.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/configuraciones/banners',
    name: 'BannersAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Banners.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/configuraciones/datosContacto',
    name: 'DatosContactoAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/DatosContacto.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/configuraciones/localidades',
    name: 'LocalidadesAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/LocalidadesAdmin.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/configuraciones/sucursales',
    name: 'SucursalesAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/SucursalesAdmin.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/login',
    name: 'LoginAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Login.vue')
  },
  {
    path: '/admin/configuraciones/publicidad',
    name: 'PublicidadAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/PublicidadAdmin.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/configuraciones/carruseles',
    name: 'CarruselesAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/CarruselesAdmin.vue'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/configuraciones/categorias',
    name: 'CategoriasAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/CategoriasAdmin'),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  {
    path: '/admin/configuraciones/menus',
    name: 'MenusAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/MenusAdmin'),
    meta: { requireAuth: true, rutaProtegida: true }
  }
  /* Fin Rutas Admin */
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)){
    let dirigirLogin = 0
    let msjDirigir = ''
    // control del token de la Api de Jarvis
    let tokenPeticion = await store.dispatch('usuarios/executeLoginApi')
    if (tokenPeticion.resultado == 0){
      dirigirLogin = 1
      msjDirigir = tokenPeticion.msj
    }
    if (!isAuth() && dirigirLogin == 0){
      dirigirLogin = 1
      msjDirigir = 'Su sesión se encuentra vencida. Por favor, vuelva a ingresar al Sistema.'
    }
    if (dirigirLogin == 1){
      // redirecciono al Login
      store.dispatch('executeSnackBar', {msj: msjDirigir, color: 'info', activo: true})
      next({ path: '/admin/login' })
      return
    }
  }
  next()
})

function isAuth(){
  if (!localStorage.getItem('tokenAdmin')) return false
  else{
    // el token es válido?
    let token = JSON.parse(localStorage.getItem('tokenAdmin'))
    let expirado = jwt.isExpired(token)
    if (expirado == true){
      // saco de localStorage la clave del token de Firebase
      localStorage.removeItem('tokenAdmin')
      // marco dirigirLogin para que redireccione
      return false
    }else return true
  }
}

export default router
