import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css'
import VueMask from 'v-mask'
import VueEasyJwt from 'vue-easy-jwt'
import VueTheMask from 'vue-the-mask'
import VuetifyMask from "vuetify-mask";  

Vue.use(VuetifyMask);
Vue.use(VueTheMask)
Vue.use(VueMask);
Vue.use(VueAwesomeSwiper);
Vue.use(VueLoaders);
Vue.use(VueAxios, axios)
Vue.use(VueEasyJwt)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
