<template>
  <div class="text-center">
    <v-dialog
      v-model="activo"
      hide-overlay
      persistent
      fullscreen
      light
      content-class="loading-dialog"
    >
      <v-container fill-height>
        <v-row 
          align-content="center" 
          justify="center" 
          align-content-sm="center"
          align-content-md="center"
          align-content-lg="center"
          align-content-xl="center"
          justify-xl="center"
          justify-sm="center"
          justify-lg="center"
          justify-md="center"
        >
            <vue-loaders-ball-beat color="red" scale="1"></vue-loaders-ball-beat>
        </v-row>
      </v-container>
        
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      activo: Boolean
    },
    data () {
      return {

      }
    },

    watch: {

    },
  }
</script>

<style>
  .loading-dialog{
    background-color: #303030
  }
</style>