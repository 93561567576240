import store from "../store"
import config from "../../config"

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getArrayArticulos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let carrusel = []
          let listado = []
          let categoriasArticulos = []
          // controlo la opcion
          if (parseInt(datos.opcion) > 0){
            if (datos.opcion == 1){
              for (let id in datos.articulos){
                if (datos.articulos[id].cat_ecommerce.length > 0){
                  let cont = 0
                  // recorro el array de categorias
                  for (let pos in datos.articulos[id].cat_ecommerce){
                    if (parseInt(datos.articulos[id].cat_ecommerce[pos].idPadre) === parseInt(datos.categoria)){
                      cont = cont + 1
                    }
                  }
                  // controlo a ver si corresponde agregar
                  if (parseInt(cont) > 0){
                    // tengo que apregar el articulo
                    carrusel.push(datos.articulos[id])
                  }
                }
              }
            }else if (datos.opcion == 2){
              for (let id in datos.articulos){
                if (datos.articulos[id].cat_ecommerce.length > 0){
                  let cont = 0
                  // recorro el array de categorias
                  for (let pos in datos.articulos[id].cat_ecommerce){
                    if (parseInt(datos.articulos[id].cat_ecommerce[pos].idHijo) === parseInt(datos.categoria)){
                      cont = cont + 1
                    }
                  }
                  // controlo a ver si corresponde agregar
                  if (parseInt(cont) > 0){
                    // tengo que apregar el articulo
                    carrusel.push(datos.articulos[id])
                  }
                }
              }
            }else{
              carrusel = datos.articulos.filter(element => parseInt(element.nuevo_producto) == 1)
            }
            // ordeno descendiente el array por codigo de articulo
            carrusel.sort(function(a,b){
              if (parseInt(a.art_codigo) > parseInt(b.art_codigo)) {
                return -1;
              }
              if (parseInt(a.art_codigo) < parseInt(b.art_codigo)) {
                return 1;
              }
              // a must be equal to b
              return 0;
            })
            // armo la estructura que necesito
            if (carrusel.length > parseInt(config.TAM_MAX_CARRUSEL_ARTS)){ // 25 es la cantidad maxima de elementos para el carrusel
              let indice = 0
              do{
                let listadoImagenes = []
                for (let pos in carrusel[indice].imagenes_mini){
                  let unaImagen = {
                    src: carrusel[indice].imagenes_mini[pos].url
                  }
                  listadoImagenes.push(unaImagen)
                }

                let unArticulo = {
                  articuloNombre: carrusel[indice].art_nom_e,
                  articuloRubro: carrusel[indice].art_rubro_nom,
                  articuloCategoria: carrusel[indice].art_categoria_nom,
                  articuloPrecio: carrusel[indice].precios,
                  articuloDescuento: carrusel[indice].descuento,
                  articuloNuevo: carrusel[indice].nuevo_producto,
                  articuloKey: carrusel[indice].key_firebase,
                  precioNeto: carrusel[indice].precios,
                  articuloMarcaCodigo: carrusel[indice].art_marca,
                  articuloModeloCodigo: carrusel[indice].art_modelo,
                  articuloCatCodigo: carrusel[indice].art_categoria,
                  articuloCaracteristicas: carrusel[indice].caracteristicas,
                  articuloDescripCorta: carrusel[indice].art_desc_corta,
                  cantidad: 1,
                  articuloImagenes: listadoImagenes,
                  stock: carrusel[indice].stock,
                  errorStock: false,
                  msjErrorStock: '',
                  articuloCodigo: carrusel[indice].art_codigo
                }
                if (listado.indexOf(unArticulo) === -1){
                  listado.push(unArticulo)
                }
                indice = indice + 1
              }while(parseInt(indice) <= parseInt(config.TAM_MAX_CARRUSEL_ARTS) - 1)
            }else{
              for (let id in carrusel){
                let listadoImagenes = []
                for (let pos in carrusel[id].imagenes_mini){
                  let unaImagen = {
                    src: carrusel[id].imagenes_mini[pos].url
                  }
                  listadoImagenes.push(unaImagen)
                }

                let unArticulo = {
                  articuloNombre: carrusel[id].art_nom_e,
                  articuloRubro: carrusel[id].art_rubro_nom,
                  articuloCategoria: carrusel[id].art_categoria_nom,
                  articuloPrecio: carrusel[id].precios,
                  articuloDescuento: carrusel[id].descuento,
                  articuloNuevo: carrusel[id].nuevo_producto,
                  articuloKey: carrusel[id].key_firebase,
                  precioNeto: carrusel[id].precios,
                  articuloMarcaCodigo: carrusel[id].art_marca,
                  articuloModeloCodigo: carrusel[id].art_modelo,
                  articuloCatCodigo: carrusel[id].art_categoria,
                  articuloCaracteristicas: carrusel[id].caracteristicas,
                  articuloDescripCorta: carrusel[id].art_desc_corta,
                  cantidad: 1,
                  articuloImagenes: listadoImagenes,
                  stock: carrusel[id].stock,
                  errorStock: false,
                  msjErrorStock: '',
                  articuloCodigo: carrusel[id].art_codigo
                }
                if (listado.indexOf(unArticulo) === -1){
                  listado.push(unArticulo)
                }
              }
            }
          }else{
            for (let id in datos.articulos){
              let listadoImagenes = []
              // leo imagenes grandes o mini?
              if (datos.imgGrandes == 0){
                for (let pos in datos.articulos[id].imagenes_mini){
                  let unaImagen = {
                    src: datos.articulos[id].imagenes_mini[pos].url
                  }
                  listadoImagenes.push(unaImagen)
                }
              }else{
                if (datos.articulos[id].hasOwnProperty('imagenes') && Array.isArray(Object.values(datos.articulos[id].imagenes))){
                  // contiene imagenes el campo?
                  for (let jd in datos.articulos[id].imagenes){
                    if (datos.articulos[id].imagenes[jd].url != null && datos.articulos[id].imagenes[jd].url != undefined && datos.articulos[id].imagenes[jd].url.length > 0){
                      let unaImagen = {
                        src: datos.articulos[id].imagenes[jd].url
                      }
                      listadoImagenes.push(unaImagen)
                    }
                  }
                }
                // si no consigo imagenes grandes, le pongo las minis
                if (listadoImagenes.length === 0 && datos.articulos[id].hasOwnProperty('imagenes_mini') && Array.isArray(Object.values(datos.articulos[id].imagenes_mini))){
                  for (let jd in datos.articulos[id].imagenes_mini){
                    if (datos.articulos[id].imagenes_mini[jd].url != null && datos.articulos[id].imagenes_mini[jd].url != undefined && datos.articulos[id].imagenes_mini[jd].url.length > 0){
                      let unaImagen = {
                        src: datos.articulos[id].imagenes_mini[jd].url
                      }
                      listadoImagenes.push(unaImagen)
                    }
                  }
                }
              }
              // descuento
              let descuento = 0
              if (datos.articulos[id].descuento != '' && datos.articulos[id].descuento != "" && datos.articulos[id].descuento != null && datos.articulos[id].descuento != undefined && datos.articulos[id].descuento.length > 0){
                descuento = datos.articulos[id].descuento
              }
              let unArticulo = {
                articuloNombre: datos.articulos[id].art_nom_e,
                articuloRubro: datos.articulos[id].art_rubro_nom,
                articuloCategoria: datos.articulos[id].art_categoria_nom,
                articuloPrecio: datos.articulos[id].precios,
                articuloDescuento: descuento,
                articuloNuevo: datos.articulos[id].nuevo_producto,
                articuloKey: datos.articulos[id].key_firebase,
                flex: 3,
                articuloCaracteristicas: datos.articulos[id].caracteristicas,
                articuloMarca: datos.articulos[id].art_marca_nom,
                precioNeto: datos.articulos[id].precios,
                articuloMarcaCodigo: datos.articulos[id].art_marca,
                articuloModeloCodigo: datos.articulos[id].art_modelo,
                articuloCatCodigo: datos.articulos[id].art_categoria,
                articuloDescripCorta: datos.articulos[id].art_desc_corta,
                cantidad: 1,
                articuloImagenes: listadoImagenes,
                stock: datos.articulos[id].stock,
                errorStock: false,
                msjErrorStock: '',
                articuloCodigo: datos.articulos[id].art_codigo
              }
              if (listado.indexOf(unArticulo) === -1){
                listado.push(unArticulo)
                if (datos.articulos[id].hasOwnProperty('cat_ecommerce') && Array.isArray(datos.articulos[id].cat_ecommerce) && datos.articulos[id].cat_ecommerce.length > 0){
                    // lo recorro y armo mi array de categorias
                    for (let i in datos.articulos[id].cat_ecommerce){
                      if (categoriasArticulos.indexOf(datos.articulos[id].cat_ecommerce[i].idHijo) === -1){
                        categoriasArticulos.push(datos.articulos[id].cat_ecommerce[i].idHijo)
                      }
                    }
                }
              }
            }
          }
          return resolve({
            resultado: 1,
            msj: 'OK',
            listado: listado,
            categoriasArticulos: categoriasArticulos
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getArrayArticulos: ' + error.message,
            listado: null,
            categoriasArticulos: null
          })
        }
      })
    }
  }
}