<template>
  <div :style="movil ? 'padding-top: 850px' : 'padding-top: 250px'">
    <!-- Footer -->
    <v-footer
      class="justify-center mt-12 pt-0"
      dark absolute
      :fixed="sinArticulos"
      color="secondary"
    >
      <v-row class="pa-0 ma-0">
        <!-- Productos -->
        <v-col cols="12" md="4" class="mb-0 pb-0">
          <v-card flat color="secondary" max-width="auto">
            <v-card-title class="formatoPalabras" :style="movil == true ? 'justify-content: center' : 'justify-content: left'">
              <strong>Productos</strong>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  :cols="cantColumnas"
                  v-for="(item, index) in columnas" :key="index"
                  class="pb-0"
                >
                  <p v-for="menuItem in item" :key="menuItem.nombre" style="line-height: 5px;"><a :href="menuItem.to" class="formatoPalabras menuSinResaltar white--text">{{ menuItem.nombre }}</a></p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-divider vertical v-if="!movil"></v-divider>
        <v-divider v-else></v-divider>
        <!-- Comprar -->
        <v-col cols="12" md="3" class="mb-0 pb-0">
          <v-card flat color="secondary" max-width="auto">
            <v-card-title class="formatoPalabras" :style="movil == true ? 'justify-content: center' : 'justify-content: left'">
              <strong>Comprar</strong>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a @click="verTerminosCondiciones" class="formatoPalabras menuSinResaltar white--text">Como comprar</a></p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a @click="verTerminosCondiciones" class="formatoPalabras menuSinResaltar white--text">Términos y Condiciones</a></p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a @click="consultarPrestamo()" class="formatoPalabras menuSinResaltar white--text">Consultar Prestamo</a></p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0" :style="movil == true ? 'text-align: center' : 'text-align: left'">
                  <v-btn text outlined rounded @click="registrarArrepentimiento">Botón de Arrepentimiento</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-divider vertical v-if="!movil"></v-divider>
        <v-divider v-else></v-divider>
        <!-- Contacto -->
        <v-col cols="12" md="2" class="mb-0 pb-0">
          <v-card flat color="secondary" max-width="auto">
            <v-card-title class="formatoPalabras" :style="movil == true ? 'justify-content: center' : 'justify-content: left'">
              <strong>Contacto</strong>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a href="/sucursales" class="formatoPalabras menuSinResaltar white--text">Sucursales</a></p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a href="/contacto" class="formatoPalabras menuSinResaltar white--text">Contacto</a></p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a :href="`https://api.whatsapp.com/send?phone=${contacto.telefono}&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre`" target="_blank" class="formatoPalabras menuSinResaltar white--text">Whatsapp</a></p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a :href="`mailto:${contacto.email}?subject= >Consulta`" class="formatoPalabras menuSinResaltar white--text">Correo</a></p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a :href="`${contacto.instagram}`" target="_blank" class="formatoPalabras menuSinResaltar white--text">Instagram</a></p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a :href="`${contacto.facebook}`" target="_blank" class="formatoPalabras menuSinResaltar white--text">Facebook</a></p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-divider vertical v-if="!movil"></v-divider>
        <v-divider v-else></v-divider>
        <!-- Mayorista -->
        <v-col cols="12" md="3" class="mb-0 pb-0">
          <v-card flat color="secondary" max-width="auto">
            <v-card-title class="formatoPalabras" :style="movil == true ? 'justify-content: center' : 'justify-content: left'">
              <strong>Mayorista</strong>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a :href="`https://api.whatsapp.com/send?phone=${contacto.telefono}&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre`" class="formatoPalabras menuSinResaltar white--text">Whatsapp</a></p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <p :style="movil == true ? 'text-align: center; line-height: 5px' : 'text-align: left; line-height: 5px'"><a :href="`mailto:${contacto.email_mayorista}?subject= >Consulta`" class="formatoPalabras menuSinResaltar white--text">Correo</a></p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-row align-content="center" justify="center" class="ma-2">
          <v-col cols="12" class="pb-0 pt-0">
            <h4 class="text-center formatoPalabras" style="font-weight: normal; font-size: 11px">Copyright © Todos los Derechos Reservados | Borigen Betzel</h4>
          </v-col>
        </v-row>
      </v-row>
    </v-footer>
    <!-- Dialog Terminos y Condiciones -->
    <v-dialog v-model="dialogTerminos" scrollable max-width="70%">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-row align="center" justify="center">
            <h1 style="color: #ffffff">Celushop</h1>
          </v-row>
        </v-toolbar>
        <v-card-text>
          <Terminos />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog Arrepentimiento Compra -->
    <v-dialog
      v-model="dialogArrepentimiento"
      persistent
      max-width="600"
      dark
      hide-overlay
    >
      <v-card style="border-radius:20px;" color="accent">
        <v-card-title style="word-break: normal; color: #fb0021; font-size: 25px" class="text-center formatoPalabras">
          <strong>Arrepentimiento de Compra</strong>
        </v-card-title>
        <v-card-text style="font-size: 15px" class="formatoPalabras">
          <div>
            <p class="black--text" style="word-break: normal;" align="justify">
              Podrás solicitar la cancelación de tu compra hasta <strong>10 días corridos</strong> a partir de la entrega/recepción del producto.
            </p>
            <p class="black--text" style="word-break: normal;" align="justify">
              Si compraste en <a href="http://www.celushop.com.ar" style="color: #2196F3" target="_blank">www.celushop.com.ar</a> <strong>o de forma telefónica</strong>, envía un correo a <a href="mailto:ventasonline@celushop.com.ar?subject= >Consulta" style="color: #2196F3" target="_blank">ventasonline@celushop.com.ar</a>.
            </p>
            <p class="black--text" style="word-break: normal;" align="justify">
              Si compraste en una sucursal de <strong>CELUSHOP</strong> y el producto presenta alguna falla, acercarte a la misma para solicitar la cancelación de tu compra.
            </p>
            <p class="black--text" style="word-break: normal;" align="justify">
              Si compraste un producto de <strong>CELUSHOP</strong> por Mercado libre o Pedidos Ya, solicita la cancelación de tu compra desde la web de la misma.
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click="dialogArrepentimiento = false"
            rounded
            class="black--text"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Buscar Prestamos -->
    <modalBuscarPrestamo 
      :datos="dialogPrestamos"
      @setearModalPrestamos="setModalPrestamos"
    />
  </div>
</template>

<script>
import modalBuscarPrestamo from '../cliente/modalBuscarPrestamo.vue'
import Terminos from '../genericos/Terminos.vue'
import { mapState } from 'vuex'
  export default {
    data: () => ({
      dialogTerminos: false,
      dialogArrepentimiento: false,
      sinArticulos: false,
      movil: false,
      tamMenus: 0,
      cantColumnas: 0,
      columnas: [],
      dialogPrestamos: {
        activo: false
      },
      contacto: {}
    }),
    props: {
      listadoNulo: Boolean,
      menus: Array
    },
    components: {
      Terminos,
      modalBuscarPrestamo
    },
    computed: {
      ...mapState(['respContacto'])
    },
    created () {
      this.isMovil()
    },
    methods: {
      verTerminosCondiciones(){
        this.dialogTerminos = !this.dialogTerminos
      },
      registrarArrepentimiento(){
        this.dialogArrepentimiento = !this.dialogArrepentimiento
      },
      isMovil(){
        var mobile = {
          Android: function() {
            return navigator.userAgent.match(/Android/i);
          },
          BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
          },
          iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
          },
          Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
          },
          Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
          },
          any: function() {
            return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
          }
        }
        // controlo la salida de mobile
        if (Array.isArray(mobile.any())){
          if (mobile.any().length > 0){
            this.movil = true
          }
        }
      },
      consultarPrestamo(){
        this.dialogPrestamos.activo = true
      },
      setModalPrestamos(value){
        this.dialogPrestamos.activo = value
      }
    },
    watch: {
      listadoNulo: function(){
        this.sinArticulos = this.listadoNulo
      },
      menus: function(){
        if (this.menus.length > 0){
          // obtengo la cantidad de menus a mostrar en el footer
          this.tamMenus = this.menus.length
          // determino cuantas columnas voy a tener
          let cantColumns = ~~(parseInt(this.tamMenus)/5)
          let resto = (parseInt(this.tamMenus)%5)
          if (parseInt(resto) > 0){
            cantColumns = cantColumns + 1
          }
          this.cantColumnas = ~~(12/cantColumns)
          let restoColumna = 12%cantColumns
          if (parseInt(restoColumna) > 0){
            this.cantColumnas = this.cantColumnas + 1
          }
          // armo mi array de menus
          this.columnas = []
          let listado = []
          let cont = 0
          for (let id in this.menus){
            listado.push(this.menus[id])
            cont = cont + 1
            if (cont == 5){
              cont = 0
              this.columnas.push(listado)
              listado = []
            }
          }
        }
      },
      respContacto: function(){
        if (Object.keys(this.respContacto).length > 0){
          if (parseInt(this.respContacto.resultado) === 1){
            this.contacto = this.respContacto.contacto
          }else{
            console.log('te falle we contactos')
          }
        }
      }
    },
  }
</script>

<style>
  .row justify-space-around{
    display: flex;
    flex-wrap: wrap;
  }
  .menuSinResaltar{
    text-decoration: none;
  }
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>