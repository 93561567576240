import Vue from 'vue'
import Vuex from 'vuex'
import config from '../../config.js'
import {storage, firebase} from '../firebase'
import moment from 'moment'
import {Encrypt, Decrypt} from '../util/aes'
import router from '../router'
import { VueEasyJwt } from 'vue-easy-jwt'
const jwt = new VueEasyJwt()

// modulos
import cobros from '../modules/cobros'
import pedidos from '../modules/pedidos'
import articulos from '../modules/articulos'
import usuarios from '../modules/usuarios'
import firebaseService from '../modules/firebaseService'
import rapiCompra from '../modules/rapiCompra.js'
import admin from '../modules/admin.js'
import genericos from '../modules/genericos.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    respItemsMenu: {},
    respContacto: {},
    respBanner: {},
    respArticulosEcommerce: {},
    respCategoriaActual: {},
    respSubCategoriaActual: {},
    respArtsEcommerceCat: {},
    respArtsEcommerceSubCat: {},
    respArtsCatBusqueda: {},
    respCatActualFiltro: {},
    articulosCarrito: [],
    respArticulosAsociados: {},
    respSucursales: {},
    respUnArticulo: {},
    respConfigCarruseles: {},
    respDashAdmin: {},
    respEstadosPedidos: {},
    respEstadosCobros: {},
    respUsuarioAdmin: {},
    respVentasEcommerce: {},
    respEstadoPedido: {},
    respArtsAdmin: {},
    respUpdateArt: {},
    respUploadImgAdmin: {},
    respNewBanner: {},
    respUpdateBanner: {},
    respContactoAdmin: {},
    respLocalidadesAdmin: {},
    respSucursalesAdmin: {},
    respSetLocalAdmin: {},
    respPublicidadAdmin: {},
    respNewPublicidad: {},
    respUpdatePublicidad: {},
    respCarruselesAdmin: {},
    respAddCarruselAdmin: {},
    respUpdateCarrAdmin: {},
    authError: '',
    datosUsuario: {},
    usuario:'',
    errorResetPaswordMsj: '',
    resetPaswordMsj: '',
    loadingCarga: false,
    myData: {},
    respUpdateMydata: {},
    respPedidosCliente: {},
    respDatosApiJarvis: {},
    respProvincias: {},
    respLocalidades: {},
    respDatosEntregaCliente: {},
    respArtsNoCarrito: {},
    respExistsUser: {},
    respStartBuy: {},
    respActUserMisPedidos: {},
    respActUserMisDatos: {},
    respControlUsuActivo: {},
    respStatusUser: {},
    respFinanciaciones: {},
    respLocalSucursal: {},
    respCancelPayment: {},
    respUpdateSale: {},
    respCategoriasAdmin: {},
    respSubCategoriasAdmin: {},
    respUpCatAdmin: {},
    respNewCatEcommerceOracle: {},
    respNewCatPadreEcomOracle: {},
    respUpCatOracleAdmin: {},
    respMenusAdmin: {},
    respUpMenuAdmin: {},
    respEmail: {},
    respEmailPetition: {},
    snackBar: {
      activo: false,
      color: '',
      msj: ''
    },
    tokenApi: '',
    tokenAdmin: '',
    menuAdmin: {},
    menuAdminNombres: [],
    loadingCircular: {
      activo: false,
      msj: ''
    },
    imgPerfil: ''
  },
  mutations: {
    setDatosApi(state, payload){
      state.respDatosApiJarvis = payload
    },
    setItemsMenu(state, payload){
      state.respItemsMenu = payload
    },
    setContacto(state, payload){
      state.respContacto = payload
    },
    setBanners(state, payload){
      state.respBanner = payload
    },
    setArticulosEcommerce(state, payload){
      state.respArticulosEcommerce = {}
      state.respArticulosEcommerce = payload
    },
    setCategoriaActual(state, payload){
      state.respCategoriaActual = payload
    },
    setArtsEcommerceCat(state, payload){
      state.respArtsEcommerceCat = payload
    },
    setCategoriaFiltros(state, payload){
      state.respCatActualFiltro = payload
    },
    setCodSubCategoria(state, payload){
      state.respSubCategoriaActual = payload
    },
    setArtsEcommerceSubCat(state, payload){
      state.respArtsEcommerceSubCat = payload
    },
    setCarrito(state, payload){
      // esta en localStorage?
      if (localStorage.getItem('carritoUsuario')){
        localStorage.removeItem('carritoUsuario')
        localStorage.setItem('carritoUsuario', JSON.stringify(payload))
      }
      state.articulosCarrito = payload
    },
    setArticuloCarrito(state, payload){
      state.articulosCarrito.push(payload)
    },
    removeArticuloCarrito(state, payload){
      let pos = -1
      for (let id in state.articulosCarrito){
        if (state.articulosCarrito[id].articuloKey == payload.articuloKey){
          pos = id
        }
      }
      if (parseInt(pos) >= 0){
        state.articulosCarrito.splice(pos,1)
      }
    },
    setArticulosAsociados(state, payload){
      state.respArticulosAsociados = payload
    },
    setSucursales(state, payload){
      state.respSucursales = payload
    },
    setCategoriasBusqueda(state, payload){
      state.respArtsCatBusqueda = payload
    },
    setUnArticulo(state, payload){
      state.respUnArticulo = payload
    },
    setConfigCarruseles(state, payload){
      state.respConfigCarruseles = payload
    },
    setErrorResetPasword(state,payload){
      state.errorResetPaswordMsj = payload
    },
    setResetPasword(state,payload){
      state.resetPaswordMsj = payload
    },
    setLoading(state, payload){
      state.loadingCarga = payload
    },
    setMyData(state, payload){
      state.myData = payload
    },
    setUpdateMyData(state, payload){
      state.respUpdateMydata = payload
    },
    setPedidosCliente(state, payload){
      state.respPedidosCliente = payload
    },
    setProvincias(state, payload){
      state.respProvincias = payload
    },
    setLocalidades(state, payload){
      state.respLocalidades = payload
    },
    setDatosEntregaCliente(state, payload){
      state.respDatosEntregaCliente = payload
    },
    setArtsNoCarrito(state, payload){
      state.respArtsNoCarrito = payload
    },
    setExistsUser(state, payload){
      state.respExistsUser = payload
    },
    setStartBuy(state, payload){
      state.respStartBuy = payload
    },
    setActiveUserMisPedidos(state, payload){
      state.respActUserMisPedidos = payload
    },
    setActiveUserMisDatos(state, payload){
      state.respActUserMisDatos = payload
    },
    setControlUsuarioActivo(state, payload){
      state.respControlUsuActivo = payload
    },
    setStatusUser(state, payload){
      state.respStatusUser = payload
    },
    setFinanciaciones(state, payload){
      state.respFinanciaciones = payload
    },
    setEmailResultado(state, payload){
      state.respEmail = payload
    },
    setEmailPetition(state, payload){
      state.respEmailPetition = payload
    },
    /* Admin */
    setDashBoardAdmin(state, payload){
      state.respDashAdmin = payload
    },
    setEstadosPedidos(state, payload){
      state.respEstadosPedidos = payload
    },
    setEstadosCobros(state, payload){
      state.respEstadosCobros = payload
    },
    setUsuarioAdmin(state, payload){
      // guardo en LocalStorage
      if (parseInt(payload.resultado) === 1){
        localStorage.setItem('tokenAdmin', JSON.stringify(payload.token))
      }
      state.respUsuarioAdmin = payload
    },
    setVentasEcommerce(state, payload){
      state.respVentasEcommerce = payload
    },
    setEstadoPedido(state, payload){
      state.respEstadoPedido = payload
    },
    setArticulosAdmin(state, payload){
      state.respArtsAdmin = payload
    },
    setUpdateArtEcommerce(state, payload){
      state.respUpdateArt = payload
    },
    setUploadImages(state, payload){
      state.respUploadImgAdmin = payload
    },
    setNewBanner(state, payload){
      state.respNewBanner = payload
    },
    setUpdateBanner(state, payload){
      state.respUpdateBanner = payload
    },
    setContactoAdmin(state, payload){
      state.respContactoAdmin = payload
    },
    setUpdateLocalidad(state, payload){
      state.respLocalidadesAdmin = payload
    },
    setSucursalesAdmin(state, payload){
      state.respSucursalesAdmin = payload
    },
    setNewLocalResp(state, payload){
      state.respSetLocalAdmin = payload
    },
    setPublicidadAdmin(state, payload){
      state.respPublicidadAdmin = payload
    },
    setNewPublicidad(state, payload){
      state.respNewPublicidad = payload
    },
    setUpdatePublicidad(state, payload){
      state.respUpdatePublicidad = payload
    },
    setCarruselesAdmin(state, payload){
      state.respCarruselesAdmin = payload
    },
    setAddCarruselAdmin(state, payload){
      state.respAddCarruselAdmin = payload
    },
    setUpdateCarruselAdmin(state, payload){
      state.respUpdateCarrAdmin = payload
    },
    setAuthError(state, payload){
      state.authError = payload
    },
    setTokenID(state, payload){
      // exitoso?
      if (parseInt(payload.resultado) === 1){
        // es logout?
        if (parseInt(payload.tipo) != 3){
            if (payload.hasOwnProperty('datosCliente')){
              // vino con datos?
              if (payload.datosCliente.hasOwnProperty('localidad')){
                if (payload.datosCliente.localidad.hasOwnProperty('provincia')){
                  if (payload.datosCliente.localidad.provincia.length > 0){
                    let datosEntrega = {
                      provinciaId: '',
                      provinciaNombre: payload.datosCliente.localidad.provincia,
                      retiroSucursal: '',
                      sucursalCodigo: '',
                      codigoPostal: payload.datosCliente.localidad.codigo_postal,
                      entregaDomicilio: '',
                      localidadId: '',
                      localidadNombre: payload.datosCliente.localidad.localidad
                    }
                    if (localStorage.getItem('enviarA')){
                      let datosLocales = JSON.parse(localStorage.getItem('enviarA'))
                      if (parseInt(datosLocales.codigoPostal) !== parseInt(datosEntrega.codigoPostal)){
                        localStorage.removeItem('enviarA')
                        localStorage.setItem('enviarA', JSON.stringify(datosEntrega))
                      }
                    }else{
                      localStorage.setItem('enviarA', JSON.stringify(datosEntrega))
                    }
                  }
                }
              }
            }
        }else{
          // remuevo los datos del usuario
          //localStorage.removeItem('datosUsuario')
        }
      }/*else{
        if (localStorage.getItem('datosUsuario')){
          // quitos los datos que ya existian
          localStorage.removeItem('datosUsuario')
        }
      }*/
      state.datosUsuario = payload
    },
    setUsuario(state, payload){
      state.usuario = payload
    },
    setLocalesSucursal(state, payload){
      state.respLocalSucursal = payload
    },
    setAnulacionCobro(state, payload){
      state.respCancelPayment = payload
    },
    setUpdateSale(state, payload){
      state.respUpdateSale = payload
    },
    setCategoriasAdmin(state, payload){
      state.respCategoriasAdmin = payload
    },
    setSubCategoriasEcommerce(state, payload){
      state.respSubCategoriasAdmin = payload
    },
    setUpdateCatAdmin(state, payload){
      state.respUpCatAdmin = payload
    },
    setNewCatEcommerce(state, payload){
      state.respNewCatEcommerceOracle = payload
    },
    setNewCatPadreEcommerce(state, payload){
      state.respNewCatPadreEcomOracle = payload
    },
    setUpCategoriasOracleAdmin(state, payload){
      state.respUpCatOracleAdmin = payload
    },
    setMenusAdmin(state, payload){
      state.respMenusAdmin = payload
    },
    setUpdateMenuAdmin(state, payload){
      state.respUpMenuAdmin = payload
    },
    setSnackBarAviso(state, payload){
      state.snackBar.msj = payload.msj
      state.snackBar.color = payload.color
      state.snackBar.activo = payload.activo
    },
    setLocalStorage(state, payload){
      // token de la Api de Jarvis
      if (localStorage.getItem('datosApiJarvis')) state.tokenApi = JSON.parse(localStorage.getItem('datosApiJarvis')).tokenJarvis
      else state.tokenApi = ''
      // token de la autenticación de Firebase
      if (localStorage.getItem('tokenAdmin')) state.tokenAdmin = JSON.parse(localStorage.getItem('tokenAdmin'))
      else state.tokenAdmin = ''
      // guardo info adicional?
      if (payload != null && payload != undefined && payload.hasOwnProperty('meta') && payload.meta != null && payload.meta != undefined && Object.keys(payload.meta).length > 0 &&
      payload.meta.requireAuth == true){
        // guardo el tema en el navegador si es que no tiene nada
        if (!localStorage.getItem('dark')) localStorage.setItem('dark', JSON.parse(true))
      }
    },
    setMenuAdmin(state, payload){
      state.menuAdminNombres = []
      if (localStorage.getItem('menuAdmin')) localStorage.removeItem('menuAdmin')
      // seteo el menu
      localStorage.setItem('menuAdmin', JSON.stringify(payload))
      state.menuAdmin = payload
      // obtengo todos los nombres de los menus
      for (let id in payload){
        // controlo el primer nivel
        if (payload[id].nombre != null && payload[id].nombre != undefined && payload[id].nombre.toString().length > 0 && payload[id].to != null && payload[id].to != undefined &&
        payload[id].to.toString().length > 0 && payload[id].submenus != null && payload[id].submenus != undefined && payload[id].submenus.length == 0){
          state.menuAdminNombres.push({
            nombre: payload[id].nombre,
            path: payload[id].to
          })
        }else if (payload[id].submenus != null && payload[id].submenus != undefined && payload[id].submenus.length > 0){
          for (let jd in payload[id].submenus){
            state.menuAdminNombres.push({
              nombre: payload[id].submenus[jd].nombre,
              path: payload[id].submenus[jd].to
            })
          }
        }
      }
    },
    /* Fin Admin */
    setModalCircularAdmin(state, payload){
      state.loadingCircular.activo = payload.activo
      state.loadingCircular.msj = payload.msj
    }
  },
  actions: {
    async restablecerDatosApi({commit}, datosKey){
      let apiRespuesta = {}
      try{
        // elimino la clave de la API de localStorage
        if (localStorage.getItem('datosApiJarvis')){
          localStorage.removeItem('datosApiJarvis')
        }
        // hago un nuevo login
        let body = {
          usuario: Encrypt(config.USUARIO_CATALOGO),
          clave: Encrypt(config.PASSWORD_CATALOGO)
        }
        let loginPedido = await fetch(config.URL_API + 'user/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
        let login = await loginPedido.json()
        // tuve exito al hacer Login?
        if (parseInt(login.exito) === 1){
          // guardo el token en localstorage
          localStorage.setItem('datosApiJarvis', JSON.stringify({tokenJarvis: login.long_token}))
        }else{
          // error al loguear en Jarvis
          apiRespuesta. resultado = 0
          apiRespuesta.msj = 'Ocurrio un problema al loguearse en la Api de Jarvis: ' + login.message
          apiRespuesta.key = {}
          apiRespuesta.opcionActual = null
          commit('setDatosApi', apiRespuesta)
          return
        }
        apiRespuesta.resultado = 1
        apiRespuesta.msj = 'OK'
        apiRespuesta.key = datosKey.key
        if (datosKey.hasOwnProperty('opciones')){
          apiRespuesta.opcionActual = datosKey.opciones
        }
        commit('setDatosApi', apiRespuesta)
      }catch(error){
        apiRespuesta. resultado = 0
        apiRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo restablecerDatosApi: ' + error.message
        apiRespuesta.key = {}
        apiRespuesta.opcionActual = null
        commit('setDatosApi', apiRespuesta)
      }
    },
    async getMenuItems({commit, dispatch}){
      try{
        let peticionItems = await fetch(config.URL_CELUSHOP + 'configuraciones/menu.json?orderBy="inhabilitado"&equalTo=0')
        let items = await peticionItems.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionItems.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getMenuItems'
        // })
        items = Object.values(items)
        let itemsRespuesta = {
          resultado: 1,
          msj: 'OK',
          items: items
        }
        commit('setItemsMenu', itemsRespuesta)
      }catch(error){
        let itemsRespuesta = {
          resultado: 0,
          msj: 'Error al correr el metodo getMenuItems: ' + error.message,
          items: null
        }
        commit('setItemsMenu', itemsRespuesta)
      }
    },
    async getSocialContacts({commit, dispatch}){
      let contactoRespuesta = {}
      try{
        let peticionContacto = await fetch(config.URL_CELUSHOP + 'configuraciones/contacto.json')
        let contacto = await peticionContacto.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionContacto.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getSocialContacts'
        // })
        contactoRespuesta.resultado = 1
        contactoRespuesta.msj = 'Ok'
        contactoRespuesta.contacto = contacto
        commit('setContacto', contactoRespuesta)
      }catch(error){
        contactoRespuesta.resultado = 0
        contactoRespuesta.msj = 'Error al ejecutar el metodo getSocialContacts: ' + error.message
        contactoRespuesta.contacto = null
        commit('setContacto', contactoRespuesta)
      }
    },
    async getBanners({commit, dispatch}){
      let bannersRespuesta = {}
      let listado = []
      let listadoInicio = []
      try{
        // banners para carrusel cycle
        let peticionBanner = await fetch(config.URL_CELUSHOP + 'configuraciones/banners.json?orderBy="inhabilitado"&equalTo=0')
        let banners = await peticionBanner.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionBanner.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getBanners'
        // })
        banners = Object.values(banners)
        let i
        for (i in banners){
          let link = {
            src: banners[i].url_imagen,
            redirect: banners[i].url_redirect
          }
          listado.push(link)
        }
        // banners para las tarjetas
        let peticionBannerInicio = await fetch(config.URL_CELUSHOP + 'configuraciones/bannersInicio.json?orderBy="inhabilitado"&equalTo=0')
        let bannersInicio = await peticionBannerInicio.json()
        // logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionBannerInicio.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getBanners 2'
        // })
        bannersInicio = Object.values(bannersInicio)
        for (let id in bannersInicio){
          let link = {
            src: bannersInicio[id].url_imagen,
            redirect: bannersInicio[id].url_redirect,
            orden: bannersInicio[id].orden
          }
          listadoInicio.push(link)
        }
        // defino mi respuesta
        let bannersListados = {
          bannersCarrusel: listado,
          bannersInicio: listadoInicio
        }
        bannersRespuesta.resultado = 1
        bannersRespuesta.msj = 'OK'
        bannersRespuesta.banners = bannersListados
        commit('setBanners', bannersRespuesta)
      }catch(error){
        bannersRespuesta.resultado = 0
        bannersRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getBanners: ' + error.message
        bannersRespuesta.banners = null
        commit('setBanners', bannersRespuesta)
      }
    },
    async getConfigCarruseles({commit, dispatch}){
      let configRespuesta = {}
      try{
        // configuracion del Orden de los Carruseles
        let configPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/carruseles_inicio.json?orderBy="inhabilitado"&equalTo=0')
        let configCarruseles = await configPeticion.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: configPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getConfigCarruseles'
        // })
        if (!Array.isArray(configCarruseles)){
          configCarruseles = Object.values(configCarruseles)
        }
        // configuracion de los criterios de filtrado de articulos para los carruseles
        let criteriosPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/criterios_carruseles.json?orderBy="inhabilitado"&equalTo=0')
        let criterios = await criteriosPeticion.json()
        // logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: criteriosPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getConfigCarruseles 2'
        // })
        // guardo la key de Firebase
        for (let id in criterios){
          criterios[id].key_firebase = id
        }
        if (!Array.isArray(criterios)){
          criterios = Object.values(criterios)
        }
        let tokenJarvis = ''
        // tengo el token de la API de Jarvis en localStorage?
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: ''})
          return
        }
        // pido los articulos
        let listado = []
        let error = 0
        let id = 0
        do{
          let articulosPeticion = await fetch(config.URL_API + `articulos/getArticulosCelushop?campo_hijo=${criterios[id].criterio.campo_hijo}&campo_padre=${criterios[id].criterio.campo_padre}&valor_campo=${criterios[id].criterio.valor_campo}&inicio=1`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': tokenJarvis.tokenJarvis
            }
          })
          let articulos = await articulosPeticion.json()
          if (parseInt(articulos.resultado) === 1){
            listado.push(articulos)
          }else{
            // el token no es valido?
            if (articulos.hasOwnProperty('exito')){
              if (parseInt(articulos.exito) === 0){
                // token vencido, debo pedir otro
                error = 1
                this.dispatch('restablecerDatosApi', {key: ''})
              }
            }
          }
          id = id + 1
        }while ((parseInt(id) <= criterios.length - 1) && parseInt(error) === 0)
        // hubo error?
        if (parseInt(error) === 1){
          return
        }
        configRespuesta.resultado = 1
        configRespuesta.msj = 'OK'
        configRespuesta.configCarruseles = configCarruseles
        configRespuesta.criterios = criterios
        configRespuesta.articulos = listado
        configRespuesta.errorLogin = 0
        commit('setConfigCarruseles', configRespuesta)
      }catch(error){
        configRespuesta.resultado = 0
        configRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getConfigCarruseles: ' + error.message
        configRespuesta.configCarruseles = null
        configRespuesta.criterios = null
        configRespuesta.articulos = null
        configRespuesta.errorLogin = 0
        commit('setConfigCarruseles', configRespuesta)
      }
    },
    async getImagenesMini({commit, dispatch}, data){
      let articulosRespuesta = {}
      let listado = []
      try{
        let artMinimo = 0;
        // ya tengo los codigos de articulos, solo los consulto y los agrego a mi listado
        for (let id in data.articulos){
          // ordeno el array para quedarme con el minimo codigo de articulo
          data.articulos[id].articulos.sort(function(a,b){
            if (parseInt(a.articuloCodigo) > parseInt(b.articuloCodigo)) {
              return -1;
            }
            if (parseInt(a.articuloCodigo) < parseInt(b.articuloCodigo)) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })
          let otroArtMinimo = data.articulos[id].articulos[data.articulos[id].articulos.length-1].articuloCodigo
          // me quedo con el minimo
          if (parseInt(artMinimo) === 0){
            artMinimo = otroArtMinimo
          }else{
            if (parseInt(otroArtMinimo) < parseInt(artMinimo)){
              artMinimo = otroArtMinimo
            }
          }
        }
        // ya obtuve el minimo codigo de articulo, hago una sola peticion
        let peticionArticulos = await fetch(config.URL_CELUSHOP + `articulos.json?orderBy="art_codigo"&startAt="${artMinimo}"`)
        let articulosCelushop = await peticionArticulos.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionArticulos.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'articulos',
        //   metodo: 'getImagenesMini'
        // })
        // guardo la key de Firebase
        for (let i in articulosCelushop){
          articulosCelushop[i].key_firebase = i
        }
        if (!Array.isArray(articulosCelushop)){
          articulosCelushop = Object.values(articulosCelushop)
        }
        // armo mi listado de respuesta
        for (let id in data.articulos){
          // recorro el array de articulos
          for (let jd in data.articulos[id].articulos){
            // ahora con el listado de articulos que obtuve de firebase veo si esta en el listado
            let unArticulo = articulosCelushop.filter(element => parseInt(element.art_codigo) === parseInt(data.articulos[id].articulos[jd].articuloCodigo))
            // obtuve resultados?
            if (unArticulo.length > 0){
              // me quedo con los que tienen precio mayor a 0
              unArticulo = unArticulo.filter(element => parseInt(element.precios) > 0)
              // me quedaron articulos?
              if (unArticulo.length > 0){
                // tiene imagenes y stock mayor a 0?
                if (unArticulo[0].hasOwnProperty('imagenes') &&
                unArticulo[0].hasOwnProperty('imagenes_mini') &&
                unArticulo[0].hasOwnProperty('stock') && 
                unArticulo[0].stock.hasOwnProperty('stockTotal') &&
                unArticulo[0].hasOwnProperty('precios') &&
                parseInt(unArticulo[0].stock.stockTotal) > 0 &&
                parseInt(unArticulo[0].precios) > 0){
                  // esta en mi listado?
                  if (listado.indexOf(unArticulo[0]) == -1){
                    listado.push(unArticulo[0])
                  }
                }
              }
            }
          }
        }
        articulosRespuesta.resultado = 1
        articulosRespuesta.msj = 'OK'
        articulosRespuesta.articulos = listado
        commit('setArticulosEcommerce', articulosRespuesta)
      }catch(error){
        articulosRespuesta.resultado = 0
        articulosRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getImagenesMini: ' + error.message
        articulosRespuesta.articulos = null
        commit('setArticulosEcommerce', articulosRespuesta)
      }
    },
    async getCodCategoria({commit, dispatch}, categoria){
      let codigoRespuesta = {}
      try{
        let peticionCodigo = await fetch(config.URL_CELUSHOP + `configuraciones/menu.json?orderBy="nombre"&equalTo="${categoria}"`)
        let codigo = await peticionCodigo.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionCodigo.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getCodCategoria'
        // })
        codigo = Object.values(codigo)[0].categoria
        // consigo el listado de articulos a mostrar
        let tokenJarvis = ''
        // tengo el token de la API de Jarvis en localStorage?
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: ''})
          return
        }
        let articulosPeticion = await fetch(config.URL_API + `articulos/getArticulosCelushop?campo_hijo=idPadre&campo_padre=cat_ecommerce&valor_campo=${codigo}&inicio=0`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          }
        })
        let articulos = await articulosPeticion.json()
        let error = 0
        if (parseInt(articulos.resultado) === 0){
          // el token no es valido?
          if (articulos.hasOwnProperty('exito')){
            if (parseInt(articulos.exito) === 0){
              // token vencido, debo pedir otro
              error = 1
              this.dispatch('restablecerDatosApi', {key: ''})
            }
          }
        }else{
          // el token no es valido?
          if (articulos.hasOwnProperty('exito')){
            if (parseInt(articulos.exito) === 0){
              // token vencido, debo pedir otro
              error = 1
              this.dispatch('restablecerDatosApi', {key: ''})
            }
          }
        }
        if (parseInt(error) === 1){
          return
        }
        // obtengo los filtros por orden
        let peticionfiltros = await fetch(config.URL_CELUSHOP + `configuraciones/filtros_orden.json`)
        let filtros = await peticionfiltros.json()
        // logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionfiltros.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getCodCategoria 2'
        // })
        codigoRespuesta.resultado = 1
        codigoRespuesta.msj = 'OK'
        codigoRespuesta.codigo = codigo
        codigoRespuesta.articulos = articulos
        codigoRespuesta.filtrosOrden = filtros
        commit('setCategoriaActual', codigoRespuesta)
      }catch(error){
        codigoRespuesta.resultado = 0
        codigoRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getCodCategoria: ' + error.message
        codigoRespuesta.codigo = null
        codigoRespuesta.articulos = null
        codigoRespuesta.filtrosOrden = null
        commit('setCategoriaActual', codigoRespuesta)
      }
    },
    async getCodSubCategoria({commit}, subCategoria){
      let codigoRespuesta = {}
      let listado = []
      try{
        let peticionCodigo = await fetch(config.URL_CELUSHOP + `articulos_categorias.json?orderBy="nombre"&equalTo="${subCategoria.subCat}"`)
        let codigo = await peticionCodigo.json()
        // guardo el codigo de la subCategoria que viene de Firebase
        for (let id in codigo){
          codigo[id].subCategoriaCodigo = parseInt(id)
        }
        codigo = Object.values(codigo)
        // consigo el listado de articulos a mostrar
        let tokenJarvis = ''
        // tengo el token de la API de Jarvis en localStorage?
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: ''})
          return
        }
        let articulosPeticion = await fetch(config.URL_API + `articulos/getArticulosCelushop?campo_hijo=idHijo&campo_padre=cat_ecommerce&valor_campo=${codigo[0].subCategoriaCodigo}&inicio=0`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          }
        })
        let articulos = await articulosPeticion.json()
        let error = 0
        if (parseInt(articulos.resultado) === 0){
          // el token no es valido?
          if (articulos.hasOwnProperty('exito')){
            if (parseInt(articulos.exito) === 0){
              // token vencido, debo pedir otro
              error = 1
              this.dispatch('restablecerDatosApi', {key: ''})
            }
          }
        }else{
          // el token no es valido?
          if (articulos.hasOwnProperty('exito')){
            if (parseInt(articulos.exito) === 0){
              // token vencido, debo pedir otro
              error = 1
              this.dispatch('restablecerDatosApi', {key: ''})
            }
          }
        }
        if (parseInt(error) === 1){
          return
        }
        codigoRespuesta.resultado = 1
        codigoRespuesta.msj = 'OK'
        codigoRespuesta.codigo = codigo[0]
        codigoRespuesta.articulos = articulos
        commit('setCodSubCategoria', codigoRespuesta)
      }catch(error){
        codigoRespuesta.resultado = 0
        codigoRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getCodSubCategoria: ' + error.message
        codigoRespuesta.codigo = null
        codigoRespuesta.articulos = null
        commit('setCodSubCategoria', codigoRespuesta)
      }
    },
    async getArtsXCategoria({commit, dispatch}, datos){
      let articulosRespuesta = {}
      let listado = []
      try{
        let artMinimo = 0;
        // ordeno el array para quedarme con el minimo codigo de articulo
        datos.articulos.articulos.sort(function(a,b){
          if (parseInt(a.articuloCodigo) > parseInt(b.articuloCodigo)) {
            return -1;
          }
          if (parseInt(a.articuloCodigo) < parseInt(b.articuloCodigo)) {
            return 1;
          }
          // a must be equal to b
          return 0;
        })
        let otroArtMinimo = datos.articulos.articulos[datos.articulos.articulos.length-1].articuloCodigo
        // me quedo con el minimo
        if (parseInt(artMinimo) === 0){
          artMinimo = otroArtMinimo
        }else{
          if (parseInt(otroArtMinimo) < parseInt(artMinimo)){
            artMinimo = otroArtMinimo
          }
        }
        // ya obtuve el minimo codigo de articulo, hago una sola peticion
        let peticionArticulos = await fetch(config.URL_CELUSHOP + `articulos.json?orderBy="art_codigo"&startAt="${artMinimo}"`)
        let articulosCelushop = await peticionArticulos.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionArticulos.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'articulos',
        //   metodo: 'getArtsXCategoria'
        // })
        // guardo la key de Firebase
        for (let i in articulosCelushop){
          articulosCelushop[i].key_firebase = i
        }
        if (!Array.isArray(articulosCelushop)){
          articulosCelushop = Object.values(articulosCelushop)
        }
        // me quedo solo con los que me hacen falta
        for (let id in datos.articulos.articulos){
          // ahora con el listado de articulos que obtuve de firebase veo si esta en el listado
          let unArticulo = articulosCelushop.filter(element => parseInt(element.art_codigo) === parseInt(datos.articulos.articulos[id].articuloCodigo))
          // obtuve resultados?
          if (unArticulo.length > 0){
            // me quedo con los que tienen precio mayor a 0
            unArticulo = unArticulo.filter(element => parseInt(element.precios) > 0)
            // me quedaron articulos?
            if (unArticulo.length > 0){
              // tiene imagenes y stock mayor a 0?
              if (unArticulo[0].hasOwnProperty('imagenes') &&
              unArticulo[0].hasOwnProperty('imagenes_mini') &&
              unArticulo[0].hasOwnProperty('stock') && 
              unArticulo[0].stock.hasOwnProperty('stockTotal') &&
              unArticulo[0].hasOwnProperty('precios') &&
              parseInt(unArticulo[0].precios) > 0 &&
              parseInt(unArticulo[0].stock.stockTotal) > 0){
                // esta en mi listado?
                if (listado.indexOf(unArticulo[0]) == -1){
                  listado.push(unArticulo[0])
                }
              }
            }
          }
        }
        articulosRespuesta.resultado = 1
        articulosRespuesta.msj = 'OK'
        articulosRespuesta.articulos = listado
        commit('setArtsEcommerceCat', articulosRespuesta)
      }catch(error){
        articulosRespuesta.resultado = 0
        articulosRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getArtsXCategoria: ' + error.message
        articulosRespuesta.articulos = null
        commit('setArtsEcommerceCat', articulosRespuesta)
      }
    },
    async getArtsXCategoria2({commit, dispatch}, datos){
      let articulosRespuesta = {}
      let listado = []
      try{
        // busco por id padre
        /*for (let i = 0; i <= 10; i++){
          let peticionArticulos = await fetch(config.URL_CELUSHOP + `articulos.json?orderBy="cat_ecommerce/${i}/idPadre"&startAt=${datos.subCatAcual}&endAt=${datos.subCatAcual}`)
          let articulos = await peticionArticulos.json()
          if (Object.values(articulos).length > 0){
            for (let id in articulos){
              if (parseInt(articulos[id].stock.stockTotal) > 0){
                if (articulos[id].hasOwnProperty('imagenes_mini')){
                  // es un listado de imagenes?
                  if ((Array.isArray(Object.values(articulos[id].imagenes_mini))) && articulos[id].imagenes_mini != 0 && articulos[id].imagenes_mini != "0" && articulos[id].imagenes_mini != '0'){
                    if (listado.indexOf(articulos[id]) === -1){
                      articulos[id].key_firebase = id
                      listado.push(articulos[id])
                    }
                  }
                }
              }
            }
          }
        }
        // busco por id hijo
        for (let i = 0; i <= 10; i++){
          let peticionArticulos = await fetch(config.URL_CELUSHOP + `articulos.json?orderBy="cat_ecommerce/${i}/idHijo"&startAt=${datos.subCatAcual}&endAt=${datos.subCatAcual}`)
          let articulos = await peticionArticulos.json()
          if (Object.values(articulos).length > 0){
            for (let id in articulos){
              if (parseInt(articulos[id].stock.stockTotal) > 0){
                if (articulos[id].hasOwnProperty('imagenes_mini')){
                  // es un listado de imagenes?
                  if ((Array.isArray(Object.values(articulos[id].imagenes_mini))) && articulos[id].imagenes_mini != 0 && articulos[id].imagenes_mini != "0" && articulos[id].imagenes_mini != '0'){
                    if (listado.indexOf(articulos[id]) === -1){
                      articulos[id].key_firebase = id
                      listado.push(articulos[id])
                    }
                  }
                }
              }
            }
          }
        }*/
        let artMinimo = 0;
        // ordeno el array para quedarme con el minimo codigo de articulo
        datos.articulos.articulos.sort(function(a,b){
          if (parseInt(a.articuloCodigo) > parseInt(b.articuloCodigo)) {
            return -1;
          }
          if (parseInt(a.articuloCodigo) < parseInt(b.articuloCodigo)) {
            return 1;
          }
          // a must be equal to b
          return 0;
        })
        let otroArtMinimo = datos.articulos.articulos[datos.articulos.articulos.length-1].articuloCodigo
        // me quedo con el minimo
        if (parseInt(artMinimo) === 0){
          artMinimo = otroArtMinimo
        }else{
          if (parseInt(otroArtMinimo) < parseInt(artMinimo)){
            artMinimo = otroArtMinimo
          }
        }
        // ya obtuve el minimo codigo de articulo, hago una sola peticion
        let peticionArticulos = await fetch(config.URL_CELUSHOP + `articulos.json?orderBy="art_codigo"&startAt="${artMinimo}"`)
        let articulosCelushop = await peticionArticulos.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionArticulos.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'articulos',
        //   metodo: 'getArtsXCategoria2'
        // })
        // guardo la key de Firebase
        for (let i in articulosCelushop){
          articulosCelushop[i].key_firebase = i
        }
        if (!Array.isArray(articulosCelushop)){
          articulosCelushop = Object.values(articulosCelushop)
        }
        for (let id in datos.articulos.articulos){
          // ahora con el listado de articulos que obtuve de firebase veo si esta en el listado
          let unArticulo = articulosCelushop.filter(element => parseInt(element.art_codigo) === parseInt(datos.articulos.articulos[id].articuloCodigo))
          // obtuve resultados?
          if (unArticulo.length > 0){
            // me quedo con los que tienen precio mayor a 0
            unArticulo = unArticulo.filter(element => parseInt(element.precios) > 0)
            // me quedaron articulos?
            if (unArticulo.length > 0){
              // tiene imagenes y stock mayor a 0?
              if (unArticulo[0].hasOwnProperty('imagenes') &&
              unArticulo[0].hasOwnProperty('imagenes_mini') &&
              unArticulo[0].hasOwnProperty('stock') && 
              unArticulo[0].stock.hasOwnProperty('stockTotal') &&
              unArticulo[0].hasOwnProperty('precios') &&
              parseInt(unArticulo[0].precios) > 0 &&
              parseInt(unArticulo[0].stock.stockTotal) > 0){
                // esta en mi listado?
                if (listado.indexOf(unArticulo[0]) == -1){
                  listado.push(unArticulo[0])
                }
              }
            }
          }
        }
        articulosRespuesta.resultado = 1
        articulosRespuesta.msj = 'OK'
        articulosRespuesta.articulos = listado
        commit('setArtsEcommerceSubCat', articulosRespuesta)
      }catch(error){
        articulosRespuesta.resultado = 0
        articulosRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getArtsXCategoria2: ' + error.message
        articulosRespuesta.articulos = null
        commit('setArtsEcommerceSubCat', articulosRespuesta)
      }
    },
    async getArticulosCategoria({commit, dispatch}, categoriaActual){
      let categoriaRespuesta = {}
      try{
        // obtengo las categorias
        let peticionCategoria = await fetch(config.URL_CELUSHOP + `articulos_categorias.json?orderBy="nombre"&startAt="${categoriaActual}"&endAt="${categoriaActual}"`)
        let categoria = await peticionCategoria.json()
        for (let id in categoria){
          categoria[id].id = id
        }
        // obtengo los filtros por orden
        let peticionfiltros = await fetch(config.URL_CELUSHOP + `configuraciones/filtros_orden.json`)
        let filtros = await peticionfiltros.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionfiltros.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getArticulosCategoria'
        // })
        categoria = Object.values(categoria)[0]
        categoriaRespuesta.resultado = 1
        categoriaRespuesta.msj = 'OK'
        categoriaRespuesta.categoria = categoria
        categoriaRespuesta.filtrosOrden = filtros
        commit('setCategoriaFiltros', categoriaRespuesta)
      }catch(error){
        categoriaRespuesta.resultado = 0
        categoriaRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getArticulosCategoria: ' + error.message
        categoriaRespuesta.categoria = null
        categoriaRespuesta.filtrosOrden = null
        commit('setCategoriaFiltros', categoriaRespuesta)
      }
    },
    async loadCarrito({commit, dispatch}){
      // reviso a ver si existe el carrito en el navegador del usuario
      if (localStorage.getItem('carritoUsuario')){
        // lo capturo
        let carritoLocal = await JSON.parse(localStorage.getItem('carritoUsuario'))
        // actualizo el precio los precios del carrito en caso que hayan cambiado
        // por cada item del carrito averiguo si los datos cambiaron
        for (let id in carritoLocal){
          let articuloPeticion = await fetch(config.URL_CELUSHOP + `articulos/${carritoLocal[id].articuloKey}.json`)
          let articulo = await articuloPeticion.json()
          // let logPeticion = await dispatch('genericos/regLogRequestSize', {
          //   sistema: 'CELUSHOP',
          //   request_bytes: articuloPeticion.headers.get('Content-Length'),
          //   usuario: 'CeluShop',
          //   nodo: 'articulos',
          //   metodo: 'loadCarrito'
          // })
          // pregunto si tengo el mismo precio en localStorage y en lo que acabo de obtener
          if (parseFloat(articulo.precios) != parseFloat(carritoLocal[id].articuloPrecio)){
            carritoLocal[id].articuloPrecio = (parseFloat(articulo.precios)).toFixed(2)
            let precioAnterior = carritoLocal[id].articuloPrecio
            carritoLocal[id].precioNeto = parseFloat(carritoLocal[id].cantidad * precioAnterior).toFixed(2)
          }
          let stockActual = {}
          if (articulo.hasOwnProperty('stock')){
            // actualizo las cantidades según campo stockTotal
            stockActual = articulo.stock
            carritoLocal[id].stock = stockActual
          }else{
            // no tiene stock? queda fuera del carrito
            stockActual.stockDescrip = 'STOCK TOTAL'
            stockActual.stockTotal = '0'
            carritoLocal[id].stock = stockActual
          }
        }
        // lo tengo, lo asigno a mi array de carrito
        commit('setCarrito', carritoLocal)
      }else{
        commit('setCarrito', [])
      }
    },
    async pushArtCarrito({commit}, articulo){
      // agrego al carrito, pero ya existe en localStorage?
      if (localStorage.getItem('carritoUsuario')){
        // lo capturo
        let carritoLocal = JSON.parse(localStorage.getItem('carritoUsuario'))
        // agrego el nuevo articulo que me mandan
        carritoLocal.push(articulo)
        // lo guardo en localStorage
        localStorage.setItem('carritoUsuario', JSON.stringify(carritoLocal))
      }else{
        // no existe, lo creo desde 0
        let carritoLocal = []
        // agrego el articulo
        carritoLocal.push(articulo)
        // lo guardo en localStorage
        localStorage.setItem('carritoUsuario', JSON.stringify(carritoLocal))
      }
      commit('setArticuloCarrito', articulo)
    },
    async removeArtCarrito({commit}, articulo){
      // quito del carrito que se encuentra en LocalStorage
      if (localStorage.getItem('carritoUsuario')){
        let carritoLocal = JSON.parse(localStorage.getItem('carritoUsuario'))
        let pos = -1
        for (let id in carritoLocal){
          if (carritoLocal[id].articuloKey == articulo.articuloKey){
            pos = id
          }
        }
        if (parseInt(pos) >= 0){
          carritoLocal.splice(pos,1)
        }
        // lo guardo en el LocalStorage
        localStorage.setItem('carritoUsuario', JSON.stringify(carritoLocal))
      }
      commit('removeArticuloCarrito', articulo)
    },
    async getCarritoAsociado({commit, dispatch}, filtros){
      let articulosRespuesta = {}
      let listado = []
      let listadoAux = []
      try{
        // filtros tiene la info de los criterios para obtener articulos asociados, lo recorremos
        for (let id in filtros){
          let articulosPeticion = await fetch(config.URL_CELUSHOP + `articulos.json?orderBy="art_marca"&equalTo="${filtros[id].marca}"`)
          let articulos = await articulosPeticion.json()
          // let logPeticion = await dispatch('genericos/regLogRequestSize', {
          //   sistema: 'CELUSHOP',
          //   request_bytes: articulosPeticion.headers.get('Content-Length'),
          //   usuario: 'CeluShop',
          //   nodo: 'articulos',
          //   metodo: 'getCarritoAsociado'
          // })
          if (Object.values(articulos).length > 0){
            // obtuve por Marca, pero me quiero quedar con los articulos que ademas de la marca tengan el mismo modelo
            for (let jd in articulos){
              articulos[jd].key_firebase = jd
              if (articulos[jd].art_modelo == filtros[id].modelo){
                // tiene imagenes mini?
                if (articulos[jd].hasOwnProperty('imagenes_mini') && articulos[jd].hasOwnProperty('precios') && parseInt(articulos[jd].precios) > 0){
                  // es un array imagenes_mini?
                  if ((articulos[jd].imagenes_mini != 0) && (articulos[jd].imagenes_mini != '0') && (articulos[jd].imagenes_mini != "0")){
                    // su stock es mayor a cero?
                    if (parseInt(articulos[jd].stock.stockTotal) > 0){
                      // esta en el listado?
                      let cont = 0
                      for (let i in listado){
                        if (listado[i].key_firebase == articulos[jd].key_firebase){
                          cont = cont + 1
                        }
                      }
                      if (parseInt(cont) === 0){
                        listado.push(articulos[jd])
                      }
                      
                    }
                  }
                }
              }
            }
          }
        }
        articulosRespuesta.resultado = 1
        articulosRespuesta.msj = 'OK'
        // pregunto si el tamaño del array es el deseado
        if (listado.length === 25){
          // hago el commit
          articulosRespuesta.articulos = listado
          commit('setArticulosAsociados', articulosRespuesta)
        }else if (listado.length > 25){
          // tengo mas de lo que necesito, ordeno descendiente y me quedo solo con 25
          listado.sort(function(a,b){
            if (parseInt(a.art_codigo) > parseInt(b.art_codigo)) {
              return -1;
            }
            if (parseInt(a.art_codigo) < parseInt(b.art_codigo)) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })
          // hacemos la "ordenacion" xD
          let index = 0
          do{
            listadoAux.push(listado[index])
            index = index + 1
          }while(parseInt(index) <= 24)
          // respuesta
          articulosRespuesta.articulos = listadoAux
          commit('setArticulosAsociados', articulosRespuesta)
        }else{
          // necesito completarlo con mas articulos, uso la categoria
          let index = 0
          do{
            let articulosPeticion = await fetch(config.URL_CELUSHOP + `articulos.json?orderBy="art_categoria"&equalTo="${filtros[index].categoria}"`)
            let articulos = await articulosPeticion.json()
            // let logPeticion = await dispatch('genericos/regLogRequestSize', {
            //   sistema: 'CELUSHOP',
            //   request_bytes: articulosPeticion.headers.get('Content-Length'),
            //   usuario: 'CeluShop',
            //   nodo: 'articulos',
            //   metodo: 'getCarritoAsociado 2'
            // })
            // guardo la clave de firebase
            for (let id in articulos){
              articulos[id].key_firebase = id
            }
            // convierto a array
            articulos = Object.values(articulos)
            if (articulos.length > 0){
              // obtuve por Marca, pero me quiero quedar con los articulos que ademas de la marca tengan el mismo modelo
              let indice = 0
              do{
                // tiene imagenes mini?
                if (articulos[indice].hasOwnProperty('imagenes_mini') && articulos[indice].hasOwnProperty('precios') && parseInt(articulos[indice].precios) > 0){
                  // es un array imagenes_mini?
                  if ((articulos[indice].imagenes_mini != 0) && (articulos[indice].imagenes_mini != '0') && (articulos[indice].imagenes_mini != "0")){
                    // su stock es mayor a cero?
                    if (parseInt(articulos[indice].stock.stockTotal) > 0){
                      // esta en el listado?
                      let cont = 0
                      for (let i in listado){
                        if (listado[i].key_firebase == articulos[indice].key_firebase){
                          cont = cont + 1
                        }
                      }
                      if (parseInt(cont) === 0){
                        listado.push(articulos[indice])
                      }
                    }
                  }
                }
                indice = indice + 1
              }while((parseInt(indice) <= Object.values(articulos).length - 1) && (listado.length <= 25))
            }
            index = index + 1
          }while((parseInt(index) <= filtros.length - 1) && (listado.length <= 25))
          // respuesta
          articulosRespuesta.articulos = listado
          commit('setArticulosAsociados', articulosRespuesta)
        }
      }catch(error){
        articulosRespuesta.resultado = 0
        articulosRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getCarritoAsociado: ' + error.message
        articulosRespuesta.articulos = null
        commit('setArticulosAsociados', articulosRespuesta)
      }
    },
    async getSucursales({commit}){
      let sucursalesRespuesta = {}
      try{
        let sucursalesPeticion = await fetch(config.URL_CELUSHOP + 'sucursales.json?orderBy="estado"&equalTo=1')
        let sucursales = await sucursalesPeticion.json()
        sucursales = Object.values(sucursales)
        sucursalesRespuesta.resultado = 1
        sucursalesRespuesta.msj = 'OK'
        sucursalesRespuesta.sucursales = sucursales
        commit('setSucursales', sucursalesRespuesta)
      }catch(error){
        sucursalesRespuesta.resultado = 0
        sucursalesRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getSucursales: ' + error.message
        sucursalesRespuesta.sucursales = null
        commit('setSucursales', sucursalesRespuesta)
      }
    },
    async getBusquedaUsuario({commit, dispatch}, busqueda){
      let articulosRespuesta = {}
      /*let listadoInicial = []
      let listadoFinal = []*/
      let listado = []
      try{
        // consigo el listado de articulos a mostrar
        let tokenJarvis = ''
        // tengo el token de la API de Jarvis en localStorage?
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: ''})
          return
        }
        let articulosPeticion = await fetch(config.URL_API + `articulos/searchArticulosCelushop?cadena=${busqueda}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          }
        })
        let articulos = await articulosPeticion.json()
        let error = 0
        if (parseInt(articulos.resultado) === 0){
          // el token no es valido?
          if (articulos.hasOwnProperty('exito')){
            if (parseInt(articulos.exito) === 0){
              // token vencido, debo pedir otro
              error = 1
              this.dispatch('restablecerDatosApi', {key: ''})
            }
          }
        }else{
          // el token no es valido?
          if (articulos.hasOwnProperty('exito')){
            if (parseInt(articulos.exito) === 0){
              // token vencido, debo pedir otro
              error = 1
              this.dispatch('restablecerDatosApi', {key: ''})
            }
          }
        }
        if (parseInt(error) === 1){
          return
        }
        // pido los articulos a firebase
        for (let id in articulos.articulos){
          let peticionArticulos = await fetch(config.URL_CELUSHOP + `articulos.json?orderBy="art_codigo"&equalTo="${articulos.articulos[id].articuloCodigo}"`)
          let articulosCelushop = await peticionArticulos.json()
          // let logPeticion = await dispatch('genericos/regLogRequestSize', {
          //   sistema: 'CELUSHOP',
          //   request_bytes: peticionArticulos.headers.get('Content-Length'),
          //   usuario: 'CeluShop',
          //   nodo: 'articulos',
          //   metodo: 'getBusquedaUsuario'
          // })
          let unArticulo = []
          for (let jd in articulosCelushop){
            articulosCelushop[jd].key_firebase = jd
            unArticulo.push(articulosCelushop[jd])
          }
          // su precio es mayor a 0?
          let artConPrecio = unArticulo.filter(element => parseInt(element.precios) > 0)
          // me quedaron articulos?
          if (artConPrecio.length > 0){
            // tiene imagenes y stock mayor a 0?
            if (artConPrecio[0].hasOwnProperty('imagenes') &&
            artConPrecio[0].hasOwnProperty('imagenes_mini') &&
            artConPrecio[0].hasOwnProperty('stock') && 
            artConPrecio[0].stock.hasOwnProperty('stockTotal') &&
            parseInt(artConPrecio[0].stock.stockTotal) > 0){
              // esta en mi listado?
              if (listado.indexOf(artConPrecio[0]) == -1){
                listado.push(artConPrecio[0])
                // voy enviando de a 8 articulos
                if (listado.length%8 == 0){
                  articulosRespuesta.resultado = 1
                  articulosRespuesta.msj = 'OK'
                  articulosRespuesta.articulos = listado
                  articulosRespuesta.pendiente = true
                  articulosRespuesta.cantidad = articulos.articulos.length
                  this.dispatch('setArtEcommerce', articulosRespuesta)
                }
              }
            }
          }
        }
        /*let artMinimo = 0;
        // ordeno el array para quedarme con el minimo codigo de articulo
        articulos.articulos.sort(function(a,b){
          if (parseInt(a.articuloCodigo) > parseInt(b.articuloCodigo)) {
            return -1;
          }
          if (parseInt(a.articuloCodigo) < parseInt(b.articuloCodigo)) {
            return 1;
          }
          // a must be equal to b
          return 0;
        })
        let otroArtMinimo = articulos.articulos[articulos.articulos.length-1].articuloCodigo
        // me quedo con el minimo
        if (parseInt(artMinimo) === 0){
          artMinimo = otroArtMinimo
        }else{
          if (parseInt(otroArtMinimo) < parseInt(artMinimo)){
            artMinimo = otroArtMinimo
          }
        }
        let artMaximo = articulos.articulos[0].articuloCodigo
        // ya obtuve el minimo codigo de articulo, hago una sola peticion
        let peticionArticulos = await fetch(config.URL_CELUSHOP + `articulos.json?orderBy="art_codigo"&startAt="${artMinimo}"&endAt="${artMaximo}"`)
        let articulosCelushop = await peticionArticulos.json()*/
        // guardo la key de Firebase
        /*for (let i in articulosCelushop){
          articulosCelushop[i].key_firebase = i
        }
        if (!Array.isArray(articulosCelushop)){
          articulosCelushop = Object.values(articulosCelushop)
        }
        for (let id in articulos.articulos){
          // ahora con el listado de articulos que obtuve de firebase veo si esta en el listado
          let unArticulo = articulosCelushop.filter(element => parseInt(element.art_codigo) === parseInt(articulos.articulos[id].articuloCodigo))
          // obtuve resultados?
          if (unArticulo.length > 0){
            // me quedo con los que tienen precio mayor a 0
            unArticulo = unArticulo.filter(element => parseInt(element.precios) > 0)
            // me quedaron articulos?
            if (unArticulo.length > 0){
              // tiene imagenes y stock mayor a 0?
              if (unArticulo[0].hasOwnProperty('imagenes') &&
              unArticulo[0].hasOwnProperty('imagenes_mini') &&
              unArticulo[0].hasOwnProperty('stock') && 
              unArticulo[0].stock.hasOwnProperty('stockTotal') &&
              parseInt(unArticulo[0].stock.stockTotal) > 0){
                // esta en mi listado?
                if (listado.indexOf(unArticulo[0]) == -1){
                  listado.push(unArticulo[0])
                }
              }
            }
          }
        }*/
        articulosRespuesta.resultado = 1
        articulosRespuesta.msj = 'OK'
        articulosRespuesta.articulos = listado
        articulosRespuesta.pendiente = false
        articulosRespuesta.cantidad = articulos.articulos.length
        commit('setArticulosEcommerce', articulosRespuesta)
      }catch(error){
        articulosRespuesta.resultado = 0
        articulosRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getBusquedaUsuario: ' + error.message
        articulosRespuesta.articulos = null
        articulosRespuesta.pendiente = false
        articulosRespuesta.cantidad = null
        commit('setArticulosEcommerce', articulosRespuesta)
      }
    },
    setArtEcommerce({commit}, datos){
      commit('setArticulosEcommerce', datos)
    },
    async getCategoriasBusqueda({commit, dispatch}, categoriasBuscadas){
      let categoriasRespuesta = {}
      let listado = []
      try{
        let categoriasPeticion = await fetch(config.URL_CELUSHOP + 'articulos_categorias.json')
        let categorias = await categoriasPeticion.json()
        // me quedo solo con las que el usuario busca
        for (let id in categorias){
          // distinto de null
          if (categorias[id] != null && categorias[id] != undefined){
            // recorro mi array de busqueda
            for (let jd in categoriasBuscadas){
              if (parseInt(id) === parseInt(categoriasBuscadas[jd])){
                // agrego siempre y cuando no se encuentre
                categorias[id].key_firebase = id
                let cont = 0
                for (let i in listado){
                  if (listado[i].key_firebase == categorias[id].key_firebase){
                    cont = cont + 1
                  }
                }
                if (parseInt(cont) === 0){
                  // tiene filtros y es array ese campo?
                  if (categorias[id].hasOwnProperty('filtros')){
                    if (Array.isArray(categorias[id].filtros)){
                      listado.push(categorias[id])
                    }
                  }
                }
              }
            }
          }
        }
        // obtengo los filtros por orden
        let peticionfiltros = await fetch(config.URL_CELUSHOP + `configuraciones/filtros_orden.json`)
        let filtros = await peticionfiltros.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionfiltros.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getCategoriasBusqueda'
        // })
        categoriasRespuesta.resultado = 1
        categoriasRespuesta.msj = 'OK'
        categoriasRespuesta.categorias = listado
        categoriasRespuesta.filtrosOrden = filtros
        commit('setCategoriasBusqueda', categoriasRespuesta)
      }catch(error){
        categoriasRespuesta.resultado = 0
        categoriasRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getCategoriasBusqueda: ' + error.message
        categoriasRespuesta.categorias = null
        categoriasRespuesta.filtrosOrden = null
        commit('setCategoriasBusqueda', categoriasRespuesta)
      }
    },
    async getOneArticleInfo({commit, dispatch}, keyFirebase){
      let articuloRespuesta = {}
      try{
        let articuloPeticion = await fetch(config.URL_CELUSHOP + `articulos/${keyFirebase}.json`)
        let articulo = await articuloPeticion.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: articuloPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'articulos',
        //   metodo: 'getOneArticleInfo'
        // })
        articuloRespuesta.resultado = 1
        articuloRespuesta.msj = 'OK'
        articuloRespuesta.articulo = articulo
        commit('setUnArticulo', articuloRespuesta)
      }catch(error){
        articuloRespuesta.resultado = 0
        articuloRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getOneArticleInfo: ' + error.message
        articuloRespuesta.articulo = null
        commit('setUnArticulo', articuloRespuesta)
      }
    },
    async crearUsuario({commit}, payload){
      try{
        firebase.auth().createUserWithEmailAndPassword(payload.email,payload.password)
          .then( response => {
            response.user.updateProfile({displayName:payload.displayName,photoURL:'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'})
              .then(response =>{
                this.dispatch('signInEmailPassword',{email:payload.email,password:payload.password, tipo: payload.tipo})
              })
              .catch(error => {
                console.log('actualizar user error' + error)
              })
              commit('setAuthError','')
          })
          .catch(error => {
            var errorMsj
            switch (error.code) {
              case 'auth/email-already-in-use':
                //al marcar que el correo ya existe estamos dando datos de los usuarios registrados
                //por tal motivo  marcamos como ambos campos invalidos
                errorMsj = 'Usuario ya registrado'
                break;
              case 'auth/invalid-email':
                errorMsj = 'Correo no válido.'
                break;
              case 'auth/operation-not-allowed':
                errorMsj = 'Correo y/o clave inválidos.'
                break;
              case 'auth/weak-password':
                errorMsj = 'La clave posee un nivel de seguridad muy bajo.'
                break;
              default:
                errorMsj = 'Error al registrarse.'
            }
            commit('setAuthError',errorMsj)
          })
      }catch(error){
        let errorMsj = 'Ocurrio un problema al ejecutar el método crearUsuario: ' + error.message
        commit('setAuthError',errorMsj)
      }
    },
    async signInGoogle({commit}){
      let usuario = {}
      usuario.tipo = 1
      try{
        //login con cuentas de Google, en caso que sea la primera vez se registra el usuario.
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().languageCode = 'es'; //especificamos el lenguaje Espaniol (no tengo enieee)
        firebase.auth().signInWithPopup(provider)
        .then(async response => {
          //LogIn Exitoso
          usuario.usuarioNombre = response.user.displayName
          usuario.usuarioEmail = response.user.email
          usuario.usuarioUid = response.user.uid
          // conseguimos los datos personales del usuario
          let refUsuario = firebase.database().ref('clientes/' + usuario.usuarioUid)
          let data = {}
          let datosPersonales = {}
          await refUsuario.once('value', function(snapshot){
            data = {}
            snapshot.forEach(function(childSnapshot){
              let childData = childSnapshot.val();
              let key = childSnapshot.key.toString()
              Object.assign(childData,{key:childSnapshot.key})
              Object.defineProperty(data, key,{value: childData, writable: true, enumerable: true, configurable: true})
            });
            // obtuve resultado?
            if (Object.keys(data).length > 0){
              // tiene datos personales?
              if (data.hasOwnProperty('datos_personales')){
                // si tiene
                datosPersonales = data.datos_personales
              }else{
                // no tiene
                datosPersonales.apellidos = ''
                datosPersonales.calle = ''
                datosPersonales.calle_numero = ''
                datosPersonales.codigo_postal = ''
                datosPersonales.datos_adicionales = ''
                datosPersonales.documento = ''
                datosPersonales.fecha_nacimiento = ''
                datosPersonales.genero = ''
                datosPersonales.localidad = ''
                datosPersonales.nombre = ''
                datosPersonales.provincia = ''
                datosPersonales.telefono = ''
              }
            }
          })
          response.user.getIdToken()
            .then(token => {
              usuario.resultado = 1
              usuario.msj = 'OK'
              usuario.usuarioToken = token
              usuario.datosCliente = datosPersonales
              commit('setTokenID',usuario)
            })
            .catch(error => {
              firebase.auth().signOut()
              usuario.resultado = 0
              usuario.msj = 'Ocurrio un problema al obtener el token en signInGoogle: ' + error.message
              usuario.usuarioToken = null
              usuario.datosCliente = null
              commit('setTokenID',usuario)
            })
          this.dispatch('evaluarUser',response.user)
        })
        .catch(error => {
          // Error al ingresar
          firebase.auth().signOut()
          usuario.resultado = 0
          usuario.msj = 'Ocurrio un problema al intentar loguearse en signInGoogle: ' + error.message
          usuario.usuarioToken = null
          usuario.datosCliente = null
          commit('setTokenID',usuario)
        })
      }catch(error){
        usuario.resultado = 0
        usuario.msj = 'Ocurrio un problema al ejecutar el metodo signInGoogle: ' + error.message
        usuario.usuarioToken = null
        usuario.datosCliente = null
        commit('setTokenID',usuario)
      }
    },
    async signInFacebook({commit}){
      let usuario = {}
      usuario.tipo = 1
      try{
        //login con cuentas de Google, en caso que sea la primera vez se registra el usuario.
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().languageCode = 'es'; //especificamos el lenguaje Espaniol (no tengo enieee)
        firebase.auth().signInWithPopup(provider)
        .then(async response => {
          //LogIn Exitoso
          usuario.usuarioNombre = response.user.displayName
          usuario.usuarioEmail = response.user.email
          usuario.usuarioUid = response.user.uid
          // conseguimos los datos personales del usuario
          let refUsuario = firebase.database().ref('clientes/' + usuario.usuarioUid)
          let data = {}
          let datosPersonales = {}
          await refUsuario.once('value', function(snapshot){
            data = {}
            snapshot.forEach(function(childSnapshot){
              let childData = childSnapshot.val();
              let key = childSnapshot.key.toString()
              Object.assign(childData,{key:childSnapshot.key})
              Object.defineProperty(data, key,{value: childData, writable: true, enumerable: true, configurable: true})
            });
            // obtuve resultado?
            if (Object.keys(data).length > 0){
              // tiene datos personales?
              if (data.hasOwnProperty('datos_personales')){
                // si tiene
                datosPersonales = data.datos_personales
              }else{
                // no tiene
                datosPersonales.apellidos = ''
                datosPersonales.calle = ''
                datosPersonales.calle_numero = ''
                datosPersonales.codigo_postal = ''
                datosPersonales.datos_adicionales = ''
                datosPersonales.documento = ''
                datosPersonales.fecha_nacimiento = ''
                datosPersonales.genero = ''
                datosPersonales.localidad = ''
                datosPersonales.nombre = ''
                datosPersonales.provincia = ''
                datosPersonales.telefono = ''
              }
            }
          })
          response.user.getIdToken()
            .then(token =>{
              usuario.resultado = 1
              usuario.msj = 'OK'
              usuario.usuarioToken = token
              usuario.datosCliente = datosPersonales
              commit('setTokenID',usuario)
            })
            .catch(error => {
              firebase.auth().signOut()
              usuario.resultado = 0
              usuario.msj = 'Ocurrio un problema al obtener el token en signInFacebook: ' + error.message
              usuario.usuarioToken = null
              usuario.datosCliente = null
              commit('setTokenID',usuario)
            })
            this.dispatch('evaluarUser',response.user)
        })
        .catch(error => {
          //Error al ingresar
          firebase.auth().signOut()
          usuario.resultado = 0
          usuario.msj = 'Ocurrio un problema al intentar loguearse en signInFacebook: ' + error.message
          usuario.usuarioToken = null
          usuario.datosCliente = null
          commit('setTokenID',usuario)
        })
      }catch(error){
        usuario.resultado = 0
        usuario.msj = 'Ocurrio un problema al ejecutar el metodo signInFacebook: ' + error.message
        usuario.usuarioToken = null
        usuario.datosCliente = null
        commit('setTokenID',usuario)
      }
    },
    async signInEmailPassword({commit},payload){
      try{
        firebase.auth().signInWithEmailAndPassword(payload.email,payload.password)
        .then(async response =>{
          let usuario = {
            usuarioNombre: response.user.displayName,
            usuarioEmail: response.user.email,
            usuarioUid: response.user.uid,
            tipo: payload.tipo
          }
          // conseguimos los datos personales del usuario
          let refUsuario = firebase.database().ref('clientes/' + usuario.usuarioUid)
          let data = {}
          let datosPersonales = {}
          await refUsuario.once('value', function(snapshot){
            data = {}
            snapshot.forEach(function(childSnapshot){
              let childData = childSnapshot.val();
              let key = childSnapshot.key.toString()
              Object.assign(childData,{key:childSnapshot.key})
              Object.defineProperty(data, key,{value: childData, writable: true, enumerable: true, configurable: true})
            });
            // obtuve resultado?
            if (Object.keys(data).length > 0){
              // tiene datos personales?
              if (data.hasOwnProperty('datos_personales')){
                // si tiene
                datosPersonales = data.datos_personales
              }else{
                // no tiene
                datosPersonales.apellidos = ''
                datosPersonales.calle = ''
                datosPersonales.calle_numero = ''
                datosPersonales.codigo_postal = ''
                datosPersonales.datos_adicionales = ''
                datosPersonales.documento = ''
                datosPersonales.fecha_nacimiento = ''
                datosPersonales.genero = ''
                datosPersonales.localidad = ''
                datosPersonales.nombre = ''
                datosPersonales.provincia = ''
                datosPersonales.telefono = ''
              }
            }
          })
          //LogIn Exitoso
          response.user.getIdToken()
            .then(token =>{
              usuario.resultado = 1
              usuario.msj = 'OK'
              usuario.usuarioToken = token
              usuario.datosCliente = datosPersonales
              commit('setTokenID',usuario)
            })
            .catch(error => {
              firebase.auth().signOut()
              usuario.resultado = 0
              usuario.msj = 'Ocurrio un problema al obtener el token en signInEmailPassword: ' + error.message
              usuario.usuarioToken = null
              usuario.datosCliente = null
              commit('setTokenID',usuario)
            })
            this.dispatch('evaluarUser',response.user)
        })
        .catch(error => {
          firebase.auth().signOut()
          var errorMsj
          switch (error.code) {
            case 'auth/user-disabled':
              //al marcar que el correo ya existe estamos dando datos de los usuarios registrados
              //por tal motivo  marcamos como ambos campos invalidos
              errorMsj = 'Correo y/o clave inválidos.'
              break;
            case 'auth/invalid-email':
              errorMsj = 'Correo no válido.'
              break;
            case 'auth/user-not-found':
              errorMsj = 'Correo y/o clave inválidos.'
              break;
            case 'auth/wrong-password':
              errorMsj = 'Correo y/o clave inválidos.'
              break;
            default:
              errorMsj = 'Error al registrarse.'
          }
          commit('setAuthError',errorMsj)
        })
      }catch(error){
        let usuario = {
          resultado: 0,
          msj: 'Ocurrio un problema al ejecutar el método signInEmailPassword: ' + error.message,
          tipo: payload.tipo,
          usuarioToken: null,
          datosCliente: null
        }
        commit('setTokenID',usuario)
      }
    },
    async resetPasswordEmail({commit},payload){
      firebase.auth().sendPasswordResetEmail(payload.email)
      .then(response => {
        commit('setErrorResetPasword','')
        commit('setResetPasword','Se envió un correo para restablecer su contraseña.')
      })
      .catch(error => {
        var errorMsj
        switch (error.code) {
          case 'auth/invalid-email':
            errorMsj = 'Correo inválido.'
            break;
          case 'auth/missing-continue-uri':
            errorMsj = 'Se debe proporcionar una URL continua en la solicitud.'
            break;
          case 'auth/invalid-continue-uri':
            errorMsj = 'La URL de continuación proporcionada en la solicitud no es válida.'
            break;
          case 'auth/unauthorized-continue-uri':
            errorMsj = 'El dominio de la URL continua no está en la lista blanca. Incluya en la lista blanca el dominio.'
            break;
          case 'auth/user-not-found':
            errorMsj = 'Correo inválido.'
            break;
          default:
            errorMsj = 'Error al registrarse.'
        }
        commit('setResetPasword','')
        commit('setErrorResetPasword',errorMsj)
      });
    },
    async logout({commit},payload){
      try{
        firebase.auth().signOut()
        let respuesta = {
          resultado: 1,
          msj: 'OK',
          tipo: 3
        }
        commit('setTokenID', respuesta)
        commit('setUsuario', null)
      }catch(error){
        let respuesta = {
          resultado: 0,
          msj: 'Ocurrio un problema al ejecutar el metodo logout: ' + error.message,
          tipo: 3
        }
        commit('setTokenID', respuesta)
        commit('setUsuario', null)
      }
    },
    async resetAuthError({commit},payload){
      commit('setAuthError','')
    },
    evaluarUser({commit},user){
      //verifico si el usuario logueado ya esta en la base de datos
      // si no esta lo agrego; seteo la variable usuario
      var clientesCorreosRef =  firebase.database().ref('clientes_correos')
      clientesCorreosRef.once("value")
      .then(snapshot => {
        let userExists = snapshot.child(user.uid).exists();
        if(!userExists){ // si no existe el usuario en la base de datos, lo creo
          var usuario = {
            email: user.email,
            displayName: user.displayName,
            phoneNumber: user.phoneNumber,
            photoUrl: user.photoURL,
            uid: user.uid,
            fecha_log: moment().format('YYYY/MM/DD h:mm:ss')
          }
          firebase.database().ref('clientes').child(user.uid).update(usuario)
          var object  = {}
          Object.defineProperty(object, user.uid,{value: user.email, writable: true, enumerable: true, configurable: true})
          firebase.database().ref('clientes_correos').update(object)
        }else{
          var usuario = snapshot.child(user.uid).val()
        }
        commit('setUsuario',usuario)
      })
    },
    showLoading({commit}){
      commit('setLoading', true)
    },
    closeLoading({commit}){
      commit('setLoading', false)
    },
    async startMyData({commit}, dataUsuario){
      let respuestaDatos = {}
      try{
        // conseguimos los datos personales del usuario
        let refUsuario = firebase.database().ref('clientes/' + dataUsuario.usuarioUid)
        let data = {}
        let datosPersonales = {}
        await refUsuario.once('value', function(snapshot){
          data = {}
          snapshot.forEach(function(childSnapshot){
            let childData = childSnapshot.val();
            let key = childSnapshot.key.toString()
            Object.assign(childData,{key:childSnapshot.key})
            Object.defineProperty(data, key,{value: childData, writable: true, enumerable: true, configurable: true})
          });
          // obtuve resultado?
          if (Object.keys(data).length > 0){
            // tiene datos personales?
            if (data.hasOwnProperty('datos_personales')){
              // si tiene
              datosPersonales = data.datos_personales
            }else{
              // no tiene
              datosPersonales.apellidos = ''
              datosPersonales.calle = ''
              datosPersonales.calle_numero = ''
              datosPersonales.codigo_postal = ''
              datosPersonales.datos_adicionales = ''
              datosPersonales.documento = ''
              datosPersonales.fecha_nacimiento = ''
              datosPersonales.genero = ''
              datosPersonales.localidad = ''
              datosPersonales.nombre = ''
              datosPersonales.provincia = ''
              datosPersonales.telefono = ''
            }
          }else{
            // el usuario no existe?
            respuestaDatos.resultado = 0
            respuestaDatos.msj = 'El usuario no existe o no se registro correctamente: ' + error.message
            respuestaDatos.datosPersonales = null
            respuestaDatos.generos = null
            respuestaDatos.localidades = null
            commit('setMyData', respuestaDatos)
          }
        })
        // conseguimos los generos
        let refGeneros = firebase.database().ref("configuraciones/generos")
        let generos = []
        await refGeneros.once('value', function(snapshot){
          snapshot.forEach(function(childSnapshot) {
            var childData = childSnapshot.val()
            var key = childSnapshot.key.toString()
            Object.assign(childData,{key:childSnapshot.key})
            generos.push(childData)
          });
        })
        // conseguimos las Localidades
        /*let refLocalidades = firebase.database().ref("configuraciones/localidades")
        let localidades = []
        await refLocalidades.once('value', function(snapshot){
          snapshot.forEach(function(childSnapshot) {
            var childData = childSnapshot.val()
            var key = childSnapshot.key.toString()
            Object.assign(childData,{key:childSnapshot.key})
            localidades.push(childData)
          });
        })*/
        // respuesta
        respuestaDatos.resultado = 1
        respuestaDatos.msj = 'OK'
        respuestaDatos.datosPersonales = datosPersonales
        respuestaDatos.generos = generos
        //respuestaDatos.localidades = localidades
        commit('setMyData', respuestaDatos)
      }catch(error){
        respuestaDatos.resultado = 0
        respuestaDatos.msj = 'Ocurrio un problema al ejecutar el metodo startMyData: ' + error.message
        respuestaDatos.datosPersonales = null
        respuestaDatos.generos = null
        //respuestaDatos.localidades = null
        commit('setMyData', respuestaDatos)
      }
    },
    async updateMydata({commit}, dataUsuario){
      let updateRespuesta = {}
      try{
        let usuario = firebase.database().ref("clientes/" + dataUsuario.usuarioUid);
        await usuario.update({
          "fecha_log": moment().format('YYYY/MM/DD h:mm:ss')
        });
        let messageListRef = firebase.database().ref("clientes/" + dataUsuario.usuarioUid + "/datos_personales");
        await messageListRef.set(dataUsuario);
        updateRespuesta.resultado = 1
        updateRespuesta. msj = 'OK'
        commit('setUpdateMyData', updateRespuesta)
      }catch(error){
        updateRespuesta.resultado = 0
        updateRespuesta. msj = 'Ocurrio un problema al ejecutar el método updateMydata: ' + error.message
        commit('setUpdateMyData', updateRespuesta)
      }
    },
    async getPedidosCliente({commit}, dataUsuario){
      let pedidosRespuesta = {}
      try{
        let refPedidos = firebase.database().ref("clientes/" + dataUsuario.usuarioUid + "/pedidos")
        let pedidos = []
        await refPedidos.on('value', function(snapshot){
          snapshot.forEach(function(childSnapshot) {
            let childData = childSnapshot.val()
            Object.assign(childData,{key:childSnapshot.key})
            pedidos.push(childData)
          })
        })
        pedidosRespuesta.resultado = 1
        pedidosRespuesta.msj = 'OK'
        pedidosRespuesta.pedidos = pedidos
        commit('setPedidosCliente', pedidosRespuesta)
      }catch(error){
        pedidosRespuesta.resultado = 0
        pedidosRespuesta.msj = 'Ocurrio un problema al ejecutar el método getPedidosCliente: ' + error.message
        pedidosRespuesta.pedidos = null
        commit('setPedidosCliente', pedidosRespuesta)
      }
    },
    async getProvincias({commit}){
      let provinciasRespuesta = {}
      try{
        let provinciasPeticion = await fetch(config.URL_API + 'domicilios/getProvincias')
        let provinciasDatos = await provinciasPeticion.json()
        if (parseInt(provinciasDatos.resultado) === 1){
          provinciasRespuesta.resultado = 1
          provinciasRespuesta.msj = 'OK'
          provinciasRespuesta.provincias = provinciasDatos
        }else{
          provinciasRespuesta.resultado = 0
          provinciasRespuesta.msj = provinciasDatos.msj
          provinciasRespuesta.provincias = null
        }
        commit('setProvincias', provinciasRespuesta)
      }catch(error){
        provinciasRespuesta.resultado = 0
        provinciasRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getProvincias: ' + error.message
        provinciasRespuesta.provincias = null
        commit('setProvincias', provinciasRespuesta)
      }
    },
    async getLocalidades({commit}, provincia){
      let localidadesRespuesta = {}
      try{
        let localidadesPeticion = await fetch(config.URL_API + `domicilios/getLocalidadesXProvincia?provincia_id=${provincia.provincia_id}`)
				let localidadesOracle = await localidadesPeticion.json()
        if (parseInt(localidadesOracle.resultado) === 1){
          localidadesRespuesta.resultado = 1
          localidadesRespuesta.msj = 'OK'
          localidadesRespuesta.localidades = localidadesOracle
        }else{
          localidadesRespuesta.resultado = 0
          localidadesRespuesta.msj = localidadesOracle.msj
          localidadesRespuesta.localidades = null
        }
        commit('setLocalidades', localidadesRespuesta)
      }catch(error){
        localidadesRespuesta.resultado = 0
        localidadesRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getLocalidades: ' + error.message
        localidadesRespuesta.localidades = null
        commit('setLocalidades', localidadesRespuesta)
      }
    },
    async getDatosEntregaXCodPostal({commit}, datosCliente){
      let datosRespuesta = {}
      try{
        let datosPeticion = await fetch(config.URL_API + `domicilios/getProvinciaXCodigoPostal?codigo_postal=${datosCliente.codigoPostal}`)
        let datos = await datosPeticion.json()
        if (parseInt(datos.resultado) === 1){
          datosRespuesta.resultado = 1
          datosRespuesta.msj = 'OK'
          datosRespuesta.datos = datos
          datosRespuesta.opcion = datosCliente.opcion
        }else{
          datosRespuesta.resultado = 0
          datosRespuesta.msj = datos.msj
          datosRespuesta.datos = null
          datosRespuesta.opcion = datosCliente.opcion
        }
        commit('setDatosEntregaCliente', datosRespuesta)
      }catch(error){
        datosRespuesta.resultado = 0
        datosRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getDatosEntregaXCodPostal: ' + error.message
        datosRespuesta.datos = null
        datosRespuesta.opcion = datosCliente.opcion
        commit('setDatosEntregaCliente', datosRespuesta)
      }
    },
    async getCarruselNoCarrito({commit, dispatch}){
      // este metodo es general
      // se hizo porque el cliente puede entrar a consultar su carrito sin tener nada en el, raro pero puede pasar
      // entonces, por mas que no tenga nada, se le deben mostrar un listado de articulos en el carrusel (en ralidad se le muestran articulos asociados a los que tiene en el carrito) como para que tenga opciones para elegir
      // pero con que criterio le muestro esos articulos? por el momento que sean los nuevos articulos
      let articulosRespuesta = {}
      let listado = []
      try{
        // ya obtuve el minimo codigo de articulo, hago una sola peticion
        let peticionArticulos = await fetch(config.URL_CELUSHOP + 'articulos.json?orderBy="nuevo_producto"&equalTo="1"&limitToLast=25')
        let articulosCelushop = await peticionArticulos.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionArticulos.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'articulos',
        //   metodo: 'getCarruselNoCarrito'
        // })
        // guardo la key de Firebase
        for (let i in articulosCelushop){
          articulosCelushop[i].key_firebase = i
        }
        if (!Array.isArray(articulosCelushop)){
          articulosCelushop = Object.values(articulosCelushop)
        }
        // armo mi listado de respuesta
        for (let id in articulosCelushop){
          // tiene imagenes y stock mayor a 0?
          if (articulosCelushop[id].hasOwnProperty('imagenes') &&
            articulosCelushop[id].hasOwnProperty('imagenes_mini') &&
            articulosCelushop[id].hasOwnProperty('stock') && 
            articulosCelushop[id].hasOwnProperty('precios') && 
            parseInt(articulosCelushop[id].precios) > 0 &&
            articulosCelushop[id].stock.hasOwnProperty('stockTotal') &&
            parseInt(articulosCelushop[id].stock.stockTotal) > 0){
              // esta en mi listado?
              if (listado.indexOf(articulosCelushop[id]) == -1){
                listado.push(articulosCelushop[id])
              }
            }
        }
        articulosRespuesta.resultado = 1
        articulosRespuesta.msj = 'OK'
        articulosRespuesta.articulos = listado
        commit('setArtsNoCarrito', articulosRespuesta)
      }catch(error){
        articulosRespuesta.resultado = 0
        articulosRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getCarruselNoCarrito: ' + error.message
        articulosRespuesta.articulos = null
        commit('setArtsNoCarrito', articulosRespuesta)
      }
    },
    async existsUser({commit}, datos){
      let clienteRespuesta = {}
      let clientePeticion = ''
      let cliente = {}
      try{
        if (parseInt(datos.opcion) === 1){
          // cliente ya logueado
        }else{
          // cliente no logueado, tiene cuenta? primero, obtengo el Uid del cliente
          clientePeticion = await fetch(config.URL_CELUSHOP + `clientes_correos.json?orderBy="$value"&equalTo="${datos.email}"`)
          cliente = await clientePeticion.json()
          let uId = ''
          for (let id in cliente){
            uId = id
          }
          // logueo con mi usuario DBA para obtener los datos del email
          firebase.auth().signInWithEmailAndPassword(config.USUARIO_CELUSHOP,config.PASSWORD_CELUSHOP)
            .then(async response => {
              // conseguimos los datos personales del usuario
              let refUsuario = firebase.database().ref('clientes/' + uId)
              let data = {}
              let datosPersonales = {}
              await refUsuario.once('value', function(snapshot){
                data = {}
                snapshot.forEach(function(childSnapshot){
                  let childData = childSnapshot.val();
                  let key = childSnapshot.key.toString()
                  Object.assign(childData,{key:childSnapshot.key})
                  Object.defineProperty(data, key,{value: childData, writable: true, enumerable: true, configurable: true})
                });
                // obtuve resultado?
                if (data.hasOwnProperty('datos_personales')){
                  // tiene datos personales
                  datosPersonales = data.datos_personales
                  // respuesta
                  clienteRespuesta.resultado = 1
                  clienteRespuesta.msj = 'OK'
                  clienteRespuesta.datosCliente = datosPersonales
                }else{
                  datosPersonales.apellidos = ''
                  datosPersonales.calle = ''
                  datosPersonales.calle_numero = ''
                  datosPersonales.codigo_postal = ''
                  datosPersonales.datos_adicionales = ''
                  datosPersonales.documento = ''
                  datosPersonales.fecha_nacimiento = ''
                  datosPersonales.genero = ''
                  datosPersonales.localidad = ''
                  datosPersonales.nombre = ''
                  datosPersonales.provincia = ''
                  datosPersonales.telefono = ''
                  if (data.hasOwnProperty('displayName')){
                    // no tiene datos personales pero si existe en nuestro sistema
                    clienteRespuesta.resultado = 2
                    clienteRespuesta.msj = 'OK'
                    clienteRespuesta.datosCliente = datosPersonales
                  }else{
                    // no existe en nuestro sistema
                    clienteRespuesta.resultado = 3
                    clienteRespuesta.msj = 'OK'
                    clienteRespuesta.datosCliente = datosPersonales
                  }
                }
              })
              // cierro sesion del usuario DBA
              firebase.auth().signOut()
              commit('setExistsUser', clienteRespuesta)
            })
            .catch(error => {
              clienteRespuesta.resultado = 0
              clienteRespuesta.msj = 'Ocurrio un problema al loguearse con usuario celushop: ' + error.message
              clienteRespuesta.datosCliente = null
              commit('setExistsUser', clienteRespuesta)
            })
        }
      }catch(error){
        clienteRespuesta.resultado = 0
        clienteRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo existsUser: ' + error.message
        clienteRespuesta.cliente = null
        commit('setExistsUser', clienteRespuesta)
      }
    },
    async startBuy({commit, dispatch}){
      let compraRespuesta = {}
      let errores = 0
      try{
        // consigo las provincias
        /*let provinciasPeticion = await fetch(config.URL_API + 'domicilios/getProvincias')
        let provinciasDatos = await provinciasPeticion.json()
        if (parseInt(provinciasDatos.resultado) === 0){
          errores = errores + 1
        }*/
        // consigo los generos
        let refGeneros = firebase.database().ref("configuraciones/generos")
        let generos = []
        await refGeneros.once('value', function(snapshot){
          snapshot.forEach(function(childSnapshot) {
            var childData = childSnapshot.val()
            var key = childSnapshot.key.toString()
            Object.assign(childData,{key:childSnapshot.key})
            generos.push(childData)
          });
        })
        // consigo los costos de envio
        let costosPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/costo_envio.json')
        let costos = await costosPeticion.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: costosPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'startBuy'
        // })
        if (parseInt(errores) === 0){
          compraRespuesta.resultado = 1
          compraRespuesta.msj = 'OK'
          //compraRespuesta.provincias = provinciasDatos
          compraRespuesta.generos = generos
          compraRespuesta.costosEnvio = costos
        }else{
          compraRespuesta.resultado = 0
          compraRespuesta.msj = 'Error al consultar provincias en startBuy'
          //compraRespuesta.provincias = null
          compraRespuesta.generos = null
          compraRespuesta.costosEnvio = null
        }
        commit('setStartBuy', compraRespuesta)
      }catch(error){
        compraRespuesta.resultado = 0
        compraRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo startBuy: ' + error.message
        //compraRespuesta.provincias = null
        compraRespuesta.generos = null
        compraRespuesta.costosEnvio = null
        commit('setStartBuy', compraRespuesta)
      }
    },
    async activeUserMisPedidos({commit}, datos){
      let activeUserRespuesta = {}
      try{
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user){
            let usuario = {
              usuarioNombre: user.displayName,
              usuarioEmail: user.email,
              usuarioUid: user.uid
            }
            // conseguimos los datos personales del usuario
            let refUsuario = firebase.database().ref('clientes/' + usuario.usuarioUid)
            let data = {}
            let datosPersonales = {}
            await refUsuario.once('value', function(snapshot){
              data = {}
              snapshot.forEach(function(childSnapshot){
                let childData = childSnapshot.val();
                let key = childSnapshot.key.toString()
                Object.assign(childData,{key:childSnapshot.key})
                Object.defineProperty(data, key,{value: childData, writable: true, enumerable: true, configurable: true})
              });
              // obtuve resultado?
              if (Object.keys(data).length > 0){
                // tiene datos personales?
                if (data.hasOwnProperty('datos_personales')){
                  // si tiene
                  datosPersonales = data.datos_personales
                }else{
                  // no tiene
                  datosPersonales.apellidos = ''
                  datosPersonales.calle = ''
                  datosPersonales.calle_numero = ''
                  datosPersonales.codigo_postal = ''
                  datosPersonales.datos_adicionales = ''
                  datosPersonales.documento = ''
                  datosPersonales.fecha_nacimiento = ''
                  datosPersonales.genero = ''
                  datosPersonales.localidad = ''
                  datosPersonales.nombre = ''
                  datosPersonales.provincia = ''
                  datosPersonales.telefono = ''
                }
              }
            })
            // obtengo el token
            user.getIdToken()
              .then(token =>{
                usuario.usuarioToken = token
                usuario.datosCliente = datosPersonales
                activeUserRespuesta.resultado = 1
                activeUserRespuesta.msj = 'OK'
                activeUserRespuesta.datosUsuario = usuario
              })
              .catch(error => {
                usuario.usuarioToken = null
                usuario.datosCliente = null
                activeUserRespuesta.resultado = 0
                activeUserRespuesta.msj = 'Ocurrio un problema al obtener el token en activeUserMisPedidos: ' + error.message
                activeUserRespuesta.datosUsuario = null
              })
            activeUserRespuesta.existeUsuario = true
            activeUserRespuesta.paso = datos.paso
            activeUserRespuesta.opcion = datos.opcion // indica desde que componente estoy llamando a este metodo
            commit('setActiveUserMisPedidos', activeUserRespuesta)
          }else{
            activeUserRespuesta.resultado = 1
            activeUserRespuesta.msj = 'OK'
            activeUserRespuesta.existeUsuario = false
            activeUserRespuesta.paso = datos.paso
            activeUserRespuesta.opcion = datos.opcion
            activeUserRespuesta.datosUsuario = null
            commit('setActiveUserMisPedidos', activeUserRespuesta)
          }
        })
      }catch(error){
        activeUserRespuesta.resultado = 0
        activeUserRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo activeUserMisPedidos: ' + error.message
        activeUserRespuesta.existeUsuario = null
        activeUserRespuesta.paso = paso
        activeUserRespuesta.datosUsuario = null
        commit('setActiveUserMisPedidos', activeUserRespuesta)
      }
    },
    async activeUserMisDatos({commit}, datos){
      let activeUserRespuesta = {}
      try{
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user){
            let usuario = {
              usuarioNombre: user.displayName,
              usuarioEmail: user.email,
              usuarioUid: user.uid
            }
            // conseguimos los datos personales del usuario
            let refUsuario = firebase.database().ref('clientes/' + usuario.usuarioUid)
            let data = {}
            let datosPersonales = {}
            await refUsuario.once('value', function(snapshot){
              data = {}
              snapshot.forEach(function(childSnapshot){
                let childData = childSnapshot.val();
                let key = childSnapshot.key.toString()
                Object.assign(childData,{key:childSnapshot.key})
                Object.defineProperty(data, key,{value: childData, writable: true, enumerable: true, configurable: true})
              });
              // obtuve resultado?
              if (Object.keys(data).length > 0){
                // tiene datos personales?
                if (data.hasOwnProperty('datos_personales')){
                  // si tiene
                  datosPersonales = data.datos_personales
                }else{
                  // no tiene
                  datosPersonales.apellidos = ''
                  datosPersonales.calle = ''
                  datosPersonales.calle_numero = ''
                  datosPersonales.codigo_postal = ''
                  datosPersonales.datos_adicionales = ''
                  datosPersonales.documento = ''
                  datosPersonales.fecha_nacimiento = ''
                  datosPersonales.genero = ''
                  datosPersonales.localidad = ''
                  datosPersonales.nombre = ''
                  datosPersonales.provincia = ''
                  datosPersonales.telefono = ''
                }
              }
            })
            // obtengo el token
            user.getIdToken()
              .then(token =>{
                usuario.usuarioToken = token
                usuario.datosCliente = datosPersonales
                activeUserRespuesta.resultado = 1
                activeUserRespuesta.msj = 'OK'
                activeUserRespuesta.datosUsuario = usuario
              })
              .catch(error => {
                usuario.usuarioToken = null
                usuario.datosCliente = null
                activeUserRespuesta.resultado = 0
                activeUserRespuesta.msj = 'Ocurrio un problema al obtener el token en activeUserMisDatos: ' + error.message
                activeUserRespuesta.datosUsuario = null
              })
            activeUserRespuesta.existeUsuario = true
            activeUserRespuesta.paso = datos.paso
            activeUserRespuesta.opcion = datos.opcion // indica desde que componente estoy llamando a este metodo
            commit('setActiveUserMisDatos', activeUserRespuesta)
          }else{
            activeUserRespuesta.resultado = 1
            activeUserRespuesta.msj = 'OK'
            activeUserRespuesta.existeUsuario = false
            activeUserRespuesta.paso = datos.paso
            activeUserRespuesta.opcion = datos.opcion
            activeUserRespuesta.datosUsuario = null
            commit('setActiveUserMisDatos', activeUserRespuesta)
          }
        })
      }catch(error){
        activeUserRespuesta.resultado = 0
        activeUserRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo activeUserMisDatos: ' + error.message
        activeUserRespuesta.existeUsuario = null
        activeUserRespuesta.paso = paso
        activeUserRespuesta.datosUsuario = null
        commit('setActiveUserMisDatos', activeUserRespuesta)
      }
    },
    async controlUsuarioActivo({commit}, datos){
      let activeUserRespuesta = {}
      try{
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user){
            let usuario = {
              usuarioNombre: user.displayName,
              usuarioEmail: user.email,
              usuarioUid: user.uid
            }
            // conseguimos los datos personales del usuario
            let refUsuario = firebase.database().ref('clientes/' + usuario.usuarioUid)
            let data = {}
            let datosPersonales = {}
            await refUsuario.once('value', function(snapshot){
              data = {}
              snapshot.forEach(function(childSnapshot){
                let childData = childSnapshot.val();
                let key = childSnapshot.key.toString()
                Object.assign(childData,{key:childSnapshot.key})
                Object.defineProperty(data, key,{value: childData, writable: true, enumerable: true, configurable: true})
              });
              // obtuve resultado?
              if (Object.keys(data).length > 0){
                // tiene datos personales?
                if (data.hasOwnProperty('datos_personales')){
                  // si tiene
                  datosPersonales = data.datos_personales
                }else{
                  // no tiene
                  datosPersonales.apellidos = ''
                  datosPersonales.calle = ''
                  datosPersonales.calle_numero = ''
                  datosPersonales.codigo_postal = ''
                  datosPersonales.datos_adicionales = ''
                  datosPersonales.documento = ''
                  datosPersonales.fecha_nacimiento = ''
                  datosPersonales.genero = ''
                  datosPersonales.localidad = ''
                  datosPersonales.nombre = ''
                  datosPersonales.provincia = ''
                  datosPersonales.telefono = ''
                }
              }
            })
            // obtengo el token
            user.getIdToken()
              .then(token =>{
                usuario.usuarioToken = token
                usuario.datosCliente = datosPersonales
                activeUserRespuesta.resultado = 1
                activeUserRespuesta.msj = 'OK'
                activeUserRespuesta.datosUsuario = usuario
              })
              .catch(error => {
                usuario.usuarioToken = null
                usuario.datosCliente = null
                activeUserRespuesta.resultado = 0
                activeUserRespuesta.msj = 'Ocurrio un problema al obtener el token en controlUsuarioActivo: ' + error.message
                activeUserRespuesta.datosUsuario = null
              })
            activeUserRespuesta.existeUsuario = true
            activeUserRespuesta.paso = datos.paso
            activeUserRespuesta.opcion = datos.opcion // indica desde que componente estoy llamando a este metodo
            commit('setControlUsuarioActivo', activeUserRespuesta)
          }else{
            activeUserRespuesta.resultado = 1
            activeUserRespuesta.msj = 'OK'
            activeUserRespuesta.existeUsuario = false
            activeUserRespuesta.paso = datos.paso
            activeUserRespuesta.opcion = datos.opcion
            activeUserRespuesta.datosUsuario = null
            commit('setControlUsuarioActivo', activeUserRespuesta)
          }
        })
      }catch(error){
        activeUserRespuesta.resultado = 0
        activeUserRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo controlUsuarioActivo: ' + error.message
        activeUserRespuesta.existeUsuario = null
        activeUserRespuesta.paso = paso
        activeUserRespuesta.datosUsuario = null
        commit('setControlUsuarioActivo', activeUserRespuesta)
      }
    },
    async checkActiveUser({commit}, datos){
      let activeUserRespuesta = {}
      try{
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user){
            let usuario = {
              usuarioNombre: user.displayName,
              usuarioEmail: user.email,
              usuarioUid: user.uid
            }
            // conseguimos los datos personales del usuario
            let refUsuario = firebase.database().ref('clientes/' + usuario.usuarioUid)
            let data = {}
            let datosPersonales = {}
            await refUsuario.once('value', function(snapshot){
              data = {}
              snapshot.forEach(function(childSnapshot){
                let childData = childSnapshot.val();
                let key = childSnapshot.key.toString()
                Object.assign(childData,{key:childSnapshot.key})
                Object.defineProperty(data, key,{value: childData, writable: true, enumerable: true, configurable: true})
              });
              // obtuve resultado?
              if (Object.keys(data).length > 0){
                // tiene datos personales?
                if (data.hasOwnProperty('datos_personales')){
                  // si tiene
                  datosPersonales = data.datos_personales
                }else{
                  // no tiene
                  datosPersonales.apellidos = ''
                  datosPersonales.calle = ''
                  datosPersonales.calle_numero = ''
                  datosPersonales.codigo_postal = ''
                  datosPersonales.datos_adicionales = ''
                  datosPersonales.documento = ''
                  datosPersonales.fecha_nacimiento = ''
                  datosPersonales.genero = ''
                  datosPersonales.localidad = ''
                  datosPersonales.nombre = ''
                  datosPersonales.provincia = ''
                  datosPersonales.telefono = ''
                }
              }
            })
            // obtengo el token
            user.getIdToken()
              .then(token =>{
                usuario.usuarioToken = token
                usuario.datosCliente = datosPersonales
                activeUserRespuesta.resultado = 1
                activeUserRespuesta.msj = 'OK'
                activeUserRespuesta.datosUsuario = usuario
              })
              .catch(error => {
                usuario.usuarioToken = null
                usuario.datosCliente = null
                activeUserRespuesta.resultado = 0
                activeUserRespuesta.msj = 'Ocurrio un problema al obtener el token en checkActiveUser: ' + error.message
                activeUserRespuesta.datosUsuario = null
              })
            activeUserRespuesta.existeUsuario = true
            activeUserRespuesta.paso = datos.paso
            activeUserRespuesta.opcion = datos.opcion // indica desde que componente estoy llamando a este metodo
            commit('setStatusUser', activeUserRespuesta)
          }else{
            activeUserRespuesta.resultado = 1
            activeUserRespuesta.msj = 'OK'
            activeUserRespuesta.existeUsuario = false
            activeUserRespuesta.paso = datos.paso
            activeUserRespuesta.opcion = datos.opcion
            activeUserRespuesta.datosUsuario = null
            commit('setStatusUser', activeUserRespuesta)
          }
        })
      }catch(error){
        activeUserRespuesta.resultado = 0
        activeUserRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo checkActiveUser: ' + error.message
        activeUserRespuesta.existeUsuario = null
        activeUserRespuesta.paso = paso
        activeUserRespuesta.datosUsuario = null
        commit('setStatusUser', activeUserRespuesta)
      }
    },
    async getFinanciaciones({commit}){
      let financiacionesRespuesta = {}
      let listado = []
      try{
        // Obtengo financiaciones
        let financPeticion = await fetch(config.URL_CELUSHOP + 'empresas/2/financiaciones.json')
        let financiaciones = await financPeticion.json()
        // me quedo solo con las vigentes
        for (let id in financiaciones){
          if (financiaciones[id].hasOwnProperty('VIGENTE')){
            if (parseInt(financiaciones[id].VIGENTE) === 1){
              listado.push(financiaciones[id])
            }
          }
        }
        // obtengo tarjetas
        let tarjetasPeticion = await fetch(config.URL_CELUSHOP + 'tarjetas.json')
        let tarjetasRespuesta = await tarjetasPeticion.json()
        financiacionesRespuesta.resultado = 1
        financiacionesRespuesta.msj = 'OK'
        financiacionesRespuesta.financiaciones = listado
        financiacionesRespuesta.tarjetas = tarjetasRespuesta
        commit('setFinanciaciones', financiacionesRespuesta)
      }catch(error){
        financiacionesRespuesta.resultado = 0
        financiacionesRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getFinanciaciones: ' + error.message
        financiacionesRespuesta.financiaciones = null
        financiacionesRespuesta.tarjetas = null
        commit('setFinanciaciones', financiacionesRespuesta)
      }
    },
    async sendMailContacto({commit}, datos){
      let envioRespuesta = {}
      try{
        // token de JARVIS, tengo el token de la API de Jarvis en localStorage?
        let tokenJarvis = ''
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
          return
        }
        // ************** ENVIO DE MAIL ************** //
        let emailPeticion = await fetch(config.URL_API + 'adminEmails/sendMailContactoEcommerce', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          },
          body: JSON.stringify(datos.bodyEmail)
        })
        let emailRes = await emailPeticion.json()
        let error = 0
        if (emailRes.hasOwnProperty('exito')){
          if (parseInt(emailRes.exito) === 0){
            // es por token expirado?
            if (emailRes.hasOwnProperty('message')){
              if (emailRes.message.indexOf('expired')){
                error = 1
              }
            }
            if (emailRes.hasOwnProperty('error')){
              if (emailRes.error.indexOf('expired')){
                error = 1
              }
            }
          }
          if (parseInt(error) === 1){
            this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
            return
          }
        }
        // ************ FIN ENVIO DE MAIL ************ //
        envioRespuesta.resultado = 1
        envioRespuesta.msj = 'OK'
        envioRespuesta.emailResultado = emailRes
        commit('setEmailResultado', envioRespuesta)
      }catch(error){
        envioRespuesta.resultado = 0
        envioRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo sendMailContacto: ' + error.message
        envioRespuesta.emailResultado = null
        commit('setEmailResultado', envioRespuesta)
      }
    },
    async sendMailCobro({commit}, datos){
      let envioRespuesta = {}
      try{
        // token de JARVIS, tengo el token de la API de Jarvis en localStorage?
        let tokenJarvis = ''
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
          return
        }
        // ************** ENVIO DE MAIL ************** //
        let emailPeticion = await fetch(config.URL_API + 'adminEmails/sendMailPaymentSuccessful', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          },
          body: JSON.stringify(datos.bodyEmail)
        })
        let emailRes = await emailPeticion.json()
        let error = 0
        if (emailRes.hasOwnProperty('exito')){
          if (parseInt(emailRes.exito) === 0){
            // es por token expirado?
            if (emailRes.hasOwnProperty('message')){
              if (emailRes.message.indexOf('expired')){
                error = 1
              }
            }
            if (emailRes.hasOwnProperty('error')){
              if (emailRes.error.indexOf('expired')){
                error = 1
              }
            }
          }
          if (parseInt(error) === 1){
            this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
            return
          }
        }
        // ************ FIN ENVIO DE MAIL ************ //
        console.log('emailRes', emailRes)
      }catch(error){
        console.log('sale por error', error)
      }
    },
    async sendEmailPetition({commit}, datos){
      let sendRespuesta = {}
      try{
        // token de JARVIS, tengo el token de la API de Jarvis en localStorage?
        let tokenJarvis = ''
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }
        // ************** ENVIO DE MAIL ************** //
        let emailPeticion = await fetch(config.URL_API + 'adminEmails/setAnEmailPetition', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          },
          body: JSON.stringify(datos)
        })
        let emailRes = await emailPeticion.json()
        let error = 0
        if (emailRes.hasOwnProperty('exito')){
          if (parseInt(emailRes.exito) === 0){
            // es por token expirado?
            if (emailRes.hasOwnProperty('message')){
              if (emailRes.message.indexOf('expired')){
                error = 1
              }
            }
            if (emailRes.hasOwnProperty('error')){
              if (emailRes.error.indexOf('expired')){
                error = 1
              }
            }
          }
          if (parseInt(error) === 1){
            this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
            return
          }
        }
        // ************ FIN ENVIO DE MAIL ************ //
        sendRespuesta.resultado = 1
        sendRespuesta.msj = 'OK'
        commit('setEmailPetition', sendRespuesta)
      }catch{
        sendRespuesta.resultado = 0
        sendRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo sendEmailPetition: ' + error.message
        commit('setEmailPetition', sendRespuesta)
      }
    },
    /* Admin */
    async getDashBoardAdmin({commit, dispatch}){
      let dashBoardRespuesta = {}
      try{
        let dashPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/menu_admin.json?orderBy="inhabilitado"&equalTo=0')
        let dash = await dashPeticion.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: dashPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getDashBoardAdmin'
        // })
        dash = Object.values(dash)
        dashBoardRespuesta.resultado = 1
        dashBoardRespuesta.msj = 'OK'
        dashBoardRespuesta.dash = dash
        commit('setDashBoardAdmin', dashBoardRespuesta)
      }catch(error){
        dashBoardRespuesta.resultado = 0
        dashBoardRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getDashBoardAdmin: ' + error.message
        dashBoardRespuesta.dash = null
        commit('setDashBoardAdmin', dashBoardRespuesta)
      }
    },
    async getEstadosPedidos({commit}){
      let estadosRespuesta = {}
      try{
        let estadosPeticion = await fetch(config.URL_CELUSHOP + 'pedidos_configuracion/estados.json')
        let estados = await estadosPeticion.json()
        // guardo la key
        for (let id in estados){
          estados[id].codigo = id
          estados[id].estado = (estados[id].estado == null || estados[id].estado == undefined) ? '' : estados[id].estado.toString().toUpperCase()
        }
        estadosRespuesta.resultado = 1
        estadosRespuesta.msj = 'OK'
        estadosRespuesta.estados = estados
        commit('setEstadosPedidos', estadosRespuesta)
      }catch(error){
        estadosRespuesta.resultado = 0
        estadosRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getEstadosPedidos: ' + error.message
        estadosRespuesta.estados = null
        commit('setEstadosPedidos', estadosRespuesta)
      }
    },
    async getEstadosCobros({commit}){
      let estadosRespuesta = {}
      try{
        let estadosPeticion = await fetch(config.URL_CELUSHOP + 'cobros_estados.json?orderBy="estado_inhabilita"&equalTo=0')
        let estados = await estadosPeticion.json()
        // guardo la key
        for (let id in estados){
          estados[id].key_firebase = id
        }
        estados = Object.values(estados)
        estadosRespuesta.resultado = 1
        estadosRespuesta.msj = 'OK'
        estadosRespuesta.estados = estados
        commit('setEstadosCobros', estadosRespuesta)
      }catch(error){
        estadosRespuesta.resultado = 0
        estadosRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getEstadosCobros: ' + error.message
        estadosRespuesta.estados = null
        commit('setEstadosCobros', estadosRespuesta)
      }
    },
    async getVentasEcommerce({commit, dispatch}, parametros){
      let ventasRespuesta = {}
      try{
        // token vencido?
        let expirado = jwt.isExpired(parametros.token)
        if (expirado == true){
          ventasRespuesta.resultado = 0
          ventasRespuesta.msj = 'Token expirado'
          ventasRespuesta.tokenExpirado = 1,
          ventasRespuesta.ventas = null
          commit('setVentasEcommerce', ventasRespuesta)
          return
        }
        let ventasPeticion = await fetch(config.URL_CELUSHOP + `pedidos.json?orderBy="estado"&equalTo="${parametros.estado}"&auth=${parametros.token}`)
        let ventas = await ventasPeticion.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: ventasPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'pedidos',
        //   metodo: 'getVentasEcommerce'
        // })
        // algun error?
        if (ventas.hasOwnProperty('error')){
          // expiro el token?
          if (ventas.error.indexOf('token') !== -1 && ventas.error.indexOf('expired') !== -1){
            ventasRespuesta.resultado = 0
            ventasRespuesta.msj = 'Token expirado'
            ventasRespuesta.tokenExpirado = 1,
            ventasRespuesta.ventas = null
          }else{
            ventasRespuesta.resultado = 0
            ventasRespuesta.msj = ventas.error
            ventasRespuesta.tokenExpirado = 0,
            ventasRespuesta.ventas = null
          }
        }else{
          for (let id in ventas){
            let quitar = 0
            let fechaDesdeParam = ''
            let fechaHastaParam = ''
            // obtengo la fecha de la venta
            let fechaVenta = moment(ventas[id].fecha_log, 'YYYY-MM-DD').format('YYYY-MM-DD')
            // fecha desde?
            if (parametros.fechaDesde != null && parametros.fechaDesde.toString().length > 0) fechaDesdeParam = moment(parametros.fechaDesde, 'YYYY-MM-DD').format('YYYY-MM-DD')
            if (fechaDesdeParam.toString().length > 0 && fechaDesdeParam > fechaVenta) quitar = 1
            // fecha hasta?
            if (parametros.fechaHasta != null && parametros.fechaHasta.toString().length > 0) fechaHastaParam = moment(parametros.fechaHasta, 'YYYY-MM-DD').format('YYYY-MM-DD')
            if (fechaHastaParam.toString().length > 0 && fechaHastaParam < fechaVenta) quitar = 1
            // estado de pedido?
            if (parametros.estadoPedido != -1 && ventas[id].estado_pedido.codigo != null && ventas[id].estado_pedido.codigo != undefined && parseInt(parametros.estadoPedido) != parseInt(ventas[id].estado_pedido.codigo)) quitar = 1
            // quitar?
            if (quitar == 1) delete ventas[id]
          }

          // armo respuesta con las ventas
          for (let id in ventas){
            // formateo mi fecha a dd/mm/yyyy
            ventas[id].fecha_log = ventas[id].fecha_log.substring(0,10)
            let dArr = ventas[id].fecha_log.split("-");
            ventas[id].fecha_grabacion = dArr[2]+ "/" +dArr[1]+ "/" +dArr[0].substring(0,4)
            // guardo la key
            ventas[id].key = id
            // algun error?
            if (!ventas[id].hasOwnProperty('error_codigo')){
              // no tiene error
              ventas[id].error_codigo = 0
              ventas[id].error_mensaje = ' '
            }
            // estado del pedido
            ventas[id].estado_pedido_codigo = ventas[id].estado_pedido.codigo
            ventas[id].estado_pedido = ventas[id].estado_pedido.estado
            // estado del Cobro
            ventas[id].estado_cobro = ventas[id].estado
            // importe final
            ventas[id].importe_final = ventas[id].forma_pago.importe
            // financiacion
            ventas[id].financiacion = ventas[id].forma_pago.financiacion
            // numero de pedido
            if (!ventas[id].hasOwnProperty('numero_pedido')) ventas[id].numero_pedido = id
            // obtengo el cliente asociado
            if (ventas[id].hasOwnProperty('uid') && ventas[id].uid.length > 0){
              let clientePeticion = await fetch(config.URL_CELUSHOP + `clientes/${ventas[id].uid}.json?auth=${parametros.token}`)
              let cliente = await clientePeticion.json()
              if (cliente != null && cliente != undefined){
                // datos personales
                if (cliente.hasOwnProperty('datos_personales')){
                  ventas[id].datos_personales = cliente.datos_personales
                }else{
                  ventas[id].datos_personales = ''
                }
                // nombre de cliente
                if (cliente.hasOwnProperty('email')){
                  ventas[id].displayName = cliente.email
                }else{
                  if (cliente.hasOwnProperty('datos_personales')){
                    ventas[id].displayName = cliente.datos_personales.nombre + ' ' + cliente.datos_personales.apellidos
                  }else{
                    ventas[id].displayName = ' '
                  }
                }
              }else{
                // tomo los datos de facturacion
                ventas[id].datos_personales = ventas[id].datos_facturacion
                ventas[id].displayName = ventas[id].datos_facturacion.email
              }
            }else{
              // cliente sin cuenta en nuestro sitio
              ventas[id].displayName = ventas[id].datos_facturacion.email
              if (ventas[id].hasOwnProperty('datos_facturacion') && typeof ventas[id].datos_facturacion == 'object'){
                ventas[id].datos_personales = ventas[id].datos_facturacion
              }
            }
          }
          ventas = Object.values(ventas)
          ventasRespuesta.resultado = 1
          ventasRespuesta.msj = 'OK'
          ventasRespuesta.tokenExpirado = 0
          ventasRespuesta.ventas = ventas
        }
        commit('setVentasEcommerce', ventasRespuesta)
      }catch(error){
        ventasRespuesta.resultado = 0
        ventasRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getVentasEcommerce: ' + error.message
        ventasRespuesta.tokenExpirado = 0
        ventasRespuesta.ventas = null
        commit('setVentasEcommerce', ventasRespuesta)
      }
    },
    async loginAdmin({commit}, datos){
      let loginRespuesta = {}
      try{
        let headers = {
          'Content-Type': 'application/json'
        }
        let loginPeticion = await fetch(config.URL_AUTH + `accounts:signInWithPassword?key=${config.KEY_API_WEB}&email=${datos.usuario}&password=${datos.clave}&returnSecureToken=true`, {
          method: 'POST',
          headers: headers
        })
        let login = await loginPeticion.json()
        // obtengo un objeto con los datos del usuario o bien con un error
        if (login.hasOwnProperty('error')){
          // marco la respuesta como error
          loginRespuesta.resultado = 0
          loginRespuesta.msj = 'El Usuario y/o Clave proporcionados son incorrectos'
          loginRespuesta.token = null
        }else{
          // login exitoso
          loginRespuesta.resultado = 1
          loginRespuesta.msj = 'OK'
          loginRespuesta.token = login.idToken
        }
        commit('setUsuarioAdmin', loginRespuesta)
      }catch(error){
        loginRespuesta.resultado = 0
        loginRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo loginAdmin: ' + error.message
        loginRespuesta.token = null
        commit('setUsuarioAdmin', loginRespuesta)
      }
    },
    async enviarPedido({commit}, item){
      let envioRespuesta = {}
      try{
        // defino el estado a actualizar
        let estado = {
          codigo: 4,
          estado : "Enviado",
          estado_cliente: "En tránsito"
        }
        // defino los campos de la estructura a actualizar
        let bodyLocal = {
          "estado_pedido": estado
        }
        let key = ''
        if (item.hasOwnProperty('key_firebase') && item.key_firebase != null && item.key_firebase != undefined && item.key_firebase.toString().length > 0) key = item.key_firebase
        if (key.toString().length == 0){
          if (item.hasOwnProperty('key') && item.key != null && item.key != undefined && item.key.toString().length > 0) key = item.key
        }
        if (key.toString().length == 0){
          envioRespuesta.resultado = 0
          envioRespuesta.msj = 'No se pudo determinar la Key del Pedido a marcar.'
          envioRespuesta.estado = estado
          envioRespuesta.item = item
          commit('setEstadoPedido', envioRespuesta)
          return
        }
        // primero actualizo el pedido
        let envioActualizacion = await fetch(config.URL_CELUSHOP + `pedidos/${key}.json`,{
          method: 'PATCH',
          body: JSON.stringify(bodyLocal)
        })
        let envio = await envioActualizacion.json()
        // controlo la respuesta
        if (envio.hasOwnProperty('error')){
          // error al actualizar
          envioRespuesta.resultado = 0
          envioRespuesta.msj = envio.error
          envioRespuesta.estado = estado
          envioRespuesta.item = item
          commit('setEstadoPedido', envioRespuesta)
        }else{
          // luego actualizo el pedido en el cliente
          let envioPedidoAct = await fetch(config.URL_CELUSHOP + `clientes/${item.uid}/pedidos/${key}.json`, {
            method: 'PATCH',
            body: JSON.stringify(bodyLocal)
          })
          let pedidoCliente = await envioPedidoAct.json()
          // controlo la respuesta
          if (pedidoCliente.hasOwnProperty('error')){
            // error al actualizar
            envioRespuesta.resultado = 0
            envioRespuesta.msj = pedidoCliente.error
            envioRespuesta.estado = estado
            envioRespuesta.item = item
            commit('setEstadoPedido', envioRespuesta)
          }else{
            // todo OK
            envioRespuesta.resultado = 1
            envioRespuesta.msj = 'OK'
            envioRespuesta.estado = estado
            envioRespuesta.item = item
            commit('setEstadoPedido', envioRespuesta)
          }
        }
      }catch(error){
        envioRespuesta.resultado = 0
        envioRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo enviarPedido: ' + error.message
        envioRespuesta.estado = null
        envioRespuesta.item = null
        commit('setEstadoPedido', envioRespuesta)
      }
    },
    async volverEstadoPedido({commit}, datos){
      let estadoRespuesta = {}
      try{
        // defino los campos de la estructura a actualizar
        let bodyLocal = {
          "estado_pedido": datos.estado
        }
        // primero actualizo el pedido
        let envioActualizacion = await fetch(config.URL_CELUSHOP + `pedidos/${datos.item.key_firebase}.json`,{
          method: 'PATCH',
          body: JSON.stringify(bodyLocal)
        })
        let envio = await envioActualizacion.json()
        // controlo la respuesta
        if (envio.hasOwnProperty('error')){
          // error al actualizar
          estadoRespuesta.resultado = 0
          estadoRespuesta.msj = envio.error
          estadoRespuesta.estado = datos.estado
          estadoRespuesta.item = datos.item
          commit('setEstadoPedido', estadoRespuesta)
        }else{
          // luego actualizo el pedido en el cliente
          let envioPedidoAct = await fetch(config.URL_CELUSHOP + `clientes/${datos.item.uid}/pedidos/${datos.item.key_firebase}.json`, {
            method: 'PATCH',
            body: JSON.stringify(bodyLocal)
          })
          let pedidoCliente = await envioPedidoAct.json()
          // controlo la respuesta
          if (pedidoCliente.hasOwnProperty('error')){
            // error al actualizar
            estadoRespuesta.resultado = 0
            estadoRespuesta.msj = pedidoCliente.error
            estadoRespuesta.estado = datos.estado
            estadoRespuesta.item = datos.item
            commit('setEstadoPedido', estadoRespuesta)
          }else{
            // todo OK
            estadoRespuesta.resultado = 1
            estadoRespuesta.msj = 'OK'
            estadoRespuesta.estado = datos.estado
            estadoRespuesta.item = datos.item
            commit('setEstadoPedido', estadoRespuesta)
          }
        }
      }catch(error){
        estadoRespuesta.resultado = 0
        estadoRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo volverEstadoPedido: ' + error.message
        estadoRespuesta.estado = null
        estadoRespuesta.item = null
        commit('setEstadoPedido', estadoRespuesta)
      }
    },
    async getArticulosAdmin({commit, dispatch}){
      let articulosRespuesta = {}
      let listado = []
      try{
        // capto los articulos con StockTotal mayores o iguales a 0
        let peticionArticulos = await fetch(config.URL_CELUSHOP + 'articulos.json?orderBy="stock/stockTotal"&startAt="0"')
        let articulos = await peticionArticulos.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionArticulos.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'articulos',
        //   metodo: 'getArticulosAdmin'
        // })
        // obtuve resultados?
        if (Object.values(articulos).length > 0){
          for (let id in articulos){
            // debe tener imagenes mini y ese campo debe ser un listado de imagenes
            if (articulos[id].hasOwnProperty('imagenes_mini') && articulos[id].hasOwnProperty('precios') && parseInt(articulos[id].precios) > 0){
              // es un listado?
              if (Array.isArray(Object.values(articulos[id].imagenes_mini))){
                if (Object.values(articulos[id].imagenes_mini).length > 0){
                  // guardo mi key de Firebase
                  articulos[id].key_firebase = id
                  // ya existe en mi listado?
                  let cont = 0
                  for (let jd in listado){
                    if (listado[jd].key_firebase == articulos[id].key_firebase){
                      cont = cont + 1
                    }
                  }
                  if (parseInt(cont) === 0){
                    listado.push(articulos[id])
                  }
                }
              }
            }
          }
        }
        articulosRespuesta.resultado = 1
        articulosRespuesta.msj = 'OK'
        articulosRespuesta.articulos = listado
        commit('setArticulosAdmin', articulosRespuesta)
      }catch(error){
        articulosRespuesta.resultado = 0
        articulosRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getArticulosAdmin: ' + error.message
        articulosRespuesta.articulos = null
        commit('setArticulosAdmin', articulosRespuesta)
      }
    },
    async updateArtAdmin({commit, dispatch}, datos){
      let articuloRespuesta = {}
      try{
        let tokenJarvis = ''
        if (localStorage.getItem('datosApiJarvis')){
            tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
            // pido un token
            this.dispatch('restablecerDatosApi', {key: ''})
            return
        }
        let actualizaPeticion = await fetch(config.URL_CELUSHOP + `articulos/${datos.keyFirebase}.json`,{
          method: 'PATCH',
          body: JSON.stringify(datos.body)
        })
        let actualizacion = await actualizaPeticion.json()
        // controlo la respuesta
        if (actualizacion.hasOwnProperty('error')){
          articuloRespuesta.resultado = 0
          articuloRespuesta.msj = actualizacion.error
          articuloRespuesta.actualizacion = null
          commit('setUpdateArtEcommerce', articuloRespuesta)
        }else{
          // 14-06-2022, termine con firebase, actualizo en oracle
          // ************** llamo a la API de JARVIS para hacer el cobro ************** //
          let updatePeticion = await fetch(config.URL_API + 'ecommerce/updateArticuloEcommer', {
              method: 'POST',
              headers: {
              'Content-Type': 'application/json',
              'Authorization': tokenJarvis.tokenJarvis
              },
              body: JSON.stringify(datos)
          })
          let update = await updatePeticion.json()
          if (update.hasOwnProperty('exito')){
            if (parseInt(update.exito) === 0){
              // es por token expirado?
              if (update.hasOwnProperty('message')){
                if (update.message.indexOf('expired')){
                  error = 1
                }
              }
              if (update.hasOwnProperty('error')){
                if (update.error.indexOf('expired')){
                  error = 1
                }
              }
            }
            if (parseInt(error) === 1){
              this.dispatch('restablecerDatosApi', {key: ''})
              return
            }
          }
          if (update.resultado == 1){
            articuloRespuesta.resultado = 1
            articuloRespuesta.msj = 'OK'
            articuloRespuesta.actualizacion = actualizacion
            commit('setUpdateArtEcommerce', articuloRespuesta)
          }else{
            articuloRespuesta.resultado = 0
            articuloRespuesta.msj = update.message
            articuloRespuesta.actualizacion = null
            commit('setUpdateArtEcommerce', articuloRespuesta)
          }
        }
      }catch(error){
        articuloRespuesta.resultado = 0
        articuloRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo updateArtAdmin: ' + error.message
        articuloRespuesta.actualizacion = null
        commit('setUpdateArtEcommerce', articuloRespuesta)
      }
    },
    async uploadImagesEcommerce({commit}, datos){
      let subidaRespuesta = {}
      try{
        for (let id in datos.files){
          let random = Math.floor((Math.random() * 1000000000) + 1)
          let refImagen = ''
          // obtengo la ruta a pegar la imagen dependiendo si es grande o mini
          if (parseInt(datos.opcion) === 1){
            refImagen = storage.ref().child('articulos/'+datos.articuloCodigo+'/'+datos.articuloCodigo+'_'+random)
          }else{
            refImagen = storage.ref().child('articulos_mini/'+datos.articuloCodigo+'/'+datos.articuloCodigo+'_'+random)
          }
          // pego la imagen
          let res = await refImagen.put(datos.files[id])
          // obtengo su url de descarga
          let urlDescarga = await refImagen.getDownloadURL()
          // asocio dependiendo si es imagen grande o mini
          let ref = ''
          if (parseInt(datos.opcion) === 1){
            ref = firebase.database().ref('articulos/'+ datos.keyFirebase).child('imagenes')
          }else{
            ref = firebase.database().ref('articulos/'+ datos.keyFirebase).child('imagenes_mini')
          }
          ref.push({
            url : urlDescarga
          })
        }
        // todo ok
        subidaRespuesta.resultado = 1
        subidaRespuesta.msj = 'OK'
        subidaRespuesta.opcion = datos.opcion
        commit('setUploadImages', subidaRespuesta)
      }catch(error){
        subidaRespuesta.resultado = 0
        subidaRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo uploadImagesEcommerce: ' + error.message
        subidaRespuesta.opcion = datos.opcion
        commit('setUploadImages', subidaRespuesta)
      }
    },
    async getBannersEcommerce({commit, dispatch}){
      let bannersRespuesta = {}
      try{
        // banners para carrusel cycle
        let peticionBanner = await fetch(config.URL_CELUSHOP + 'configuraciones/banners.json')
        let banners = await peticionBanner.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionBanner.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getBannersEcommerce'
        // })
        let i
        for (i in banners){
          // guardo la key
          banners[i].key_firebase = i
        }
        banners = Object.values(banners)
        // banners para las tarjetas
        let peticionBannerInicio = await fetch(config.URL_CELUSHOP + 'configuraciones/bannersInicio.json')
        let bannersInicio = await peticionBannerInicio.json()
        // logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: peticionBannerInicio.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getBannersEcommerce 2'
        // })
        for (let id in bannersInicio){
          // guardo la key
          bannersInicio[id].key_firebase = id
        }
        bannersInicio = Object.values(bannersInicio)
        // defino mi respuesta
        let bannersListados = {
          bannersCarrusel: banners,
          bannersInicio: bannersInicio
        }
        bannersRespuesta.resultado = 1
        bannersRespuesta.msj = 'OK'
        bannersRespuesta.banners = bannersListados
        commit('setBanners', bannersRespuesta)
      }catch(error){
        bannersRespuesta.resultado = 0
        bannersRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getBannersEcommerce: ' + error.message
        bannersRespuesta.banners = null
        commit('setBanners', bannersRespuesta)
      }
    },
    async newBanner({commit}, data){
      let bannerRespuesta = {}
      try{
        if(data.file.length == 0){
          var ref =  firebase.database().ref('/configuraciones/banners')
          ref.push({
            data
          }, function(error) {
            if(error){
              bannerRespuesta.resultado = 0
              bannerRespuesta.msj = error
              commit('setNewBanner', bannerRespuesta)
              return
            }
          })
        }else{
          // numero random
          let random = Math.floor((Math.random() * 1000000000) + 1)
          // posicion en la ruta de storage
          const refImagen = storage.ref().child('banners/'+data.nombre+'_'+random)
          // insercion de la imagen
          const res = await refImagen.put(data.file[0])
          // obtenemos la url de descarga
          let urlDescarga = await refImagen.getDownloadURL()
          // la asociamos en el nodo de firebase
          var ref =  firebase.database().ref('/configuraciones/banners')
          ref.push({
            nombre: data.nombre,
            inhabilitado: data.inhabilitado,
            url_redirect : data.url_redirect,
            url_imagen: urlDescarga
          }, function(error) {
            if (error){
              bannerRespuesta.resultado = 0
              bannerRespuesta.msj = error
              commit('setNewBanner', bannerRespuesta)
              return
            }
          })
        }
        bannerRespuesta.resultado = 1
        bannerRespuesta.msj = 'OK'
        commit('setNewBanner', bannerRespuesta)
      }catch(error){
        bannerRespuesta.resultado = 0
        bannerRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo newBanner: ' + error.message
        commit('setNewBanner', bannerRespuesta)
      }
    },
    async updateBannerAdmin({commit}, data){
      let actualizacionRespuesta = {}
      try{
        if(data.file.length == 0){ 
          //actualizar solo info del banner
          var ref =  firebase.database().ref('/configuraciones/banners/'+data.key)
          ref.update({
            nombre: data.nombre,
            inhabilitado: data.inhabilitado,
            url_redirect: data.url_redirect,
          }, function(error){
            if (error){
              actualizacionRespuesta.resultado = 0
              actualizacionRespuesta.msj = error
              commit('setUpdateBanner', actualizacionRespuesta)
              return
            }
          })
        }else{ 
          //actualizar info e imagen del banner
          let random = Math.floor((Math.random() * 1000000000) + 1)
          // posicion en el storage de Firebase
          const refImagen = storage.ref().child('banners/'+data.nombre+'_'+random)
          // insercion de la imagen
          const res = await refImagen.put(data.file[0])
          // obtenemos la url de descarga
          let urlDescarga = await refImagen.getDownloadURL()
          // actualizamos en el nodo de banners la nueva url
          var ref =  firebase.database().ref('/configuraciones/banners/'+data.key)
          ref.update({
            nombre: data.nombre,
            inhabilitado: data.inhabilitado,
            url_redirect: data.url_redirect,
            url_imagen: urlDescarga
          }, function(error) {
            if (error){
              actualizacionRespuesta.resultado = 0
              actualizacionRespuesta.msj = error
              commit('setUpdateBanner', actualizacionRespuesta)
              return
            }
          })
        }
        // Todo OK
        actualizacionRespuesta.resultado = 1
        actualizacionRespuesta.msj = 'OK'
        commit('setUpdateBanner', actualizacionRespuesta)
      }catch(error){
        actualizacionRespuesta.resultado = 0
        actualizacionRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo updateBannerAdmin: ' + error.message
        commit('setUpdateBanner', actualizacionRespuesta)
      }
    },
    async getInfoContacto({commit, dispatch}){
      let contactoRespuesta = {}
      try{
        let contactoPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones.json')
        let contacto = await contactoPeticion.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: contactoPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getInfoContacto'
        // })
        contactoRespuesta.resultado = 1
        contactoRespuesta.msj = 'OK'
        contactoRespuesta.contacto = contacto
        commit('setContactoAdmin', contactoRespuesta)
      }catch(error){
        contactoRespuesta.resultado = 0
        contactoRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getInfoContacto: ' + error.message
        contactoRespuesta.contacto = null
        commit('setContactoAdmin', contactoRespuesta)
      }
    },
    async updateLocalidad({commit}, data){
      let actualizarRespuesta = {}
      try{
        var ref = firebase.database().ref('/configuraciones/localidades/'+data.index)
        ref.update({
          provincia: data.provincia,
          localidad: data.localidad,
          departamento: data.departamento,
          codigo_postal: data.codigo_postal,
          estado: data.estado,
          retiro_local: data.retiro_local,
          entrega_domicilio: data.entrega_domicilio
        }, function(error) {
          if(error){
            actualizarRespuesta.resultado = 0
            actualizarRespuesta.msj = error
            commit('setUpdateLocalidad', actualizarRespuesta)
            return
          }
        })
        // TODO OK
        actualizarRespuesta.resultado = 1
        actualizarRespuesta.msj = 'OK'
        commit('setUpdateLocalidad', actualizarRespuesta)
      }catch(error){
        actualizarRespuesta.resultado = 0
        actualizarRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo updateLocalidad: ' + error.message
        commit('setUpdateLocalidad', actualizarRespuesta)
      }
    },
    async getSucursalesAdmin({commit}){
      let sucursalesRespuesta = {}
      try{
        let data = []
        var ref =  await firebase.database().ref('sucursales').once('value')
          .then(function(snapshot){
            let key = snapshot.key
            let childKey = snapshot.child("sucursales/locales").key
            data = snapshot.val()																				
          })
          .catch(error => {
            sucursalesRespuesta.resultado = 0
            sucursalesRespuesta.msj = error
            sucursalesRespuesta.sucursales = null
            commit('setSucursalesAdmin', sucursalesRespuesta)
            return
          })
        sucursalesRespuesta.resultado = 1
        sucursalesRespuesta.msj = 'OK'
        sucursalesRespuesta.sucursales = data
        commit('setSucursalesAdmin', sucursalesRespuesta)
      }catch(error){
        sucursalesRespuesta.resultado = 0
        sucursalesRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getSucursalesAdmin: ' + error.message
        sucursalesRespuesta.sucursales = null
        commit('setSucursalesAdmin', sucursalesRespuesta)
      }
    },
    async setNewLocal({commit}, data){
      let newLocalRespuesta = {}
      try{
        let newLocalPeticion = ''
        if (parseInt(data.opcion) === 1){
          // genero una nueva key para la coleccion
          var myRef = firebase.database().ref().push()
          var newKey = myRef.key
          // inserto el registro
          let body = {
            key : newKey,
            estado : 1,
            direccion : data.localNombre,
            deposito_codigo: data.depCodigo
          }
          newLocalPeticion = await fetch(config.URL_CELUSHOP + `sucursales/${data.keySucursal}/locales/${newKey}.json?auth=${data.token}`,{
            method: 'PATCH',
            body: JSON.stringify(body)
          })
        }else{
          newLocalPeticion = await fetch(config.URL_CELUSHOP + `sucursales/${data.keySucursal}/locales/${data.keyLocal}.json?auth=${data.token}`,{
            method: 'PATCH',
            body: JSON.stringify(data.body)
          })
        }
        let newLocal = await newLocalPeticion.json()
        // algun error?
        if (newLocal.hasOwnProperty('error')){
          // es de token expirado?
          if (parseInt(newLocal.error.indexOf('token')) !== -1 && parseInt(newLocal.error.indexOf('expired'))){
            // expirado
            newLocalRespuesta.resultado = 0
            newLocalRespuesta.msj = newLocal.error
            newLocalRespuesta.tokenExpirado = 1
            newLocalRespuesta.opcion = data.opcion
          }else{
            // otro error
            newLocalRespuesta.resultado = 0
            newLocalRespuesta.msj = newLocal.error
            newLocalRespuesta.tokenExpirado = 0
            newLocalRespuesta.opcion = data.opcion
          }
          commit('setNewLocalResp', newLocalRespuesta)
        }else{
          newLocalRespuesta.resultado = 1
          newLocalRespuesta.msj = 'OK'
          newLocalRespuesta.tokenExpirado = 0
          newLocalRespuesta.opcion = data.opcion
          commit('setNewLocalResp', newLocalRespuesta)
        }
      }catch(error){
        newLocalRespuesta.resultado = 0
        newLocalRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo setNewLocal: ' + error.message
        newLocalRespuesta.tokenExpirado = 0
        newLocalRespuesta.opcion = data.opcion
        commit('setNewLocalResp', newLocalRespuesta)
      }
    },
    async getPublicidadAdmin({commit, dispatch}){
      let publicidadRespuesta = {}
      try{
        let publicidadPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/bannersInicio.json')
        let publicidad = await publicidadPeticion.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: publicidadPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getPublicidadAdmin'
        // })
        // guardamos la key
        for (let id in publicidad){
          publicidad[id].key_firebase = id
        }
        publicidad = Object.values(publicidad)
        publicidadRespuesta.resultado = 1
        publicidadRespuesta.msj = 'OK'
        publicidadRespuesta.publicidad = publicidad
        commit('setPublicidadAdmin', publicidadRespuesta)
      }catch(error){
        publicidadRespuesta.resultado = 0
        publicidadRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getPublicidadAdmin: ' + error.message
        publicidadRespuesta.publicidad = null
        commit('setPublicidadAdmin', publicidadRespuesta)
      }
    },
    async newPublicidad({commit}, data){
      let publicidadRespuesta = {}
      try{
        if(data.file.length == 0){
          var ref =  firebase.database().ref('/configuraciones/bannersInicio')
          ref.push({
            data
          }, function(error) {
            if(error){
              publicidadRespuesta.resultado = 0
              publicidadRespuesta.msj = error
              commit('setNewPublicidad', publicidadRespuesta)
              return
            }
          })
        }else{
          // numero random
          let random = Math.floor((Math.random() * 1000000000) + 1)
          // posicion en la ruta de storage
          const refImagen = storage.ref().child('banners_inicio/'+data.nombre+'_'+random)
          // insercion de la imagen
          const res = await refImagen.put(data.file[0])
          // obtenemos la url de descarga
          let urlDescarga = await refImagen.getDownloadURL()
          // la asociamos en el nodo de firebase
          var ref =  firebase.database().ref('/configuraciones/bannersInicio')
          ref.push({
            nombre: data.nombre,
            inhabilitado: data.inhabilitado,
            url_redirect : data.url_redirect,
            url_imagen: urlDescarga,
            orden: data.orden
          }, function(error) {
            if (error){
              publicidadRespuesta.resultado = 0
              publicidadRespuesta.msj = error
              commit('setNewPublicidad', publicidadRespuesta)
              return
            }
          })
        }
        publicidadRespuesta.resultado = 1
        publicidadRespuesta.msj = 'OK'
        commit('setNewPublicidad', publicidadRespuesta)
      }catch(error){
        publicidadRespuesta.resultado = 0
        publicidadRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo newPublicidad: ' + error.message
        commit('setNewPublicidad', publicidadRespuesta)
      }
    },
    async updatePublicidad({commit}, data){
      let updateRespuesta = {}
      try{
        if(data.file.length == 0){ 
          //actualizar solo info del banner
          var ref =  firebase.database().ref('/configuraciones/bannersInicio/'+data.key)
          ref.update({
            nombre: data.nombre,
            inhabilitado: data.inhabilitado,
            url_redirect: data.url_redirect,
          }, function(error){
            if (error){
              updateRespuesta.resultado = 0
              updateRespuesta.msj = error
              commit('setUpdatePublicidad', updateRespuesta)
              return
            }
          })
        }else{ 
          //actualizar info e imagen del banner
          let random = Math.floor((Math.random() * 1000000000) + 1)
          // posicion en el storage de Firebase
          const refImagen = storage.ref().child('banners_inicio/'+data.nombre+'_'+random)
          // insercion de la imagen
          const res = await refImagen.put(data.file[0])
          // obtenemos la url de descarga
          let urlDescarga = await refImagen.getDownloadURL()
          // actualizamos en el nodo de banners la nueva url
          var ref =  firebase.database().ref('/configuraciones/bannersInicio/'+data.key)
          ref.update({
            nombre: data.nombre,
            inhabilitado: data.inhabilitado,
            url_redirect: data.url_redirect,
            url_imagen: urlDescarga
          }, function(error) {
            if (error){
              updateRespuesta.resultado = 0
              updateRespuesta.msj = error
              commit('setUpdatePublicidad', updateRespuesta)
              return
            }
          })
        }
        // Todo OK
        updateRespuesta.resultado = 1
        updateRespuesta.msj = 'OK'
        commit('setUpdatePublicidad', updateRespuesta)
      }catch(error){
        updateRespuesta.resultado = 0
        updateRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo updatePublicidad: ' + error.message
        commit('setUpdatePublicidad', updateRespuesta)
      }
    },
    async getCarruselesAdmin({commit, dispatch}){
      let carruselesRespuesta = {}
      try{
        // consigo los carruseles
        let carruselesPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/carruseles_inicio.json')
        let carruseles = await carruselesPeticion.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: carruselesPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getCarruselesAdmin'
        // })
        // guardo su key
        for (let id in carruseles){
          carruseles[id].key_firebase = id
        }
        // consigo los criterios de carruseles
        let criteriosPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/criterios_carruseles.json')
        let criterios = await criteriosPeticion.json()
        // logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: criteriosPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getCarruselesAdmin 2'
        // })
        // guardo su key
        for (let id in criterios){
          criterios[id].key_firebase = id
        }
        carruselesRespuesta.resultado = 1
        carruselesRespuesta.msj = 'OK'
        carruselesRespuesta.carruseles = carruseles
        carruselesRespuesta.criterios = criterios
        commit('setCarruselesAdmin', carruselesRespuesta)
      }catch(error){
        carruselesRespuesta.resultado = 0
        carruselesRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getCarruselesAdmin: ' + error.message
        carruselesRespuesta.carruseles = null
        carruselesRespuesta.criterios = null
        commit('setCarruselesAdmin', carruselesRespuesta)
      }
    },
    async addCarouselAdmin({commit, dispatch}, data){
      let carruselRespuesta = {}
      try{
        let carruselPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/carruseles_inicio.json',{
          method: 'POST',
          body: JSON.stringify(data)
        })
        let carrusel = await carruselPeticion.json()
        carruselRespuesta.resultado = 1
        carruselRespuesta.msj = 'OK'
        carruselRespuesta.carrusel = carrusel
        commit('setAddCarruselAdmin', carruselRespuesta)
      }catch(error){
        carruselRespuesta.resultado = 0
        carruselRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo addCarouselAdmin: ' + error.message
        carruselRespuesta.carrusel = null
        commit('setAddCarruselAdmin', carruselRespuesta)
      }
    },
    async updateCarouselAdmin({commit}, data){
      let updateRespuesta = {}
      try{
        let body = {
          criterio: data.criterio,
          inhabilitado: data.inhabilitado,
          nombre: data.nombre,
          orden: data.orden
        }
        let updatePeticion = await fetch(config.URL_CELUSHOP + `configuraciones/carruseles_inicio/${data.key}.json`, {
          method: 'PATCH',
          body: JSON.stringify(body)
        })
        let updateCarrusel = await updatePeticion.json()
        updateRespuesta.resultado = 1
        updateRespuesta.msj = 'OK'
        commit('setUpdateCarruselAdmin', updateRespuesta)
      }catch(error){
        updateRespuesta.resultado = 0
        updateRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo updateCarouselAdmin: ' + error.message
        commit('setUpdateCarruselAdmin', updateRespuesta)
      }
    },
    async getLocalesXSucursal({commit}, sucursal){
      let localesRespuesta = {}
      try{
        let tokenJarvis = ''
        // tengo el token de la API de Jarvis en localStorage?
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: ''})
          return
        }
        // peticion a la API
        let localesPeticion = await fetch(config.URL_API + `locales/getLocalesXSucursal?sucursal_codigo=${sucursal}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          }
        })
        let locales = await localesPeticion.json()
        if (parseInt(locales.resultado) === 0){
          // el token no es valido?
          if (locales.hasOwnProperty('exito')){
            if (parseInt(articulos.exito) === 0){
              // token vencido, debo pedir otro
              this.dispatch('restablecerDatosApi', {key: ''})
              return
            }
          }
        }
        // el token no es valido?
        if (locales.hasOwnProperty('exito')){
          if (parseInt(locales.exito) === 0){
            // token vencido, debo pedir otro
            this.dispatch('restablecerDatosApi', {key: ''})
            return
          }
        }
        localesRespuesta.resultado = locales.resultado
        localesRespuesta.msj = locales.msj
        localesRespuesta.locales = locales.localesSucursal
        commit('setLocalesSucursal', localesRespuesta)
      }catch(error){
        localesRespuesta.resultado = 0
        localesRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getLocalesXSucursal: ' + error.message
        localesRespuesta.locales = null
        commit('setLocalesSucursal', localesRespuesta)
      }
    },
    async cancelPayment({commit}, datos){
      let anulacionRespuesta = {}
      try{
        let tokenJarvis = ''
        if (localStorage.getItem('datosApiJarvis')){
            tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
            // pido un token
            this.dispatch('restablecerDatosApi', {key: ''})
            return
        }
        // armo el body a enviar
        let bodyAnulacion = {
            transaccion_id: datos.Ipg_TransaccionID,
            empresa_id: 2
        }
        // ************** llamo a la API de JARVIS para hacer el cobro ************** //
        let anulacionPeticion = await fetch(config.URL_API + 'decidir/anular', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
            },
            body: JSON.stringify(bodyAnulacion)
        })
        let anulacionResultado = await anulacionPeticion.json()
        // ************ acaba el cobro, empieza analisis de la respuesta ************ //
        let error = 0
        if (anulacionResultado.hasOwnProperty('exito')){
          if (parseInt(anulacionResultado.exito) === 0){
            // es por token expirado?
            if (anulacionResultado.hasOwnProperty('message')){
              if (anulacionResultado.message.indexOf('expired')){
                error = 1
              }
            }
            if (anulacionResultado.hasOwnProperty('error')){
              if (anulacionResultado.error.indexOf('expired')){
                error = 1
              }
            }
          }
          if (parseInt(error) === 1){
            this.dispatch('restablecerDatosApi', {key: ''})
            return
          }
        }
        
        anulacionRespuesta.resultado = 1
        anulacionRespuesta.msj = 'OK'
        anulacionRespuesta.anulacionDatos = anulacionResultado
        anulacionRespuesta.datosOpciones = datos.terminalID
        commit('setAnulacionCobro', anulacionRespuesta)
      }catch(error){
        anulacionRespuesta.resultado = 0
        anulacionRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo cancelPayment: ' + error.message
        anulacionRespuesta.anulacionDatos = null
        anulacionRespuesta.datosOpciones = datos.terminalID
        commit('setAnulacionCobro', anulacionRespuesta)
      }
    },
    async updateSale({commit}, datos){
      let updateRespuesta = {}
      try{
        // primero actualizo el pedido en el nodo de pedidos
        let envioActualizacion = await fetch(config.URL_CELUSHOP + `pedidos/${datos.keyPedido}.json`,{
          method: 'PATCH',
          body: JSON.stringify(datos.datosAnulacion)
        })
        let envio = await envioActualizacion.json()
        // controlo la respuesta
        if (envio.hasOwnProperty('error')){
          // error al actualizar
          updateRespuesta.resultado = 0
          updateRespuesta.msj = envio.error
          updateRespuesta.pedido = datos
          commit('setUpdateSale', updateRespuesta)
        }else{
          // luego actualizo el pedido en el cliente
          let envioPedidoAct = await fetch(config.URL_CELUSHOP + `clientes/${datos.keyUsuario}/pedidos/${datos.keyPedido}.json`, {
            method: 'PATCH',
            body: JSON.stringify(datos.datosAnulacion)
          })
          let pedidoCliente = await envioPedidoAct.json()
          // controlo la respuesta
          if (pedidoCliente.hasOwnProperty('error')){
            // error al actualizar
            updateRespuesta.resultado = 0
            updateRespuesta.msj = pedidoCliente.error
            updateRespuesta.pedido = datos
            commit('setUpdateSale', updateRespuesta)
          }else{
            // todo OK
            updateRespuesta.resultado = 1
            updateRespuesta.msj = 'OK'
            updateRespuesta.pedido = datos
            commit('setUpdateSale', updateRespuesta)
          }
        }
      }catch(error){
        updateRespuesta.resultado = 0
        updateRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo updateSale: ' + error.message
        updateRespuesta.pedido = null
        commit('setUpdateSale', updateRespuesta)
      }
    },
    async getArticulosCategorias({commit}){
      let cateRespuesta = {}
      try{
        // consigo las categorias ecommerce
        let categoriasPeticion = await fetch(config.URL_CELUSHOP + 'articulos_categorias.json')
        let categorias = await categoriasPeticion.json()
        // guardo su key
        let listado = []
        for (let id in categorias){
          if (categorias[id] != null){
            categorias[id].key_firebase = id
            listado.push(categorias[id])
          }
        }
        cateRespuesta.resultado = 1
        cateRespuesta.msj = 'OK'
        cateRespuesta.catEcommerce = listado
        commit('setCategoriasAdmin', cateRespuesta)
      }catch(error){
        cateRespuesta.resultado = 0
        cateRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getArticulosCategorias: ' + error.message
        cateRespuesta.catEcommerce = null
        commit('setCategoriasAdmin', cateRespuesta)
      }
    },
    async getCategoriaOracle({commit}, datos){
      let categoriaRespuesta = {}
      try{
        let tokenJarvis = ''
        // tengo el token de la API de Jarvis en localStorage?
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
          return
        }
        // peticion a la Api
        let catPeticion = await fetch(config.URL_API + `ecommerce/getCategoriaEcommerce?nombreCategoria=${datos.categoria.nombre}&padre=${datos.categoria.padre}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          }
        })
        let categ = await catPeticion.json()
        // token no valido?
        if (categ.hasOwnProperty('exito')){
          if (parseInt(categ.exito) === 0){
            // token vencido, debo pedir otro
            this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
            return
          }
        }
        categoriaRespuesta.resultado = categ.resultado
        categoriaRespuesta.msj = categ.message
        categoriaRespuesta.subCategorias = categ.categoriasEcommerce
        commit('setSubCategoriasEcommerce', categoriaRespuesta)
      }catch(error){
        categoriaRespuesta.resultado = 0
        categoriaRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getCategoriaOracle: ' + error.message
        categoriaRespuesta.subCategorias = null
        commit('setSubCategoriasEcommerce', categoriaRespuesta)
      }
    },
    async updateCategoriaEcommerceAdmin({commit}, datos){
      let updateRespuesta = {}
      try{
        // obtengo el token del usuario
        let tokenLocal = JSON.parse(localStorage.getItem('tokenAdmin'))
        // actualizacion
        let actualizaPeticion = await fetch(config.URL_CELUSHOP + `articulos_categorias/${datos.keyFirebase}.json?auth=${tokenLocal}`,{
          method: 'PATCH',
          body: JSON.stringify(datos.categoria)
        })
        let actualizacion = await actualizaPeticion.json()
        // algun error?
        if (actualizacion.hasOwnProperty('error')){
          // es de token expirado?
          if (parseInt(actualizacion.error.indexOf('token')) !== -1 && parseInt(actualizacion.error.indexOf('expired'))){
            // expirado
            updateRespuesta.resultado = 0
            updateRespuesta.msj = actualizacion.error
            updateRespuesta.tokenExpirado = 1
          }else{
            // otro error
            updateRespuesta.resultado = 0
            updateRespuesta.msj = actualizacion.error
            updateRespuesta.tokenExpirado = 0
          }
          commit('setUpdateCatAdmin', updateRespuesta)
        }else{
          let error = 0
          if (datos.nuevos.length > 0){
            // tengo nuevas subCategorias para insertar en firebase
            for (let id in datos.nuevos){
              let actualizaPeticionn = await fetch(config.URL_CELUSHOP + `articulos_categorias/${datos.nuevos[id].id}.json?auth=${tokenLocal}`,{
                method: 'PATCH',
                body: JSON.stringify({
                  filtros: 0,
                  inhabilitado: 0,
                  nombre: datos.nuevos[id].descripcion,
                  padre: datos.nuevos[id].padre
                })
              })
              let actualizacionn = await actualizaPeticionn.json()
              // algun error?
              if (actualizacionn.hasOwnProperty('error')){
                // es de token expirado?
                error = error + 1
                if (parseInt(actualizacionn.error.indexOf('token')) !== -1 && parseInt(actualizacionn.error.indexOf('expired'))){
                  // expirado
                  updateRespuesta.resultado = 0
                  updateRespuesta.msj = actualizacionn.error
                  updateRespuesta.tokenExpirado = 1
                }else{
                  // otro error
                  updateRespuesta.resultado = 0
                  updateRespuesta.msj = actualizacionn.error
                  updateRespuesta.tokenExpirado = 0
                }
              }
            }
          }
          if (parseInt(error) > 0){
            commit('setUpdateCatAdmin', updateRespuesta)
          }else{
            // pregunto si me llegaron subCategorias
            if (datos.subCat.length > 0){
              // actualizo solo el estado de las subCategorias
              for (let id in datos.subCat){
                if (parseInt(datos.subCat[id].keyFirebase) > 0){
                  let actualizaPeticionn = await fetch(config.URL_CELUSHOP + `articulos_categorias/${datos.subCat[id].keyFirebase}.json?auth=${tokenLocal}`,{
                    method: 'PATCH',
                    body: JSON.stringify({
                      inhabilitado: datos.subCat[id].inhabilitado
                    })
                  })
                  let actualizacionn = await actualizaPeticionn.json()
                  // algun error?
                  if (actualizacionn.hasOwnProperty('error')){
                    // es de token expirado?
                    error = error + 1
                    if (parseInt(actualizacionn.error.indexOf('token')) !== -1 && parseInt(actualizacionn.error.indexOf('expired'))){
                      // expirado
                      updateRespuesta.resultado = 0
                      updateRespuesta.msj = actualizacionn.error
                      updateRespuesta.tokenExpirado = 1
                    }else{
                      // otro error
                      updateRespuesta.resultado = 0
                      updateRespuesta.msj = actualizacionn.error
                      updateRespuesta.tokenExpirado = 0
                    }
                  }
                }
              }
              if (parseInt(error) > 0){
                commit('setUpdateCatAdmin', updateRespuesta)
              }else{
                updateRespuesta.resultado = 1
                updateRespuesta.msj = 'OK'
                updateRespuesta.tokenExpirado = 0
                commit('setUpdateCatAdmin', updateRespuesta)
              }
            }else{
              updateRespuesta.resultado = 1
              updateRespuesta.msj = 'OK'
              updateRespuesta.tokenExpirado = 0
              commit('setUpdateCatAdmin', updateRespuesta)
            }
          }
        }
      }catch(error){
        updateRespuesta.resultado = 0
        updateRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo updateCategoriaEcommerceAdmin: ' + error.message
        updateRespuesta.tokenExpirado = 0
        commit('setUpdateCatAdmin', updateRespuesta)
      }
    },
    async setSubCategoryOracle({commit}, datos){
      let insertRespuesta = {}
      try{
        let tokenJarvis = ''
        // tengo el token de la API de Jarvis en localStorage?
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
          return
        }
        // llamada a la Api
        let insertPeticion = await fetch(config.URL_API + `ecommerce/insertCategoriasEcommerce`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          },
          body: JSON.stringify(datos.categorias)
        })
        let insert = await insertPeticion.json()
        // el token no es valido?
        if (insert.hasOwnProperty('exito')){
          if (parseInt(insert.exito) === 0){
            // token vencido, debo pedir otro
            this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
            return
          }
        }
        insertRespuesta.resultado = insert.resultado
        insertRespuesta.msj = insert.message
        insertRespuesta.newCatOracle = insert.categoriasEcommerce
        insertRespuesta.opciones = datos.opcion
        commit('setNewCatEcommerce', insertRespuesta)
      }catch(error){
        insertRespuesta.resultado = 0
        insertRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo setSubCategoryOracle: ' + error.message
        insertRespuesta.newCatOracle = null
        insertRespuesta.opciones = datos.opcion
        commit('setNewCatEcommerce', insertRespuesta)
      }
    },
    async setCategoryOracle({commit}, datos){
      let insertRespuesta = {}
      try{
        let tokenJarvis = ''
        // tengo el token de la API de Jarvis en localStorage?
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
          return
        }
        // llamada a la Api
        let insertPeticion = await fetch(config.URL_API + `ecommerce/insertCategoriaPadreEcommerce`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          },
          body: JSON.stringify(datos.categoria)
        })
        let insert = await insertPeticion.json()
        // el token no es valido?
        if (insert.hasOwnProperty('exito')){
          if (parseInt(insert.exito) === 0){
            // token vencido, debo pedir otro
            this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
            return
          }
        }
        insertRespuesta.resultado = insert.resultado
        insertRespuesta.msj = insert.message
        insertRespuesta.newCatPadreOracle = insert.categoriaPadre
        insertRespuesta.opciones = datos.opcion
        commit('setNewCatPadreEcommerce', insertRespuesta)
      }catch(error){
        insertRespuesta.resultado = 0
        insertRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo setSubCategoryOracle: ' + error.message
        insertRespuesta.newCatPadreOracle = null
        insertRespuesta.opciones = datos.opcion
        commit('setNewCatPadreEcommerce', insertRespuesta)
      }
    },
    async updateCategoriaOracle({commit}, datos){
      let updateRespuesta = {}
      try{
        let tokenJarvis = ''
        // tengo el token de la API de Jarvis en localStorage?
        if (localStorage.getItem('datosApiJarvis')){
          tokenJarvis = JSON.parse(localStorage.getItem('datosApiJarvis'))
        }else{
          // pido un token
          this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
          return
        }
        let updatePeticion = await fetch(config.URL_API + `ecommerce/updateCategoriasOracle`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenJarvis.tokenJarvis
          },
          body: JSON.stringify(datos.categorias)
        })
        let updateRes = await updatePeticion.json()
        // el token no es valido?
        if (updateRes.hasOwnProperty('exito')){
          if (parseInt(updateRes.exito) === 0){
            // token vencido, debo pedir otro
            this.dispatch('restablecerDatosApi', {key: '', opciones: datos.opcion})
            return
          }
        }
        updateRespuesta.resultado = updateRes.resultado
        updateRespuesta.msj = updateRes.message
        updateRespuesta.opciones = datos.opcion
        commit('setUpCategoriasOracleAdmin', updateRespuesta)
      }catch(error){
        updateRespuesta.resultado = 0
        updateRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo updateCategoriaOracle: ' + error.message
        updateRespuesta.opciones = datos.opcion
        commit('setUpCategoriasOracleAdmin', updateRespuesta)
      }
    },
    async getMenusAdmin({commit, dispatch}){
      let menusRespuesta = {}
      try{
        let menusPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/menu.json')
        let menus = await menusPeticion.json()
        // let logPeticion = await dispatch('genericos/regLogRequestSize', {
        //   sistema: 'CELUSHOP',
        //   request_bytes: menusPeticion.headers.get('Content-Length'),
        //   usuario: 'CeluShop',
        //   nodo: 'configuraciones',
        //   metodo: 'getMenusAdmin'
        // })
        // guardo la key de Firebase
        for (let id in menus){
          menus[id].keyFirebase = id
        }
        // pido las categorias
        let categoriasPeticion = await fetch(config.URL_CELUSHOP + 'articulos_categorias.json')
        let categorias = await categoriasPeticion.json()
        // guardo su key
        let listado = []
        for (let id in categorias){
          if (categorias[id] != null){
            categorias[id].key_firebase = id
            listado.push(categorias[id])
          }
        }
        menusRespuesta.resultado = 1
        menusRespuesta.msj = 'OK'
        menusRespuesta.listadoMenus = menus
        menusRespuesta.categorias = listado
        commit('setMenusAdmin', menusRespuesta)
      }catch(error){
        menusRespuesta.resultado = 0
        menusRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getMenusAdmin: ' + error.message
        menusRespuesta.listadoMenus = null
        menusRespuesta.categorias = null
        commit('setMenusAdmin', menusRespuesta)
      }
    },
    async updateMenusAdmin({commit}, datos){
      let updateRespuesta = {}
      try{
        // obtengo el token del usuario
        let tokenLocal = JSON.parse(localStorage.getItem('tokenAdmin'))
        // actualizacion del menu grande primero
        let actualizaPeticion = await fetch(config.URL_CELUSHOP + `configuraciones/menu/${datos.menuGrande.keyFirebase}.json?auth=${tokenLocal}`,{
          method: 'PATCH',
          body: JSON.stringify(datos.menuGrande.menuGrande)
        })
        let actualizacion = await actualizaPeticion.json()
        if (actualizacion.hasOwnProperty('error')){
          // es de token expirado?
          if (parseInt(actualizacion.error.indexOf('token')) !== -1 && parseInt(actualizacion.error.indexOf('expired'))){
            // expirado
            updateRespuesta.resultado = 0
            updateRespuesta.msj = actualizacion.error
            updateRespuesta.tokenExpirado = 1
          }else{
            // otro error
            updateRespuesta.resultado = 0
            updateRespuesta.msj = actualizacion.error
            updateRespuesta.tokenExpirado = 0
          }
          commit('setUpdateMenuAdmin', updateRespuesta)
        }else{
          // si sale bien, prosigo con el menu pequeño siempre y cuando tenga algo
          if (Object.keys(datos.menuPeque).length > 0){
            let actualizaPeticion = await fetch(config.URL_CELUSHOP + `configuraciones/menu/${datos.menuPeque.keyFirebase}.json?auth=${tokenLocal}`,{
              method: 'PATCH',
              body: JSON.stringify(datos.menuPeque.menuPeque)
            })
            let actualizacion = await actualizaPeticion.json()
            if (actualizacion.hasOwnProperty('error')){
              // es de token expirado?
              if (parseInt(actualizacion.error.indexOf('token')) !== -1 && parseInt(actualizacion.error.indexOf('expired'))){
                // expirado
                updateRespuesta.resultado = 0
                updateRespuesta.msj = actualizacion.error
                updateRespuesta.tokenExpirado = 1
              }else{
                // otro error
                updateRespuesta.resultado = 0
                updateRespuesta.msj = actualizacion.error
                updateRespuesta.tokenExpirado = 0
              }
              commit('setUpdateMenuAdmin', updateRespuesta)
            }else{
              updateRespuesta.resultado = 1
              updateRespuesta.msj = 'OK'
              updateRespuesta.tokenExpirado = 0
              commit('setUpdateMenuAdmin', updateRespuesta)
            }
          }else{
            updateRespuesta.resultado = 1
            updateRespuesta.msj = 'OK'
            updateRespuesta.tokenExpirado = 0
            commit('setUpdateMenuAdmin', updateRespuesta)
          }
        }
      }catch(error){
        updateRespuesta.resultado = 0
        updateRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo updateMenusAdmin: ' + error.message
        updateRespuesta.tokenExpirado = 0
        commit('setUpdateMenuAdmin', updateRespuesta)
      }
    },
    /* Fin Admin */
    async executeSnackBar({commit}, datos){
      commit('setSnackBarAviso', datos)
    },
    async loadLocalStorage({commit, dispatch}, datos){
      commit('setLocalStorage', datos)
      // estoy en una ruta donde deba cargar los menus del admin?
      if (datos.meta != null && datos.meta != undefined && datos.meta.requireAuth == true){
        if (!localStorage.getItem('menuAdmin')){
          // paso a pedir los menus para administrador
          let menuPeticion = await dispatch('admin/getMenusAdmin')
          if (menuPeticion.resultado == 0) commit('setMenuAdmin', [])
          else commit('setMenuAdmin', menuPeticion.menus)
        }else{
          // tengo menús para el administrador?
          let menus = JSON.parse(localStorage.getItem('menuAdmin'))
          if (menus.length == 0){
            let menuPeticion = await dispatch('admin/getMenusAdmin')
            if (menuPeticion.resultado == 0) commit('setMenuAdmin', [])
            else commit('setMenuAdmin', menuPeticion.menus)
          }else commit('setMenuAdmin', menus)
        }
      }
    },
    logoutAdmin({commit}){
      if (localStorage.getItem('tokenAdmin')) localStorage.removeItem('tokenAdmin')
      if (localStorage.getItem('menuAdmin')) localStorage.removeItem('menuAdmin')
      commit('setMenuAdmin', [])
      router.push({name: 'LoginAdmin'})
    },
    executeLoadCircular({commit}, datos){
      commit('setModalCircularAdmin', datos)
    }
  },
  modules: {
    cobros,
    pedidos,
    articulos,
    usuarios,
    firebaseService,
    rapiCompra,
    admin,
    genericos
  },
  getters: {
    existeUsuario(state){
      if(state.usuario === null || state.usuario === '' || state.usuario === undefined ){
        return false
      }else{
        return true
      }
    }
  }
})
