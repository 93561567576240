import store from "../store"
import config from "../../config"

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async regLogRequestSize({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // controlo la validez del token para la api de jarvis
          let tokenPeticion = await store.dispatch('usuarios/executeLoginApi')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: tokenPeticion.msj
            })
          }
          let logPeticion = await fetch(config.URL_API + `regLogRequestSize`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            },
            body: JSON.stringify(datos)
          })
          let log = await logPeticion.json()
          log.msj = log.message
          return resolve(log)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo regLogRequestSize: ' + error.message,
            dias: null
          })
        }
      })
    },
    async getSocialContacts({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let peticionContacto = await fetch(config.URL_CELUSHOP + 'configuraciones/contacto.json')
          let contacto = await peticionContacto.json()
          return resolve({
            resultado: 1,
            msj: 'OK',
            contacto: contacto
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrió un problema al ejecutar el método getSocialContacts: ' + error.message,
            contacto: null
          })
        }
      })
    }
  }
}