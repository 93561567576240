<template>
  <v-dialog v-model="$store.state.loadingCircular.activo" persistent width="400">
      <v-card color="#ed1841" dark>
        <v-card-title class="text-center">
          <v-spacer></v-spacer>
          {{ $store.state.loadingCircular.msj }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="text-center">
          <v-progress-circular :size="50" :width="8" color="#ffff" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {

}
</script>

<style>

</style>