<template>
  <v-app>
    <NavigationDrawer 
      v-if="$route.meta != null && $route.meta != undefined && $route.meta.requireAuth == true"
      @subir_vista="$vuetify.goTo(0)"
    />
    <v-main :style="imagen">
      <router-view/>
    </v-main>
    <!-- SnackBar Aviso -->
    <SnackBarAviso />
    <!-- Modal Loading Circular para Administrador -->
    <ModalLoadingCircular />
  </v-app>
</template>

<script>
import SnackBarAviso from './components/genericos/SnackBarAviso.vue'
import ModalLoadingCircular from './components/genericos/ModalLoadingCircular.vue';
import NavigationDrawer from './components/admin/DashBoard.vue';
import { mapActions } from 'vuex';
import router from './router'
import { VueEasyJwt } from 'vue-easy-jwt'
const jwt = new VueEasyJwt()
export default {
  name: 'App',

  data: () => ({
    
  }),
  computed: {
    imagen (){
      return [ this.$route.name === 'LoginAdmin' ? {
        'background-image' : `url(${require('./assets/login.webp')})`,
        'background-position' : 'center',
        'background-repeat' : 'no-repeat',
        'height' : '500px',
        'background-size' : 'cover'
      } : {
        'min-height' : `100%`
      } ]
    }
  },
  async beforeUpdate(){
    // asignar variables de la tienda
    this.initProyect()
  },
  created () {
    //this.initProyect()
  },
  methods: {
    ...mapActions(['loadLocalStorage']),
    async initProyect(){
      /*let dirigirLogin = 0
      // control del token de la Api de Jarvis
      let tokenPeticion = await this.$store.dispatch('usuarios/executeLoginApi')
      if (tokenPeticion.resultado == 0){
        this.$store.dispatch('executeSnackBar', {msj: tokenPeticion.msj, color: 'error', activo: true})
        dirigirLogin = 1
      }
      // control del token de autenticación de Firebase, hay token?
      if (this.$route.meta != null && this.$route.meta != undefined && this.$route.meta.requireAuth == true && dirigirLogin == 0){
        if (!localStorage.getItem('tokenAdmin')) dirigirLogin = 1
        else{
          // el token es válido?
          let token = JSON.parse(localStorage.getItem('tokenAdmin'))
          let expirado = jwt.isExpired(token)
          if (expirado == true){
            // saco de localStorage la clave del token de Firebase
            localStorage.removeItem('tokenAdmin')
            // marco dirigirLogin para que redireccione
            dirigirLogin = 1
          }
        }
        // cargo el tema?
        if (localStorage.getItem('dark') && dirigirLogin == 0) this.$vuetify.theme.dark = JSON.stringify(localStorage.getItem('dark'))
      }
      if (dirigirLogin == 1){
        // redirecciono al inicio
        if (this.$route.name != 'LoginAdmin'){
          this.$store.dispatch('executeSnackBar', {msj: 'Su sesión se encuentra vencida. Por favor, vuelva a ingresar al Sistema.', color: 'info', activo: true})
          this.$vuetify.theme.dark = false
          router.push({name: 'LoginAdmin'})
          return
        }
      }*/
      // cargo el tema dark?
      if (this.$route.meta != null && this.$route.meta != undefined && this.$route.meta.requireAuth == true && localStorage.getItem('dark')) this.$vuetify.theme.dark = JSON.parse(localStorage.getItem('dark'))
      // todo ok, paso a inicializar localStorage
      this.loadLocalStorage(this.$route)
    }
  },
  components: {
    SnackBarAviso,
    NavigationDrawer,
    ModalLoadingCircular
  },
};
</script>

<style>
  .theme--dark.v-data-table {
      /* background-color: var(--v-secondary-base) !important; */
      color: #fff;
  }
  .v-data-table tbody tr.v-data-table__selected {
    background: var(--v-rowsel-base) !important;
  }
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tbody>tr>th, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>th, .v-data-table>.v-data-table__wrapper>table>thead>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
    padding: 0 8px !important;
  }
  .cebra tr:nth-of-type(even) { /* con even empieza el priero con color mas claro y con odd empieza el segundo */
    background-color: rgba(0, 0, 0, .1);
  }
</style>
