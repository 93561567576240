import store from "../store"
import config from "../../config"
import moment from "moment"
import { cadenaVacia } from "../util/util"

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async processPayment({dispatch}, datos){
      return await new Promise(async (resolve, reject) => {
        // nro de pedido de oracle
        let pedidoOracle = '0'
        // ya me estan pasando el key del pedido?
        //let keyNewPedido = ''
        if (datos.keyPedido.toString().length > 0){
          //keyNewPedido = datos.keyPedido.toString()
          pedidoOracle = datos.keyPedido.toString()
        }
        try{
          // controlo la validez del token para la api de jarvis
          let tokenPeticion = await store.dispatch('usuarios/executeLoginApi')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: tokenPeticion.msj,
              key: pedidoOracle,//keyNewPedido,
              pedidoOracle: pedidoOracle,
              resCobro: null
            })
          }
          // token valido, me pasan el key del pedido?
          if (!cadenaVacia(pedidoOracle) && parseInt(pedidoOracle) > 0){
            // me lo pasaron, busco en la api de Jarvis a ver si existe un cobro con ese key
            let checkPeticion = await dispatch('checkCobroStatusOracle', {key_firebase: pedidoOracle/*datos.keyPedido.toString()*/, tipo_pedido: 17, importe: datos.pedidoFinal.importe, tarjeta: datos.datosCobro.tarjeta_numero})
            if (checkPeticion.resultado == 0){
              return resolve({
                resultado: 0,
                msj: checkPeticion.msj,
                key: pedidoOracle,//keyNewPedido,
                pedidoOracle: pedidoOracle,
                resCobro: null
              })
            }
            // controlo a ver si se aprobo
            if (parseInt(checkPeticion.estados[0].cant_aprobados) > 0){
              // cobro ya aprobado, devuelvo esa respuesta
              return resolve({
                resultado: 2,
                msj: 'El cobro ya fue aprobado.',
                key: pedidoOracle,//keyNewPedido,
                pedidoOracle: pedidoOracle,
                resCobro: checkPeticion.cobro
              })
            }
            // obtengo el numero de pedido si es que existiera
            let numPedidoPeticion = await store.dispatch('firebaseService/getNumPedido', {key: pedidoOracle/*datos.keyPedido.toString()*/})
            if (numPedidoPeticion.resultado == 0){
              return resolve({
                resultado: 0,
                msj: numPedidoPeticion.msj,
                key: pedidoOracle,//keyNewPedido,
                pedidoOracle: pedidoOracle,
                resCobro: null
              })
            }
            // obtuve el numero de pedido y la fecha log del pedido, controlo la diferencia de dias
            let fecha_log = moment(numPedidoPeticion.fecha_log, "YYYY-MM-DD")
            let fecha_actual = moment(new Date()).format('YYYY-MM-DD')
            fecha_actual = moment(fecha_actual, "YYYY-MM-DD")
            let diferencia = fecha_actual.diff(fecha_log, 'days')
            // si el pedido es antiguo, genero uno nuevo
            if (parseInt(diferencia) > parseInt(datos.diasPedido)) pedidoOracle = '0'
          }
          // el cliente estará bloqueado?
          let bloqueado = await dispatch('checkBlockClient', {importe: datos.pedidoFinal.importe, documento: datos.datosCobro.documento, tipo_pedido: 17, empresa: 2, pedido_numero: pedidoOracle, tarjeta: datos.datosCobro.tarjeta_numero})
          if (bloqueado.resultado == 0){
            return resolve({
              resultado: 0,
              msj: bloqueado.msj,
              key: pedidoOracle,//keyNewPedido,
              pedidoOracle: pedidoOracle,
              resCobro: null
            })
          }
          // pido los estados de los cobros
          let estados = []
          let estadosPeticion = await store.dispatch('firebaseService/getEstadosCobros')
          if (estadosPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: estadosPeticion.msj,
              key: pedidoOracle,//keyNewPedido,
              pedidoOracle: pedidoOracle,
              resCobro: null
            })
          }
          estados = estadosPeticion.estados
          // agregado el 07-06-2023 por MMURILLO, genero aquí la secuencia del nro de pedido de celushop si es que sigue en 0
          if (parseInt(pedidoOracle) == 0){
            // paso a generar un nuevo nro de pedido
            let nroPedPeticion =  await fetch(config.URL_API + 'adminVentas/getVtasSecuencia', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': store.state.tokenApi
              }
            })
            let nroPedido = await nroPedPeticion.json()
            if (nroPedido.resultado == 0){
              return resolve({
                resultado: 0,
                msj: nroPedido.message,
                key: pedidoOracle,//keyNewPedido,
                pedidoOracle: pedidoOracle,
                resCobro: null
              })
            }
            // se generó el nro de pedido con éxito
            pedidoOracle = nroPedido.nro_pedido
          }
          // 07-06-2023 por MMURILLO, pedidoOracle tiene el nro de venta si o si a esta altura del proceso
          // controlo si ya existe en firebase
          let numPedidoPeticion = await store.dispatch('firebaseService/getNumPedido', {key: pedidoOracle})
          if (numPedidoPeticion.resultado == 0){
            // controlo si es por token
            if (!cadenaVacia(numPedidoPeticion.msj) && numPedidoPeticion.msj.toString().indexOf('encontrar') != -1){
              return resolve({
                resultado: 0,
                msj: numPedidoPeticion.msj,
                key: pedidoOracle,//keyNewPedido,
                pedidoOracle: pedidoOracle,
                resCobro: null
              })
            }
            // el pedido no existe, paso a crearlo
            let pedidoPeticion = await store.dispatch('firebaseService/setNuevoPedidoCelushop', {
              pedido: datos.pedidoFinal,
              nro_pedido: pedidoOracle
            })
            if (pedidoPeticion.resultado == 0){
              return resolve({
                resultado: 0,
                msj: pedidoPeticion.msj,
                key: pedidoOracle,//keyNewPedido,
                pedidoOracle: pedidoOracle,
                resCobro: null
              })
            }
          }

          // if (datos.keyPedido.toString().length == 0){
          //   // guardo el pedido tal cual viene en el nodo de pedidos
            
          //   keyNewPedido = pedidoPeticion.key
          // }else{
          //   keyNewPedido = datos.keyPedido
          // }
          // // ya obtuve mi key del pedido, tengo el cliente para guardar el pedido en su nodo?
          // if (datos.pedidoFinal.uid.length > 0){
          //   let pedidoCliPeticion = await store.dispatch('firebaseService/setNuevoPedidoCliente', {uid: datos.pedidoFinal.uid, key: keyNewPedido, pedido: datos.pedidoFinal})
          //   if (pedidoCliPeticion.resultado == 0){
          //     return resolve({
          //       resultado: 0,
          //       msj: pedidoCliPeticion.msj,
          //       key: keyNewPedido,
          //       pedidoOracle: pedidoOracle,
          //       resCobro: null
          //     })
          //   }
          // }
          // CONTROLO LA KEY DE FIREBASE Y EL NUMERO DE PEDIDO DE ORACLE
          datos.datosCobro.key_boton = pedidoOracle //keyNewPedido
          // comentado el 07-06-2023 por MMURILLO por haber generado con anterioridad el nro de pedido
          //if (pedidoOracle == '0') datos.datosCobro.pedido_numero = null
          datos.datosCobro.pedido_numero = pedidoOracle
          // si grabo bien el pedido, paso a cobrar, LLAMADA A LA API PARA COBRAR
          let cobroPeticion = await fetch(config.URL_API + 'decidir/Pago', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            },
            body: JSON.stringify(datos.datosCobro)
          })
          let cobro = await cobroPeticion.json()
          // obtengo el pedido que vino en la api, comentado el 07-06-2023 por MMURILLO por haber generado el nro de pedido con anterioridad
          //if (cobro.hasOwnProperty('pedido_numero')) pedidoOracle = cobro.pedido_numero
          // FINALIZA LA LLAMADA A LA API PARA COBRAR, EVALUO LA SALIDA
          if ((Object.keys(cobro).length == 2 || Object.keys(cobro).length == 1) && cobro.hasOwnProperty('error')){
            let respCobro = {
              exito: 1,
              body: {
                id: '0',
                status: 'REJECTED',
                status_details: {
                  card_authorization_code: '0000',
                  address_validation_code: '0000',
                  ticket: '0000'
                },
                date: null
              }
            }
            let updatePeticion = await store.dispatch('firebaseService/updatePedidoOracle', {pedido: datos.pedidoFinal, resCobro: respCobro, estados: estados, key: pedidoOracle/*keyNewPedido*/, datosCobroCliente: datos.datosCobro, pedidoNum: pedidoOracle})
            if (updatePeticion.resultado == 0){
              return resolve({
                resultado: 0,
                msj: updatePeticion.msj,
                key: pedidoOracle, //keyNewPedido,
                pedidoOracle: pedidoOracle,
                resCobro: cobro
              })
            }
            return resolve({
              resultado: 0,
              msj: cobro.error.mensaje,
              key: pedidoOracle, //keyNewPedido,
              pedidoOracle: pedidoOracle,
              resCobro: cobro
            })
          }
          // de entrada no vino error, aun es posible que el cobro no se haya aprobado, actualizo el resultado en firebase
          let updatePeticion = await store.dispatch('firebaseService/updatePedidoOracle', {pedido: datos.pedidoFinal, resCobro: cobro, estados: estados, key: pedidoOracle/*keyNewPedido*/, datosCobroCliente: datos.datosCobro, pedidoNum: pedidoOracle})
          if (updatePeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: updatePeticion.msj,
              key: pedidoOracle, //keyNewPedido,
              pedidoOracle: pedidoOracle,
              resCobro: cobro
            })
          }
          // con los pedidos actualizados, respondo al cliente, recordar que resultado 1 en este metodo no significa necesariamente que el cobro fue aprobado
          return resolve({
            resultado: 1,
            msj: 'OK',
            key: pedidoOracle, //keyNewPedido,
            pedidoOracle: pedidoOracle,
            resCobro: cobro
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo processPayment: ' + error.message,
            key: pedidoOracle, //keyNewPedido,
            pedidoOracle: pedidoOracle,
            resCobro: null
          })
        }
      })
    },
    async checkCobroStatusOracle({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let statusPeticion = await fetch(config.URL_API + `/cobros/checkApiPayment?key_firebase=${datos.key_firebase}&tipo_pedido=${datos.tipo_pedido}&importe=${datos.importe}&tarjeta=${datos.tarjeta}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            }
          })
          let status = await statusPeticion.json()
          status.msj = status.message
          return resolve(status)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo checkCobroStatusOracle: ' + error.message,
            status: null
          })
        }
      })
    },
    async checkBlockClient({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let blockPeticion = await fetch(config.URL_API + `/cobros/checkBlockClient?importe=${datos.importe}&documento=${datos.documento}&tipo_pedido=${datos.tipo_pedido}&empresa=${datos.empresa}&pedido_numero=${datos.pedido_numero}&tarjeta=${datos.tarjeta}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            }
          })
          let block = await blockPeticion.json()
          block.msj = block.message
          return resolve(block)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo checkBlockClient: ' + error.message,
            status: null
          })
        }
      })
    },
    async getNuevoPedidoOracle({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let pedidoPeticion = await fetch(config.URL_API + `/cobros/newPedidoVtas?tipo_pedido=${datos.tipo_pedido}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            }
          })
          let pedido = await pedidoPeticion.json()
          pedido.msj = pedido.message
          return resolve(pedido)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getNuevoPedidoOracle: ' + error.message,
            pedido: null
          })
        }
      })
    },
    async cancelPayment({dispatch}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // controlo la validez del token para la api de jarvis
          let tokenPeticion = await store.dispatch('usuarios/executeLoginApi')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: tokenPeticion.msj
            })
          }
          // armo el body a enviar
          let bodyAnulacion = {
            transaccion_id: datos.Ipg_TransaccionID,
            empresa_id: 2,
            importe: '0'
          }
          let anulacionPeticion = await fetch(config.URL_API + 'decidir/anular', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.tokenApi
            },
            body: JSON.stringify(bodyAnulacion)
          })
          let anulacion = await anulacionPeticion.json()
          if (anulacion.exito == 1){
            return resolve({
              resultado: 1,
              msj: 'OK'
            })
          }else{
            return resolve({
              resultado: 0,
              msj: anulacion.mensaje
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo cancelPayment: ' + error.message
          })
        }
      })
    }
  }
}