<template>
  <div>
    <!-- <v-menu  :close-on-click="true" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!movil" text color="#ffffff" v-bind="attrs" v-on="on" style="margin-right:-18px;font-family: 'Montserrat', sans-serif;font-weight: 600">
          <v-icon>mdi-account</v-icon>
          <h5 class="formatoPalabras">Mi cuenta</h5>
        </v-btn>
        <v-btn v-if="movil" text color="#ffffff" v-bind="attrs" v-on="on" style="margin-right:-18px;font-family: 'Montserrat', sans-serif;font-weight: 600">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="!existeUsuario"  @click="showLoginModal = true" >
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="formatoPalabras">Login</v-list-item-title>
        </v-list-item>
        <v-list-item  v-if="existeUsuario" @click="redireccionarMisDatos">
          <v-list-item-icon>
            <v-icon>mdi-comment-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="formatoPalabras">Mis Datos</v-list-item-title>
        </v-list-item>
        <v-list-item  v-if="existeUsuario" @click="redireccionarMisPedidos">
          <v-list-item-icon>
            <v-icon>mdi-shopping</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="formatoPalabras">Mis Pedidos</v-list-item-title>
        </v-list-item>
        <v-list-item  v-if="existeUsuario" @click="cerrarSesion" >
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="formatoPalabras">Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
    <!-- Modal se adapta a pantalla chicas -->
    <v-dialog v-model="showLoginModal" max-width="80%">
      <v-card>
        <!--Login -->
        <v-card flat v-if="loginForm">
          <v-card-title class="formatoPalabras">
            <v-row justify="center">
              <v-col cols="12">
                Iniciar Sesión
              </v-col>
              <v-col cols="12" v-if="tieneMsj == true">
                <v-alert
                  outlined
                  type="info"
                  text
                  class="formatoPalabras"
                >
                  {{ msjLogin }}
                </v-alert>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-subheader light class="formatoPalabras">Ingresar con:</v-subheader>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn color="#c9302c" block class="white--text formatoPalabras" @click="iniciarConGoogle">
                  <v-icon>mdi-google-plus</v-icon>
                  Google
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn color="#286090" block class="white--text formatoPalabras" @click="iniciarConFacebook">
                  <v-icon>mdi-facebook</v-icon>
                  Facebook
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-subheader light class="formatoPalabras">o ingresa con tu E-mail</v-subheader>
            </v-row>
            <form @submit.prevent="signInEmailPassword({email: emailLogin, password:passLogin, tipo: 1})">
              <v-row style="margin-bottom: 0px; margin-top: 0px">
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                  <v-text-field
                    label="Email"
                    outlined
                    v-model="emailLogin"
                    :rules="Rules"
                    class="formatoPalabras"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                  <v-text-field
                    label="Contraseña"
                    outlined
                    type="password"
                    v-model="passLogin"
                    :rules="Rules"
                    class="formatoPalabras"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="end" style="margin-bottom: 12px">
                <a @click="shwoResetForm(emailLogin)" style="float:right;font-size:12px;" class="formatoPalabras">Olvide mi Contraseña</a>
              </v-row>
              <v-row style="margin-bottom: 0px; margin-top: 0px" v-if="authError != ''">
                <v-col cols="12">
                  <div>
                    <v-alert
                      outlined
                      type="error"
                      text
                      style="width"
                      class="formatoPalabras"
                    >
                      {{ authError }}
                    </v-alert>
                  </div>
                </v-col>
              </v-row>
              <v-row style="margin-bottom: 0px; margin-top: 0px">
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                  <v-btn color="#286090" type="submit" block class="white--text formatoPalabras">
                    Ingresar
                  </v-btn>
                </v-col>
                <v-row justify="center" style="margin-bottom: 0px; margin-top: 0px">
                  <strong class="black--text formatoPalabras">¿Aún no tenés cuenta?</strong><a @click="showRegistroLogin()" class="formatoPalabras"> Registrarme</a>
                </v-row>
              </v-row>
            </form>
          </v-card-text>
        </v-card>
        <!--Registrarse-->
        <v-card flat v-if="formRegistro">
          <v-card-title 
            class="formatoPalabras"
          >
            <v-toolbar 
              flat 
              color="primary"
            >
              <v-spacer></v-spacer>
              <v-toolbar-title class="white--text">Para crear tu cuenta necesitamos que nos proporciones algunos de tus Datos</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>
          <br/>
          <v-card-text>
            <form @submit.prevent="crearNewUsuario({email: email, password:pass1,  displayName: apellido + ' ' + nombre, tipo: 2})" style="color:black">
              <v-row>
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                  <v-text-field
                    label="Apellido"
                    outlined
                    v-model="apellido"
                    :rules="Rules"
                    class="formatoPalabras"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                  <v-text-field
                    label="Nombre"
                    outlined
                    v-model="nombre"
                    :rules="Rules"
                    class="formatoPalabras"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                  <v-text-field
                    label="Email"
                    outlined
                    v-model="email"
                    :rules="Rules"
                    class="formatoPalabras"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                  <v-text-field
                    label="Contraseña"
                    outlined
                    type="password"
                    v-model="pass1"
                    :rules="Rules"
                    class="formatoPalabras"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                  <v-text-field
                    label="Confirmar contraseña"
                    outlined
                    type="password"
                    v-model="pass2"
                    :rules="Rules"
                    class="formatoPalabras"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="authErrorReg">
                  <div>
                    <v-alert
                      outlined
                      type="error"
                      text
                      class="formatoPalabras"
                    >
                      {{ authError }}
                    </v-alert>
                  </div>
                </v-col>
                <v-col cols="12" v-if="errorRegistro">
                  <div>
                    <v-alert
                      outlined
                      type="error"
                      text
                      class="formatoPalabras"
                    >
                      {{ msjError }}
                    </v-alert>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn color="#286090" type="submit" class="white--text formatoPalabras">
                    Registrarme
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </v-card-text>
        </v-card>
        <!-- Olvide mi Contraseña -->
        <v-card flat v-if="resetForm">
          <v-card-text>
            <form @submit.prevent="resetPasswordEmail({email: resetEmail  })">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Enviar Email a:"
                    outlined
                    v-model="resetEmail"
                    :rules="Rules"
                    class="formatoPalabras"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="resetPaswordMsj != ''">
                  <div>
                    <v-alert
                      outlined
                      type="error"
                      text
                      class="formatoPalabras"
                    >
                      {{ resetPaswordMsj }}
                    </v-alert>
                  </div>
                </v-col>
                <v-col cols="12" v-if="errorResetPaswordMsj != ''">
                  <div>
                    <v-alert
                      outlined
                      type="error"
                      text
                      class="formatoPalabras"
                    >
                      {{ errorResetPaswordMsj }}
                    </v-alert>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn type="submit" color="#286090" block class="white--text formatoPalabras">
                    Enviar
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import router from '../../router'
export default {
  props: {
    activo: Boolean,
    msjLogin: String
  },
  data() {
    return {
      drawer: false,
      formRegistro: false,
      showLoginModal: false,
      showAuthError: false,
      resetForm: false,
      loginForm: true,
      authErrorReg: false,
      user: '',
      password: '',
      apellido:'',
      nombre:'',
      email:'',
      pass1:'',
      pass2:'',
      emailLogin:'',
      passLogin:'',
      resetEmail:'',
      LoginResponse:[],
      dialog: false,
      existeUsuario: false,
      Rules: [
        v => !!v || "Campo requerido.",
			],
      msjError: '',
      errorRegistro: false,
      movil: false,
      tieneMsj: false
    }
  },
  computed: {
    ...mapState(['authError', 'resetPaswordMsj', 'errorResetPaswordMsj', 'respControlUsuActivo']),
    passwordConfirmationRule(){
      return () => (this.pass1 === this.pass2) || 'La contraseña debe coincidir'
    }
  },
  created(){
    //this.iniciarUsuario()
    this.isMovil()
  },
  methods: {
    ...mapActions(['crearUsuario', 'signInGoogle', 'signInFacebook', 'signInEmailPassword', 
                  'resetPasswordEmail', 'resetAuthError', 'showLoading', 'closeLoading', 'controlUsuarioActivo']),
    /*async iniciarUsuario(){
      await this.controlUsuarioActivo({paso: 1, opcion: 1})
    },*/
    isMovil(){
			var mobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
        }
      }
      // controlo la salida de mobile
      if (Array.isArray(mobile.any())){
        if (mobile.any().length > 0){
          this.movil = true
        }
      }
    },
    shwoResetForm(email){
      this.loginForm = false
      this.resetForm = true
      this.resetEmail = email
    },
    showRegistroLogin(){
      this.loginForm = false
      this.formRegistro = true
    },
    async crearNewUsuario(userData){
      // campos vacios?
      if (this.apellido.length > 0 && this.nombre.length > 0 && this.email.length > 0 && this.pass1.length > 0 &&
          this.pass2.length > 0){
        if (this.pass1 !== this.pass2){
          this.msjError = 'Las claves no Coinciden'
          this.errorRegistro = true
          this.pass2 = ''
        }else{
          // controlo email
          if (!this.validarEmail(userData.email)){
            this.msjError = 'Formato de Email no válido'
            this.errorRegistro = true
            this.email = ''
            return
          }
          this.showLoading()
          await this.crearUsuario(userData)
        }
      }else{
        this.msjError = 'Todos los campos son obligatorios'
        this.errorRegistro = true
        return
      }
    },
    /*async cerrarSesion(){
      this.showLoading()
      await this.logout()
    },
    redireccionarMisDatos(){
      router.push({name: 'MisDatos'})
    },
    redireccionarMisPedidos(){
      router.push({name: 'MisPedidos'})
    },*/
    validarEmail(email){
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.length > 0){
        if (re.test(email) == false){
          return false
        }else{
          return true
        }
      }else{
        return false
      }
    },
    async iniciarConGoogle(){
      await this.signInGoogle()
    },
    async iniciarConFacebook(){
      await this.signInFacebook()
    }
  },
  watch: {
    existeUsuario:function(){
      //por ahora unica solucion T.T
      //window.location = route('vistas.index')
    },
    resetForm: function (){
      if(!this.resetForm ){
        this.loginForm = true
      }
      this.password = ''
      this.apellido = ''
      this.nombre = ''
      this.email = ''
      this.pass1 = ''
      this.pass2 = ''
      this.emailLogin = ''
      this.passLogin = ''
      this.showAuthError = false
    },
    formRegistro:function(){
      if(!this.formRegistro){
        this.loginForm = true
      }
      this.password = ''
      this.apellido = ''
      this.nombre = ''
      this.email = ''
      this.pass1 = ''
      this.pass2 = ''
      this.emailLogin = ''
      this.passLogin = ''
      this.showAuthError = false
    },
    showLoginModal:function(){
      if(!this.showLoginModal){
        this.password = ''
        this.apellido = ''
        this.nombre = ''
        this.email = ''
        this.pass1 = ''
        this.pass2 = ''
        this.emailLogin = ''
        this.passLogin = ''
        this.resetEmail = ''
        this.showAuthError = false
        this.formRegistro = false
        this.resetForm = false
      }
      if (this.showLoginModal == false){
        this.$emit('cerrarModalLogin', false)
      }
    },
    authError:function(){
      if(this.authError != ''){
        // cierro el loading circular
        this.closeLoading()
        this.authErrorReg = true
      }
    },
    resetPaswordMsj:function(){
      this.loginForm = true
      this.password = ''
      this.apellido = ''
      this.nombre = ''
      this.email = ''
      this.pass1 = ''
      this.pass2 = ''
      this.emailLogin = ''
      this.passLogin = ''
      this.resetEmail = ''
      this.showAuthError = false
      this.formRegistro = false
      this.resetForm = false
    },
    datosUsuario: function(){
      // cierro el loading circular
      //this.closeLoading()
      // cierro el modal de registro
      this.formRegistro = false
      // cierro modal login
      this.loginForm = false
      // refresco la pagina
      //setTimeout(() => [location.reload()], 3000)
    },
    pass2: function(){
      if (this.pass2.length > 0){
        this.errorRegistro = false
      }
    },
    /*respControlUsuActivo: async function(){
      if (Object.keys(this.respControlUsuActivo).length > 0){
        if (parseInt(this.respControlUsuActivo.opcion) === 1){
          if (parseInt(this.respControlUsuActivo.resultado) === 1){
            if (this.respControlUsuActivo.existeUsuario == true){
              this.existeUsuario = true
            }else{
              this.existeUsuario = false
            }
          }else{
            await this.logout()
            location.reload()
          }
        }
      }
    },*/
    activo: function(){
      if (this.activo == true){
        this.showLoginModal = this.activo
      }
    },
    msjLogin: function(){
      if (this.msjLogin.length > 0){
        this.tieneMsj = true
      }else{
        this.tieneMsj = false
      }
    }
  },
}
</script>

<style scoped>
  .global-container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
  }

  .card-title{ font-weight:300; }

  .btn{
    font-size: 14px;
    margin-top:-25px;
    margin-left: -15px;
    text-align: left;
  }
  .btn button{
    margin-top:-25px;
  }

  .v-application a{
    color: #1976d2
  }

  .login-form{
    width:60%;
  }

  .sign-up{
    text-align:center;
    padding:20px 0 10;
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 80%;
    margin: 0px auto;
    padding: 10px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }



  @media (max-width: 360px) {
    .modal-container {
      width: 90%;
      height: 90%;
    }
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
    width: auto;
  }

  .modal-default-button {
    float: right;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>