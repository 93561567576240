<template>
  <div>
    <!-- <v-dialog
      v-model="showCarrito"
      hide-overlay
      :content-class="itemsCarrito.length == 0 && movil == false ? 'ajusteDialog1' : 
                      itemsCarrito.length == 1 && movil == false ? 'ajusteDialog2' : 
                      itemsCarrito.length > 1 && movil == false ? 'ajusteDialog3' :
                      itemsCarrito.length == 0 && movil == true ? 'ajusteDialog4' :
                      itemsCarrito.length == 1 && movil == true ? 'ajusteDialog5' :
                      'ajusteDialog6'"
    > -->
    <!-- <v-dialog
      v-model="showCarrito"
      :hide-overlay="movil == false"
      style="margin: 0px"
      max-width="400px"
      :content-class="itemsCarrito.length == 0 && movil == false ? 'ajusteDialog1' : 
                      itemsCarrito.length == 1 && movil == false ? 'ajusteDialog2' : 
                      itemsCarrito.length > 1 && movil == false ? 'ajusteDialog3' : 'margin: 0px'"
    > -->
    <!-- Web -->
    <v-menu
      v-model="activo"
      :position-x="x"
      :position-y="y"
      absolute
      width="400px"
      v-if="movil == false"
      offset-y
      :close-on-content-click="false"
    >
      <v-card max-height="500px">
        <v-card 
          flat 
          max-height="300px"
          v-scroll.self="onScroll"
          class="overflow-y-auto"
          width="400px"
        >
          <v-card-title v-if="movil == true">
            <v-toolbar flat>
              <v-row>
                <v-toolbar-title class="formatoPalabras">
                  Mi Carrito
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  title="Cerrar Carrito"
                  @click="activo = false"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-row>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-row v-for="(articulo, index) in itemsCarrito" :key="index" style="height: 120px; margin-right: 0px">
              <v-col 
                cols="3" 
                style="padding-right: 0px; padding-left: 0px"
              >
                <v-card flat class="justificarImagen">
                  <v-img
                    :src="articulo.articuloImagenes[0].src"
                  ></v-img>
                </v-card>
              </v-col>
              <v-col cols="7" style="padding-left: 0px">
                <v-row justify="center">
                  <div v-if="articulo.articuloNombre.length<22">
                    <h4 class="formatoPalabras text-center"><strong>{{ articulo.articuloNombre.toUpperCase() }}</strong></h4>
                  </div>
                  <div class="text-center" v-else>
                    <h4 class="formatoPalabras"><strong>{{ (articulo.articuloNombre.substring(0,20) + '..').toUpperCase() }}</strong></h4>
                  </div>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="articulo.cantidad"
                      prefix="x"
                      class="formatoPalabras"
                      @blur="recalcularPrecio(articulo)"
                      :readonly="compra"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <h4 style="padding:0px; margin-top: 20px;" class="formatoPalabras">
                      {{ (parseFloat(articulo.precioNeto)).toFixed(2) }}
                    </h4>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1">
                <v-btn 
                  icon
                  color="primary"
                  @click="quitarDelCarrito(articulo)"
                  title="Quitar del Carrito"
                  :disabled="compra"
                >
                  <v-icon>
                    mdi-delete-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-card 
          height="auto" 
          flat
        >
          <v-card-text>
            <div class="text-center">
              <h5>{{ itemsCarrito.length }} Articulos(s) seleccionados</h5>
            </div>
            <div class="text-center">
              <h4><strong>TOTAL: ${{ calculaPrecioTotal }}</strong></h4>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              tile
              block
              @click="comprarArticulos()"
            >
              Comprar
            </v-btn>
          </v-card-actions>
          </v-card>
      </v-card>
    </v-menu>
    <!-- Movil -->
    <v-dialog
      v-model="activo"
      :hide-overlay="movil == false"
      style="margin: 0px"
      max-width="400px"
      v-if="movil == true"
    >
      <v-card max-height="500px">
        <v-card 
          flat 
          max-height="300px"
          v-scroll.self="onScroll"
          class="overflow-y-auto"
          max-width="400px"
        >
          <v-card-title v-if="movil == true">
            <v-toolbar flat>
              <v-row>
                <v-toolbar-title class="formatoPalabras">
                  Mi Carrito
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  title="Cerrar Carrito"
                  @click="activo = false"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-row>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-row v-for="(articulo, index) in itemsCarrito" :key="index" style="height: 120px; margin-right: 0px">
              <v-col 
                cols="3" 
                style="padding-right: 0px; padding-left: 0px"
              >
                <v-card flat class="justificarImagen">
                  <v-img
                    :src="articulo.articuloImagenes[0].src"
                  ></v-img>
                </v-card>
              </v-col>
              <v-col cols="7" style="padding-left: 0px">
                <v-row justify="center">
                  <div v-if="articulo.articuloNombre.length<22">
                    <h4 class="formatoPalabras text-center"><strong>{{ articulo.articuloNombre.toUpperCase() }}</strong></h4>
                  </div>
                  <div class="text-center" v-else>
                    <h4 class="formatoPalabras"><strong>{{ (articulo.articuloNombre.substring(0,20) + '..').toUpperCase() }}</strong></h4>
                  </div>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="articulo.cantidad"
                      prefix="x"
                      class="formatoPalabras"
                      @blur="recalcularPrecio(articulo)"
                      :readonly="compra"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <h4 style="padding:0px; margin-top: 20px;" class="formatoPalabras">
                      {{ (parseFloat(articulo.precioNeto)).toFixed(2) }}
                    </h4>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1">
                <v-btn 
                  icon
                  color="primary"
                  @click="quitarDelCarrito(articulo)"
                  title="Quitar del Carrito"
                  :disabled="compra"
                >
                  <v-icon>
                    mdi-delete-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-card 
          height="auto" 
          flat
        >
          <v-card-text>
            <div class="text-center">
              <h5>{{ itemsCarrito.length }} Articulos(s) seleccionados</h5>
            </div>
            <div class="text-center">
              <h4><strong>TOTAL: ${{ calculaPrecioTotal }}</strong></h4>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              tile
              block
              @click="comprarArticulos()"
            >
              Comprar
            </v-btn>
          </v-card-actions>
          </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import router from '../../router'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      showCarrito: false,
      scrollInvoked: 0,
      itemsCarrito: [],
      modificarPrecio: true,
      movil: false,
      compra: false
    }
  },
  created(){
    this.isMovil()
    this.compraUsuario()
  },
  computed: {
    ...mapState(['articulosCarrito']),
    calculaPrecioTotal(){
      let total = 0
      for (let id in this.itemsCarrito){
        total = (parseFloat(total) + parseFloat(this.itemsCarrito[id].precioNeto)).toFixed(2)
      }
      return total
    },
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('hideCarrito', value)
      }
    },
    x: {
      get(){
        return this.datos.x
      }
    },
    y: {
      get(){
        return this.datos.y
      }
    }
  },
  methods: {
    ...mapActions(['removeArtCarrito']),
    onScroll(){
      this.scrollInvoked++
    },
    async quitarDelCarrito(articulo){
      // debemos quitar pero en la tienda
      await this.removeArtCarrito(articulo)
    },
    recalcularPrecio(articulo){
      let listado = []
      listado = this.itemsCarrito
      this.itemsCarrito = []
      let pos = listado.indexOf(articulo)
      if (parseInt(pos) >= 0){
        // tengo stock?
        if (parseInt(articulo.stock.stockTotal) >= parseInt(articulo.cantidad)){
          // recalcular el precio
          let precioAnterior = listado[pos].articuloPrecio
          listado[pos].precioNeto = parseFloat(listado[pos].cantidad * precioAnterior).toFixed(2)
        }else{
          // vuelvo la cantidad a la cantidad maxima
          let cantidadMaxima = articulo.stock.stockTotal
          listado[pos].cantidad = cantidadMaxima
        }
        localStorage.setItem('carritoUsuario', JSON.stringify(listado))
        this.itemsCarrito = listado
      }
    },
    isMovil(){
			var mobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
        }
      }
      // controlo la salida de mobile
      if (Array.isArray(mobile.any())){
        if (mobile.any().length > 0){
          this.movil = true
        }
      }
    },
    comprarArticulos(){
      // a comprar
      router.push({path: '/carritoDetalle'})
    },
    compraUsuario(){
      if (this.$route.name.toUpperCase() === 'CHECKOUT'){
        this.compra = true
      }
    }
  },
  watch: {
    carritoVisible: function(){
      if (this.carritoVisible == true){
        // this.showCarrito = this.carritoVisible
      }
    },
    // showCarrito: function(){
    //   if (this.showCarrito == false){
    //     this.$emit('hideCarrito')
    //   }
    // },
    articulosCarrito:function(){
      this.itemsCarrito = []
      if (this.articulosCarrito.length > 0){
        for (let id in this.articulosCarrito){
          let obj = this.articulosCarrito[id]
          this.itemsCarrito.push(obj)
        }
      }
    }
  },
}
</script>

<style>
  .posCarrito{
    padding-top: inherit;
  }
  .justificarImagen{
    width: 100px;
  }
  .ajusteDialog1 {
    border-radius: 4px !important;
    margin: 24px !important;
    overflow-y: auto !important;
    pointer-events: auto !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    z-index: inherit !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
    margin-top: -300px !important;
    margin-left: auto !important;
    max-width: 400px;
  }
  .ajusteDialog2 {
    border-radius: 4px !important;
    margin: 24px !important;
    overflow-y: auto !important;
    pointer-events: auto !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    z-index: inherit !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
    margin-top: -200px !important;
    margin-left: auto !important;
    max-width: 400px;
  }
  .ajusteDialog3 {
    border-radius: 4px !important;
    margin: 24px !important;
    overflow-y: auto !important;
    pointer-events: auto !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    z-index: inherit !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
    margin-top: -50px !important;
    margin-left: auto !important;
    max-width: 400px;
  }
  /*.ajusteDialog4 {
    border-radius: 4px !important;
    margin: 24px !important;
    overflow-y: auto !important;
    pointer-events: auto !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    width: 100% !important;
    z-index: inherit !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
    
    margin-left: auto !important;
    width: 270px !important;
  }
  .ajusteDialog5 {
    border-radius: 4px !important;
    margin: 24px !important;
    overflow-y: auto !important;
    pointer-events: auto !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    width: 100% !important;
    z-index: inherit !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
    padding-top: 50px !important;
    margin-left: auto !important;
    width: 270px !important;
  }
  .ajusteDialog6 {
    border-radius: 4px !important;
    margin: 24px !important;
    overflow-y: auto !important;
    pointer-events: auto !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    width: 100% !important;
    z-index: inherit !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
    padding-top: 50px !important;
    margin-left: auto !important;
    width: 270px !important;
  }*/
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>