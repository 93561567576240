<template>
  <div>
    <!-- app bar Principal, contiene dos v app bar -->
      <v-app-bar
        fixed 
        tile
        dark
        flat
        height="auto"
        color="accent"
        @mouseleave="quitarSubMenus()"
      >
        <v-row :style="movil ? 'height:110px' : 'height:110px'">
          <!-- Nuevo Bar para redes sociales y ubicacion -->
          <!-- <v-col cols="12" style="height:40px; padding-left: 0px; padding-right: 0px; padding-top: 8px" v-if="!movil">
            <v-app-bar
              flat
              color="white"
              height="40px"
            >
              
              Botones para redes sociales
              <v-btn icon :href="contacto.whatsapp" target="_blank" color="secondary" v-show="!movil">
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>

              <v-btn icon :href="contacto.facebook" target="_blank" color="secondary" v-show="!movil">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>

              <v-btn icon :href="contacto.instagram" target="_blank" color="secondary" v-show="!movil">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <div v-if="!movil">
                <v-btn
                  title="Ubicación"
                  @click="ingresarCP"
                  v-if="!movil"
                  icon
                  width="auto"
                >
                  <v-icon color="black">mdi-map-marker-plus-outline</v-icon>
                  <h5 style="color: black" class="formatoPalabras" v-if="provinciaCliente.length > 0"><a style="color: rgb(0, 106, 253);">{{ provinciaCliente }} ({{ codigoPostalCliente }})</a></h5>
                </v-btn>
              </div>
            </v-app-bar>
          </v-col> -->
          <!-- Bar para la Busqueda de Articulos e Iconos de contacto -->
          <v-col cols="12" style="height:60px; padding-left: 0px; padding-right: 0px">
            <v-app-bar
              flat
              color="accent"
              height="60px"
            >
              <v-card
                flat
                href="/"
                style="background-color: #f2f2f2; padding-bottom: 5px"
                v-show="!movil"
              >
                <v-img
                  :src="pathLogo"
                  contain
                  style="aspect-ratio: 4.5"
                ></v-img>
              </v-card>
              <v-spacer v-if="!movil"></v-spacer>
              <!-- Uso de API de GOOGLE para ayudar a la busqueda del cliente -->
              <v-text-field
                flat
                placeholder="Buscar Artículos"
                @keyup.enter="buscarArticulo"
                v-model="articuloBuscado"
                outlined dense
                class="formatoPalabras custom-placeholer-color pt-6"
                rounded
                background-color="white"
                color="black"
              ></v-text-field>

              <!-- <v-autocomplete
                v-model="buscarArticulo"
                :items="resultadosBusqueda"
                :loading="isLoading"
                :search-input.sync="search"
                hide-no-data
                hide-selected
                outlined dense
                item-text="Description"
                label="Buscar Artículos"
                return-object
                solo-inverted
                class="formatoPalabras"
                style="padding-top: 20px;"
              ></v-autocomplete> -->

              <v-btn icon @click="buscarArticulo" color="secondary">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>

              <v-spacer></v-spacer>
              <!-- Cuenta de Usuario -->
              <div style="padding-right: 20px">
                <v-menu  :close-on-click="true" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="!movil" text color="secondary" v-bind="attrs" v-on="on" style="margin-right:-18px;font-family: 'Montserrat', sans-serif;font-weight: 600">
                      <v-icon>mdi-account</v-icon>
                      <h5 class="formatoPalabras">Mi cuenta</h5>
                    </v-btn>
                    <v-btn v-if="movil" text color="secondary" v-bind="attrs" v-on="on" style="margin-right:-18px;font-family: 'Montserrat', sans-serif;font-weight: 600">
                      <v-icon>mdi-account</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-if="!existeUsuario"  @click="showLoginModal = true" >
                      <v-list-item-icon>
                        <v-icon>mdi-login</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="formatoPalabras">Iniciar Sesion</v-list-item-title>
                    </v-list-item>
                    <v-list-item  v-if="existeUsuario" @click="redireccionarMisDatos">
                      <v-list-item-icon>
                        <v-icon>mdi-comment-account-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="formatoPalabras">Mis Datos</v-list-item-title>
                    </v-list-item>
                    <v-list-item  v-if="existeUsuario" @click="redireccionarMisPedidos">
                      <v-list-item-icon>
                        <v-icon>mdi-shopping</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="formatoPalabras">Mis Pedidos</v-list-item-title>
                    </v-list-item>
                    <v-list-item  v-if="existeUsuario" @click="cerrarSesion" >
                      <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="formatoPalabras">Cerrar Sesion</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <!-- Ubicacion -->
              <div v-if="!movil">
                <v-btn
                  title="Ubicación"
                  @click="ingresarCP"
                  v-if="!movil"
                  icon
                  width="auto"
                >
                  <v-icon color="black">mdi-map-marker-plus-outline</v-icon>
                  <h5 style="color: black" class="formatoPalabras" v-if="provinciaCliente.length > 0"><a style="color: rgb(0, 106, 253);">{{ provinciaCliente }} ({{ codigoPostalCliente }})</a></h5>
                </v-btn>
              </div>
              <!-- Carrito -->
              <v-badge
                color="primary" 
                overlap
                :value="tam"
                :content="tam"
                title="Mi Carrito"
                v-if="!movil"
              >
                <v-icon 
                  large 
                  color="black" 
                  @click="mostrarCarrito"
                >
                  mdi-cart-outline
                </v-icon>
              </v-badge>

              <v-btn icon :href="contacto.whatsapp" target="_blank" color="secondary" v-show="movil">
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>

              <v-btn icon :href="contacto.facebook" target="_blank" color="secondary" v-show="movil">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>

              <v-btn icon :href="contacto.instagram" target="_blank" color="secondary" v-show="movil">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
            </v-app-bar>
          </v-col>
          <!-- Bar para la Imagen, los Menus y el Carrito -->
          <v-col cols="12" style="height: 50px; padding-left: 0px; padding-right: 0px">
            <v-row style="margin: 0px">
              <v-app-bar
                flat
                prominent
                height="50px"
                color="white"
              >
                <!--Icono BarNav para Movil-->
                <v-app-bar-nav-icon @click.stop="mostrarMenuMovil" v-show="movil" style="color: black"></v-app-bar-nav-icon>
                <v-spacer v-show="movil"></v-spacer>
                <!--Imagen Celushop-->
                <v-card
                  flat
                  href="/"
                  style="background-color: white;"
                  height="30px"
                  v-show="movil"
                >
                  <v-img
                    :src="pathLogo"
                  ></v-img>
                </v-card>
                <v-spacer v-show="!movil"></v-spacer>
                <!-- Menu -->
                <!-- <div v-show="!movil" style="font-size: 1.5rem">
                  <v-menu
                    bottom
                    left
                    v-for="item in menus"
                    :key="item.nombre"
                    offset-y
                    open-on-hover
                  >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          class="formatoPalabras align-self-center mr-4"
                          v-bind="attrs"
                          v-on="on"
                          height="50px"
                          style="color: black;"
                          @mouseenter="desplegarSubmenu(item.nombre)"
                          @click="redireccionar(item.to)"
                        >
                          {{item.nombre}}
                          <v-icon right v-show="tieneSubmenu(item.nombre)">
                            mdi-menu-down
                          </v-icon>
                        </v-btn>
                    </template>

                    <v-list class="grey lighten-3" v-show="tieneSubmenu(item.nombre)">
                      <v-list-item
                        v-for="sub in selectedSubMenu"
                        :key="sub.nombre"
                        class="formatoPalabras"
                        @click="redireccionar(sub.to)"
                      >
                          {{ sub.nombre.toUpperCase() }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div> -->
                <div v-show="!movil" style="width: -webkit-fill-available">
                  <!-- <v-tabs
                    v-model="tab"
                    align-with-title
                    background-color="white"
                    grow
                  >
                      <v-tabs-slider color="#cc1e42"></v-tabs-slider> -->

                      <!-- <v-tab 
                        class="black--text formatoPalabras ma-0 pa-0"
                        v-for="item in menus"
                        :key="item.nombre"
                        @mouseenter="desplegarSubmenu(item.nombre)"
                        @click="redireccionar({to: item.to, event: $event})"
                        @mousedown.middle="abrirNuevaPestaña(item.to)"
                      >
                        {{item.nombre}}
                      </v-tab> -->
                      <!-- <v-tab
                        v-for="item in menus"
                        :key="item.nombre"
                      > -->
                      <!-- transition="scale-transition" -->
                      <v-row justify="space-around" class="pt-4 pl-2">
                        <v-menu
                          rounded
                          offset-y
                          v-for="item in menus"
                          :key="item.nombre"
                          open-on-hover
                          class="ml-0 mr-0 pl-0 pr-0"
                          transition="slide-y-transition"
                          origin="center center"
                        >
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn
                              class="black--text ma-0 pa-0 formatoPalabras"
                              v-bind="attrs"
                              v-on="on"
                              text
                              elevation="0"
                              @mouseenter="desplegarSubmenu(item)"
                              @mouseleave="volverColor(item)"
                              @click="redireccionar({to: item.to, event: $event})"
                              @mousedown.middle="abrirNuevaPestaña(item.to)"
                              rounded
                            >
                              <div v-if="item.color == 'red'">
                                <strong style="color: red">{{ item.nombre }}</strong>
                              </div>
                              <div v-else style="color: black">
                                {{ item.nombre }}
                              </div>
                              <!-- <v-icon right v-show="tieneSubmenu(item.nombre)" class="ml-0 pl-0">
                                mdi-menu-down
                              </v-icon> -->
                            </v-btn>
                          </template>
                          
                          <v-list v-if="conSubMenus == true">
                            <v-list-item
                              v-for="sub in item.submenu"
                              :key="sub.nombre"
                              link
                              class="formatoPalabras"
                              @mouseenter="colorearSubmenu(item, sub)"
                              @mouseleave="volverColorSubmenu(item, sub)"
                              @click="redireccionar({to: sub.to, event: $event})"
                              @mousedown.middle="abrirNuevaPestaña(item.to)"
                              v-if="sub.estado == 0"
                            >
                              <div v-if="sub.color == 2">
                                <strong><v-list-item-title v-text="sub.nombre" :style="sub.color == 1 ? 'color: black' : 'color: #fb0021'"></v-list-item-title></strong>
                              </div>
                              <div v-else>
                                <v-list-item-title v-text="sub.nombre" :style="sub.color == 1 ? 'color: black' : 'color: #fb0021'"></v-list-item-title>
                              </div>
                              <!-- {{ sub.nombre }} - {{ sub.color }} -->
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-row>
                      <!-- </v-tab> -->
                  <!-- </v-tabs> -->
                </div>
                <v-spacer></v-spacer>
                <!-- Ubicación para Web -->
                <!-- <div class="posCarrito" style="font-size: 1.5rem; padding-top: 0px" v-if="!movil">
                  <v-card flat color="white" height="45px">
                    <v-card-actions style="padding-top:0px; padding-bottom: 0px; height: 30px">
                      <v-btn
                        title="Enviar a"
                        @click="ingresarCP"
                        outlined
                        v-if="!movil"
                      >
                        <v-icon 
                          large
                          color="black"
                          small
                        >
                          mdi-map-marker-plus-outline
                        </v-icon>
                        <h5 style="color: black" class="formatoPalabras">Enviar a</h5>
                      </v-btn>
                    </v-card-actions>
                    <v-card-text style="padding-top: 0px; padding-bottom: 0px; height: 15px">
                      <h5 style="color: black" class="formatoPalabras">{{ provinciaCliente }} {{ codigoPostalCliente }}</h5>
                    </v-card-text>
                  </v-card>
                </div> -->
                <div class="posCarrito" style="font-size: 1.5rem">
                  <!-- Ubicación para Movil-->
                  <v-spacer v-show="movil"></v-spacer>
                  <v-btn icon v-if="movil" color="black" @click="ingresarCP">
                    <v-icon large>mdi-map-marker-plus-outline</v-icon>
                  </v-btn>
                  <!-- Carrito -->
                  <v-badge
                    color="primary" 
                    overlap
                    :value="tam"
                    :content="tam"
                    title="Mi Carrito"
                    v-if="movil"
                  >
                    <v-icon 
                      large 
                      color="black" 
                      @click="mostrarCarrito"
                    >
                      mdi-cart-outline
                    </v-icon>
                  </v-badge>
                </div>
              </v-app-bar>
            </v-row>
          </v-col>
        </v-row>
      </v-app-bar>
      <Carrito 
        :datos="dialogCarrito"
        @hideCarrito="ocultarCarrito"
      />
      <!-- Modal del Login -->
      <loginUsuario 
        :activo="showLoginModal"
        :msjLogin="msjInicioSesion"
        @cerrarModalLogin="setearModalLogin"
      />
  </div>
</template>

<script>
import router from '../../router'
import {mapActions, mapState} from 'vuex'
import Carrito from '../genericos/Carrito'
import loginUsuario from '../index/loginUsuario.vue'
import config from '../../../config'
import Vue from '../../main'
export default {
  props: {
    provinciaActual: String,
    codPostalActual: String
  },
  data: () => ({
    pathLogo : '/img/logo.webp',
    menus: [],
    contacto: [],
    tab: null,
    subTab: null,
    selectedMenu: '',
    articuloBuscado: '',
    submenus: [],
    selectedSubMenu: [],
    movil: false,
    tam: '0',
    showCarrito: false,
    dialogCarrito: {
      activo: false,
      x: 0,
      y: 0
    },
    provinciaCliente: '',
    codigoPostalCliente: '',
    showLoginModal: false,
    existeUsuario: false,
    msjInicioSesion: '',
    resultadosBusqueda: [],
    isLoading: false,
    search: null,
    prueba: [],
    conSubMenus: false
  }),
  components: {
    Carrito,
    loginUsuario
  },
  created () {
    this.getDataMenu()
    this.getDataSocialMedia()
    this.isMovil()
    this.getDatosEntrega()
    this.iniciarUsuario()
	},
	computed:{
    ...mapState(['respItemsMenu', 'respContacto', 'articulosCarrito', 'datosUsuario', 'respControlUsuActivo'])
	},
  methods: {
    ...mapActions(['getMenuItems', 'getSocialContacts', 'logout', 'showLoading', 'closeLoading', 'controlUsuarioActivo']),
    async getDataMenu(){
      await this.getMenuItems()
    },
    async getDataSocialMedia(){
      await this.getSocialContacts()
    },
    async iniciarUsuario(){
      await this.controlUsuarioActivo({paso: 1, opcion: 1})
    },
    buscarArticulo(){
      if (this.articuloBuscado.length > 0){
        router.push({name: 'BuscarArticulo', params: {busqueda: this.articuloBuscado}})
      }
    },
    getDatosEntrega(){
      if (localStorage.getItem('enviarA')){
        let datosEntrega = JSON.parse(localStorage.getItem('enviarA'))
        this.provinciaCliente = datosEntrega.provinciaNombre
        this.codigoPostalCliente = datosEntrega.codigoPostal
      }
    },
    tieneSubmenu(menu){
      let cont = 0
      if (this.submenus.length > 0){
        this.submenus.forEach(element => {
          if (element.menu == menu){
            cont = cont + 1
          }
        })
        if (parseInt(cont) > 0){
          this.conSubMenus = true
        }else{
          this.conSubMenus = false
        }
      }else{
        this.conSubMenus = false
      }
    },
    desplegarSubmenu(item){
      /*let pos = -1
      let id = 0
      while (pos == -1 && (id <= this.menus.length - 1)){
        if (this.menus[id].nombre.toUpperCase() == item.toUpperCase()){
          pos = id
        }
        id = id + 1
      }
      // asigno la pestaña actual
      this.tab = pos
      // completo los subMenus
      if (this.submenus.length > 0){
        let pos = -1
        let id = 0
        while ((id <= this.submenus.length - 1) && pos == -1){
          if (this.submenus[id].menu == item){
            pos = id
          }
          id = id + 1
        }
        
        if (parseInt(pos) >= 0){
          this.selectedSubMenu = this.submenus[pos].submenu
        }else{
          this.selectedSubMenu = []
        }
      }else{
        this.selectedSubMenu = []
      }
      this.subTab = 0*/
      this.selectedSubMenu = []
      if (item.hasOwnProperty('submenu')){
        if (Array.isArray(item.submenu)){
          this.selectedSubMenu = item.submenu
        }else{
          this.selectedSubMenu = []
        }
      }else{
        this.selectedSubMenu = []
      }
      // pinto de color el menu
      let pos = this.menus.indexOf(item)
      this.menus[pos].color = 'red'
      this.tieneSubmenu(item.nombre)
    },
    volverColor(item){
      let pos = this.menus.indexOf(item)
      this.menus[pos].color = 'black'
    },
    colorearSubmenu(item, sub){
      let menusAux = this.menus
      this.menus = []
      let pos1 = menusAux.indexOf(item)
      let pos2 = menusAux[pos1].submenu.indexOf(sub)
      menusAux[pos1].submenu[pos2].color = 2
      this.menus = menusAux
    },
    volverColorSubmenu(item, sub){
      let menusAux = this.menus
      this.menus = []
      let pos1 = menusAux.indexOf(item)
      let pos2 = menusAux[pos1].submenu.indexOf(sub)
      menusAux[pos1].submenu[pos2].color = 1
      this.menus = menusAux
    },
    moverSubSeleccion(item){
      let id = 0
      let pos = -1
      while ((id <= this.selectedSubMenu.length - 1) && pos == -1){
        if (this.selectedSubMenu[id].nombre.toUpperCase() == item.toUpperCase()){
          pos = id
        }
        id = id + 1
      }
      this.subTab = pos
    },
    quitarSubMenus(){
      this.selectedSubMenu = []
    },
    isMovil(){
			var mobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
        }
      }
      // controlo la salida de mobile
      if (Array.isArray(mobile.any())){
        if (mobile.any().length > 0){
          this.movil = true
        }
      }
    },
    mostrarMenuMovil(){
      this.$emit('cargarDashboardMovil')
    },
    mostrarCarrito(event){
      //this.showCarrito = true
      event.preventDefault()
      this.dialogCarrito.activo = false
      this.dialogCarrito.x = event.clientX
      this.dialogCarrito.y = event.clientY
      this.$nextTick(() => {
        this.dialogCarrito.activo = true
      })
    },
    ocultarCarrito(value){
      this.dialogCarrito.activo = value
    },
    redireccionar(redireccion){
      if (redireccion.event.ctrlKey == true){
        // abrir en una nueva pestaña
        let routeData = this.$router.resolve({path: redireccion.to});
        window.open(routeData.href, '_blank');
      }else{
        if (this.$route.path.toString() == redireccion.to.toString()){
          window.location.reload()
        }else{
          router.push({path: redireccion.to})
        }
      }
    },
    abrirNuevaPestaña(to){
      let routeData = this.$router.resolve({path: to});
      window.open(routeData.href, '_blank');
    },
    ingresarCP(){
      this.$emit('setDomicilioEntrega', true)
    },
    redireccionarMisDatos(){
      router.push({name: 'MisDatos'})
    },
    redireccionarMisPedidos(){
      router.push({name: 'MisPedidos'})
    },
    async cerrarSesion(){
      this.showLoading()
      await this.logout()
    },
    setearModalLogin(valor){
      this.showLoginModal = valor
    }
  },
  watch: {
    respItemsMenu: function(){
      if (Object.keys(this.respItemsMenu).length > 0){
        if (parseInt(this.respItemsMenu.resultado) === 1){
          // guardo mi array de menus
          /*this.menus = this.respItemsMenu.items.map(function (task, index, array) {
            return task.nombre
          })*/
          this.menus = this.respItemsMenu.items
          // agrego el color
          for (let id in this.menus){
            // a los menus
            this.menus[id].color = '#ffffff'
            // a los submenus
            if (this.menus[id].hasOwnProperty('submenu')){
              if (Array.isArray(this.menus[id].submenu)){
                if (this.menus[id].submenu.length > 0){
                  for (let jd in this.menus[id].submenu){
                    this.menus[id].submenu[jd].color = 1
                  }
                }
              }
            }
          }
          // ordeno el menu por orden
          this.menus.sort(function(a,b){
            if (parseInt(a.orden) > parseInt(b.orden)) {
              return 1;
            }
            if (parseInt(a.orden) < parseInt(b.orden)) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          // guardo mi array de submenus
          let i
          for (i in this.respItemsMenu.items){
            if (this.respItemsMenu.items[i].hasOwnProperty('submenu')){
              let sub = {
                menu: this.respItemsMenu.items[i].nombre,
                submenu: this.respItemsMenu.items[i].submenu
              }
              this.submenus.push(sub)
            }
          }
          this.$emit('obtenerMenus', {menus: this.menus, subMenus: this.submenus})
        }else{
          console.log('te falle we items menu')
        }
      }
    },
    respContacto: function(){
      if (Object.keys(this.respContacto).length > 0){
        if (parseInt(this.respContacto.resultado) === 1){
          this.contacto = this.respContacto.contacto
        }else{
          console.log('te falle we contactos')
        }
      }
    },
    articulosCarrito: function(){
      if (this.articulosCarrito.length > 0){
        this.tam = this.articulosCarrito.length
      }else{
        this.tam = '0'
      }
    },
    $route:{
      deep:true,
      handler(to,from){
        if (to.name == from.name && to.path !== from.path){
          this.$emit('reloadPage')
        }
      }
    },
    provinciaActual: function(){
      this.provinciaCliente = this.provinciaActual
    },
    codPostalActual: function(){
      if (this.codPostalActual.length > 0){
        this.codigoPostalCliente = this.codPostalActual
      }
    },
    datosUsuario: function(){
      // cierro el loading circular
      this.closeLoading()
      // cierro el modal de registro
      //this.formRegistro = false
      // cierro modal login
      //this.loginForm = false
      // refresco la pagina
      setTimeout(() => [location.reload()], 3000)
    },
    respControlUsuActivo: async function(){
      if (Object.keys(this.respControlUsuActivo).length > 0){
        if (parseInt(this.respControlUsuActivo.opcion) === 1){
          if (parseInt(this.respControlUsuActivo.resultado) === 1){
            if (this.respControlUsuActivo.existeUsuario == true){
              this.existeUsuario = true
            }else{
              this.existeUsuario = false
            }
          }else{
            await this.logout()
            location.reload()
          }
        }
      }
    }/*,
    async search(val){
      try{
        this.resultadosBusqueda = []
        // Lazily load input items
        fetch(config.GOOGLE_API_IP + `showFilters=true&limit=10&api_version=2&q=${this.search}`)
          .then(response => response.json())
          .then(response => {
            let datos = response.suggested_queries
            if (datos.length){
              for (let id in datos){
                this.resultadosBusqueda.push(datos[id].q)
              }
            }
          })
          .catch(err => {
            console.warn('Something went wrong.', err)
          })
      }catch(error){
        console.log('error en la peticion a la api de autocompletado: ' + error.message)
      }
    }*/
  },

}
</script>

<style>
  .tabsCelushop{
    background-color: blue;
  }
  .v-toolbar__extension{
    justify-content: center;
  }
  /*.v-text-field.v-text-field--solo .v-input__control {
    min-height: 32px !important;
    padding: 0;
  }
  .v-toolbar__content .v-btn.v-btn--icon.v-size--default, .v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
    height: 32px !important;
    width: 48px;
  }*/
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
  .menuSinResaltar{
    text-decoration: none;
  }
  .v-toolbar__content, .v-toolbar__extension {
    padding: 4px 12px !important;
  }
  .posCarrito{
    padding-top: inherit;
  }
  .custom-placeholer-color input::placeholder {
    color: black!important;
    opacity: 1;
  }

  .custom-label-color .v-label {
    color: black;
    opacity: 1;
  }

  .custom-placeholer-color input,
  .custom-label-color input{
    color: black!important;
  }
  .justificarImagen{
    width: auto;
    height: auto;
  }
  .v-btn__content {
    margin: 10px;
  }
</style>