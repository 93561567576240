<template>
  <div style="background-color: #f2f2f2">
  <div>
    <Menu 
      :provinciaActual="provinciaNombre"
      :codPostalActual="codigoPostal"
      @cargarDashboardMovil="desplegarMenuMovil"
      @obtenerMenus="asignarMenus"
      @setDomicilioEntrega="ingresarDomEntrega"
    />
  </div>
  <div :style="$vuetify.breakpoint.width > 960 ? 'padding-top: 120px' : 'padding-top: 120px'">
    <contenidoIndex />
  </div>
  <div>
    <Footer 
      :menus="menuHome"
    />
    <DashboardMovil 
      :drawerParam="drawerHome"
      :groupParam="groupHome"
      :menus="menuHome"
      :subMenus="subMenuHome"
      @closeMenuMovil="cerrarMenuMovil"
    />
    <domicilioEntrega 
      :activo="dialogDomEntrega"
      @closeDomicilioEntrega="ingresarDomEntrega"
      @setDatosEntrega="setearDatosEntrega"
    />
  </div>
  </div>
</template>

<script>
  import Menu from '../components/index/Menu'
  import Footer from '../components/index/Footer'
  import contenidoIndex from '../components/index/contenidoIndex'
  import DashboardMovil from '../components/movil/DashboardMovil.vue'
  import domicilioEntrega from '../components/index/domicilioEntrega.vue'
  export default {
    name: 'Home',
    data() {
      return {
        drawerHome: false,
        groupHome: null,
        menuHome: [],
        subMenuHome: [],
        dialogDomEntrega: false,
        provinciaNombre: '',
        codigoPostal: ''
      }
    },
    components: {
      Menu,
      Footer,
      contenidoIndex,
      DashboardMovil,
      domicilioEntrega
    },
    methods: {
      desplegarMenuMovil(){
        this.drawerHome = true
      },
      cerrarMenuMovil(group){
        this.drawerHome = false
        this.groupHome = group
      },
      asignarMenus(menus){
        this.menuHome = menus.menus
        this.subMenuHome = menus.subMenus
      },
      ingresarDomEntrega(bandDialog){
        this.dialogDomEntrega = bandDialog
      },
      setearDatosEntrega(){
        let datosEntrega = JSON.parse(localStorage.getItem('enviarA'))
        this.provinciaNombre = datosEntrega.provinciaNombre
        this.codigoPostal = datosEntrega.codigoPostal
      }
    },
    watch: {

    },
  }
</script>
