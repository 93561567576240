import store from "../store"
import config from "../../config"
import router from "../router"

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getMenusAdmin({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let dashPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/menu_admin.json?orderBy="inhabilitado"&equalTo=0')
          let dash = await dashPeticion.json()
          dash = Object.values(dash)
          for (let id in dash){
            if (!dash[id].hasOwnProperty('submenus')) dash[id].submenus = []
            if (!dash[id].hasOwnProperty('to')) dash[id].to = null
          }
          return resolve({
            resultado: 1,
            msj: 'OK',
            menus: dash
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getMenusAdmin: ' + error.message
          })
        }
      })
    }
  }
}