import config from '../../config'

export function roundNumber(num, scale) {
  if(!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale)  + "e-" + scale);
  } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
          sig = "+";
      }
      return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}

export function format_money (money) {
    if (!money && money != 0) return ''
    return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(money)
}

export function textoVacio(cadena){
    if (cadena == null || cadena == undefined || cadena.toString().length == 0) return true
    else return false
}

export function objetoVacio(obj){
    if (obj == null || obj == undefined || typeof obj != 'object' || Object.keys(obj).length == 0) return true
    else return false
}

export function cadenaVacia(cadena){
    if (cadena == null || cadena == undefined || cadena.toString().length == 0) return true
    else return false
  }