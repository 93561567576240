module.exports = {
  NODE_ENV: process.env.NODE_ENV || 'development',
  HOST: process.env.HOST || '127.0.0.1',
  PORT: process.env.PORT || 3000,
  URL_CELUSHOP: 'https://celushop-5a30e.firebaseio.com/',
  URL_AUTH: 'https://identitytoolkit.googleapis.com/v1/',
  USUARIO_CATALOGO: 'CATALOGO_WEB',
  PASSWORD_CATALOGO: 'JAVAORACLE290',
  USUARIO_FIREBASE: 'ejeprueba@prueba.com.ar',
  PASSWORD_FIREBASE: 'prueba01',
  //URL_API: 'http://localhost:443/api/',
  //URL_API: 'http://10.0.0.17/api/',
  URL_API: 'https://api.borigenbetzel.com.ar/api/',
  TAM_MAX_CARRUSEL_ARTS: 25,
  KEY_API_WEB: 'AIzaSyBQe-regfvNbg9fXZz2I527YzHvGr88Z-4',
  USUARIO_CELUSHOP: 'mesa_ayuda@borigenbetzel.com.ar',
  PASSWORD_CELUSHOP: 'npmAbS212021.oP',
  GOOGLE_API_IP: 'https://http2.mlstatic.com/resources/sites/MLA/autosuggest?',
  MAIL_DESTINATARIO_CONSULTAS: 'celushoparg@gmail.com',
  //MAIL_DESTINATARIO_CONSULTAS: 'marquitos070@gmail.com',
  //GOOGLE_API_IP: 'https://corsanywhere.herokuapp.com/http://suggestqueries.google.com/complete/search?'/*,
  //GOOGLE_API_KEY: 'AIzaSyBQe-regfvNbg9fXZz2I527YzHvGr88Z-4',
  //GOOGLE_API_CX: '3576f0d4bbc4344e6'*/
  //URL_SITIO: 'http://localhost:8080/',
  URL_SITIO: 'https://celushop.com.ar/',
  //URL_API_MACRO: 'https://api.onscore-test.pcnt.io/v2', // url para pruebas
  URL_API_MACRO: 'https://api.onscore.io/v2', // url para produccion
  //TOKEN_MACRO: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJtaXNzaW9ucyI6W10sInVzZXJuYW1lIjoiY2VsdXNob3BlY29tbWVyY2Vwcm92aWRlciJ9.h7GJLML81wUPRHjdDOV3rOGDC-9rt9fcQhQ3KLGluts' // token de prueba
  //TOKEN_MACRO: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJtaXNzaW9ucyI6W10sInVzZXJuYW1lIjoiY2VsdXNob3Bwcm92aWRlciJ9.A_iYWIQGCItZG5JKRVT8EdaabpB9GBut1ZkAlLkTfHM' // token en sandbox???
  TOKEN_MACRO: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJtaXNzaW9ucyI6W10sInVzZXJuYW1lIjoiY2VsdXNob3BlY29tbWVyY2Vwcm92aWRlciJ9.wrGBr3Rg_naZW2GYQ_cvBPNg2_N7XszjgYAYSWwnGKQ' // token produccion
}