import store from "../store"
import config from "../../config"
import {Encrypt, Decrypt} from '../util/aes'
import { VueEasyJwt } from 'vue-easy-jwt'
const jwt = new VueEasyJwt()

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async executeLoginApi({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let loguear = 0
          // tengo el token ya?
          if (localStorage.getItem('datosApiJarvis')){
            // ya lo tengo al token, paso a llenar localStorage
            await store.dispatch('loadLocalStorage', {})
            // controlo a ver si el token es valido
            let invalido = jwt.isExpired(store.state.tokenApi)
            if (invalido == true){
              // quito el token expirado y marco la bandera para hacer una peicion de otro
              localStorage.removeItem('datosApiJarvis')
              // dejo vacio el token de la API
              await store.dispatch('loadLocalStorage', {})
              loguear = 1
            }
          }else{
            loguear = 1
          }
          if (loguear == 1){
            // hago un nuevo login
            let body = {
              usuario: Encrypt(config.USUARIO_CATALOGO),
              clave: Encrypt(config.PASSWORD_CATALOGO)
            }
            let loginPedido = await fetch(config.URL_API + '/user/login', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With'
              },
              body: JSON.stringify(body)
            })
            let login = await loginPedido.json()
            // tuve exito al hacer Login?
            if (parseInt(login.exito) === 1){
              // guardo el token en localstorage
              localStorage.setItem('datosApiJarvis', JSON.stringify({tokenJarvis: login.long_token}))
              // guardo el token en el store del proyecto
              await store.dispatch('loadLocalStorage', {})
              return resolve({
                resultado: 1,
                msj:'OK'
              })
            }else{
              return resolve({
                resultado: 0,
                msj:'No se pudo hacer Login en la Api.'
              })
            }
          }else{
            // ya tengo token y es válido
            return resolve({
              resultado: 1,
              msj:'OK'
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo executeLoginApi: ' + error.message
          })
        }
      })
    },
    async existsUser({dispatch}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // controlo el token de la api
          let tokenPeticion = await dispatch('executeLoginApi', {})
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: tokenPeticion.msj
            })
          }
          // peticion del usuario a la api
          let clientePeticion = await fetch(config.URL_API + `adminCelushop/getClienteCelushop?email=${datos.email}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            }
          })
          let cliente = await clientePeticion.json()
          cliente.msj = cliente.message
          return resolve(cliente)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo existsUser: ' + error.message
          })
        }
      })
    },
    async executeLoginAdmin({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          /*let headers = {
            'Content-Type': 'application/json'
          }
          let loginPeticion = await fetch(config.URL_AUTH + `accounts:signInWithPassword?key=${config.KEY_API_WEB}&email=${datos.usuario}&password=${datos.clave}&returnSecureToken=true`, {
            method: 'POST',
            headers: headers
          })
          let login = await loginPeticion.json()
          if (login.hasOwnProperty('error')){
            return resolve({
              resultado: 0,
              msj: 'El Usuario y/o Clave proporcionados son incorrectos',
              token: null
            })
          }
          // login correcto
          return resolve({
            resultado: 1,
            msj: 'OK',
            token: login.idToken
          })*/
         // hago un nuevo login
          let loginPedido = await fetch(config.URL_API + `adminCelushop/executeLoginUser?user=${datos.usuario}&pass=${datos.clave}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            }
          })
          let login = await loginPedido.json()
          return resolve(login)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo executeLoginAdmin: ' + error.message
          })
        }
      })
    }
  }
}