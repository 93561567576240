<template>
  <div class="text-center">
    <v-dialog
      v-model="visible"
      max-width="800px"
    >
      <v-card
        max-width="800px"
        flat
        v-if="movil && (provinciaCliente.length > 0 || codigoPostalCliente.length > 0)"
      >
        <v-card-title>
          Tus compras serán enviadas a:
        </v-card-title>
        <v-card-text>
          <h3 class="text-center">{{ provinciaCliente }} {{ codigoPostalCliente }}</h3>
        </v-card-text>
      </v-card>
      <v-card
        max-width="800px"
        flat
      >
        <v-card-title>Elegí dónde recibir tus Compras</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="codigoPostal"
                label="Ingrese su Código Postal"
                outlined
                class="formatoPalabras"
                @keypress.enter="usarCP"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" lg="3" xl="3" style="padding-top: 20px;">
              <v-btn 
                color="#2968c8" 
                class="white--text formatoPalabras" 
                block
                @click="usarCP"
              >
                Usar
              </v-btn>
            </v-col>
            <v-col cols="12" md="3" lg="3" xl="3" style="padding-top: 20px;">
              <v-btn 
                block 
                text 
                color="#2968c8" 
                class="formatoPalabras"
                @click="buscarCodigoPostal"
              >
                No sé mi Código
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="errorCodigoPostal">
              <v-alert dense text type="info">
								{{ msjErrorCodPostal }}
							</v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog 
			persistent
			v-model="dialogCP"
			width="500px"
		>
			<v-card 
				flat
				width="500px"
			>
				<v-card-title>Buscando Código Postal</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-autocomplete
								v-model="selectedProvincia"
								:items="provincias"
								:rules="Rules"
								return-object
								clearable
								outlined dense
								label="Provincia"
								item-text="provincia_nombre"
								placeholder="Seleccione una Provincia"
								@change="buscarLocalidades()"
							></v-autocomplete>
						</v-col>
						<v-col cols="12">
							<v-autocomplete
								v-model="selectedLocalidad"
								:items="localidades"
								:rules="Rules"
								return-object
								clearable
								outlined dense
								label="Ciudad"
								item-text="localidad_nombre"
								placeholder="Seleccione su Ciudad"
							></v-autocomplete>
						</v-col>
            <v-col cols="12" v-if="errorDomicilioEntrega">
              <v-alert dense text type="info">
								{{ msjErrorDomEntrega }}
							</v-alert>
            </v-col>
					</v-row>
					<v-row align="center" justify="center">
						<v-btn color="success" @click="setUbicacionEntrega()" style="margin: 5px">
							Seleccionar Localidad
						</v-btn>
						<v-btn color="warning" @click="dialogCP = false" style="margin: 5px">
							Volver
						</v-btn>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
    <!-- Modal Seleccionar Provincia -->
    <ModalSeleccionar 
      :datos="dialogSelectProv"
      @setearModalSelectProv="setModalSelectProv"
      @setSelectProvincia="setProvincia"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import ModalSeleccionar from '../genericos/ModalSeleccionarProv.vue'
export default {
  props: {
    activo: Boolean
  },
  components: {
    ModalSeleccionar,
  },
  data() {
    return {
      visible: false,
      codigoPostal: '',
      selectedProvincia: {},
      provincias: [],
      selectedLocalidad: {},
      localidades: [],
      dialogCP: false,
      Rules: [
        v => !!v || "Campo requerido.",
			],
      errorDomicilioEntrega: false,
      msjErrorDomEntrega: '',
      errorCodigoPostal: false,
      msjErrorCodPostal: '',
      provinciaCliente: '',
      codigoPostalCliente: '',
      movil: false,
      dialogSelectProv: {
        activo: false,
        provincias: []
      }
    }
  },
  computed: {
    ...mapState(['respProvincias', 'loadingCarga', 'respLocalidades', 'respDatosEntregaCliente'])
  },
  created () {
    this.obtenerProvincias()
    this.getDatosEntrega()
    this.isMovil()
  },
  methods: {
    ...mapActions(['getProvincias', 'showLoading', 'closeLoading', 'getLocalidades', 'setDatosEntrega',
                  'getDatosEntregaXCodPostal']),
    async usarCP(){
      if (this.codigoPostal.length > 0){
        // cuidado, existe en localStorage?
        if (localStorage.getItem('enviarA')){
          // el código postal coincide con el que esta ingresando el usuario?
          let datosEntrega = JSON.parse(localStorage.getItem('enviarA'))
          let codPostal = datosEntrega.codigoPostal
          if (parseInt(this.codigoPostal) === parseInt(codPostal)){
            // tomo los datos de localStorage
            this.visible = false
            this.provinciaCliente = datosEntrega.provinciaNombre
            this.codigoPostalCliente = datosEntrega.codigoPostal
            this.$emit('setDatosEntrega')
          }else{
            // debo determinar los datos de entrega a partir del codigo postal
            localStorage.removeItem('enviarA')
            this.showLoading()
            await this.getDatosEntregaXCodPostal({codigoPostal: this.codigoPostal, opcion: 1})
          }
        }else{
          // debo determinar los datos de entrega a partir del codigo postal
          this.showLoading()
          await this.getDatosEntregaXCodPostal({codigoPostal: this.codigoPostal, opcion: 1})
        }
      }else{
        this.msjErrorCodPostal = 'Debe ingresar en Código Postal válido'
        this.errorCodigoPostal = true
      }
    },
    buscarCodigoPostal(){
      this.errorDomicilioEntrega = false
      this.selectedProvincia = {}
      this.selectedLocalidad = {}
      this.dialogCP = true
    },
    async buscarLocalidades(){
      if (Object.keys(this.selectedProvincia).length > 0){
        this.showLoading()
        await this.getLocalidades(this.selectedProvincia)
      }
    },
    setUbicacionEntrega(){
      // lleno los campos?
      if (Object.keys(this.selectedProvincia).length == 0 || Object.keys(this.selectedLocalidad).length == 0){
        this.msjErrorDomEntrega = 'Debe completar todos los Datos para proseguir'
        this.errorDomicilioEntrega = true
        return
      }
      this.errorDomicilioEntrega = false
      // guardo en localStorage
      if (localStorage.getItem('enviarA')){
        localStorage.removeItem('enviarA')
      }
      let datosEntrega = {
        provinciaId: this.selectedProvincia.provincia_id,
        provinciaNombre: this.selectedProvincia.provincia_nombre,
        retiroSucursal: this.selectedProvincia.retiro_sucursal,
        sucursalCodigo: this.selectedProvincia.sucursal_codigo,
        codigoPostal: this.selectedLocalidad.codigo_postal,
        entregaDomicilio: this.selectedLocalidad.entrega_domicilio,
        localidadId: this.selectedLocalidad.localidad_id,
        localidadNombre: this.selectedLocalidad.localidad_nombre
      }
      localStorage.setItem('enviarA', JSON.stringify(datosEntrega))
      // asigno el codigo postal
      this.codigoPostal = this.selectedLocalidad.codigo_postal
      this.dialogCP = false
    },
    async obtenerProvincias(){
      await this.getProvincias()
    },
    getDatosEntrega(){
      if (localStorage.getItem('enviarA')){
        let datosEntrega = JSON.parse(localStorage.getItem('enviarA'))
        this.provinciaCliente = datosEntrega.provinciaNombre
        this.codigoPostalCliente = datosEntrega.codigoPostal
      }
    },
    isMovil(){
			var mobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
        }
      }
      // controlo la salida de mobile
      if (Array.isArray(mobile.any())){
        if (mobile.any().length > 0){
          this.movil = true
        }
      }
    },
    setModalSelectProv(value){
      this.dialogSelectProv.activo = value
      if (this.dialogSelectProv.activo == false) this.dialogSelectProv.provincias = []
    },
    setProvincia(item){
      let datosEntrega = {}
      datosEntrega.provinciaId = item.provincia_id
      datosEntrega.provinciaNombre = item.provincia_nombre
      datosEntrega.retiroSucursal = item.retiro_sucursal
      datosEntrega.sucursalCodigo = item.sucursal_codigo
      datosEntrega.codigoPostal = item.codigo_postal
      datosEntrega.entregaDomicilio = ''
      datosEntrega.localidadId = ''
      datosEntrega.localidadNombre = ''
      localStorage.setItem('enviarA', JSON.stringify(datosEntrega))
      this.visible = false
      this.provinciaCliente = datosEntrega.provinciaNombre
      this.codigoPostalCliente = datosEntrega.codigoPostal
      this.$emit('setDatosEntrega')
    }
  },
  watch: {
    activo: function(){
      this.visible = this.activo
    },
    visible: function(){
      if (this.visible == false){
        this.$emit('closeDomicilioEntrega', this.visible)
      }
    },
    respProvincias: function(){
      if (Object.keys(this.respProvincias).length > 0){
        if (parseInt(this.respProvincias.resultado) === 1){
          this.provincias = this.respProvincias.provincias.provincias
        }else{
          console.log(this.respProvincias.msj)
          this.msjErrorDomEntrega = 'El servicio Búsqueda de Códigos Postales no se encuentra disponible por el momento'
          this.errorDomicilioEntrega = true
          this.selectedProvincia = {}
          this.selectedLocalidad = {}
        }
        // estoy en el inicio?
        if (this.$route.name.toUpperCase() === 'HOME'){
          // tengo datos de entrega en localStorage?
          if (!localStorage.getItem('enviarA')){
            this.visible = true
          }
        }
      }
    },
    respLocalidades: function(){
      this.closeLoading()
      if (Object.keys(this.respLocalidades).length > 0){
        if (parseInt(this.respLocalidades.resultado) === 1){
          this.localidades = this.respLocalidades.localidades.localidades
        }else{
          console.log(this.respLocalidades.msj)
          this.msjErrorDomEntrega = 'El servicio Búsqueda de Códigos Postales no se encuentra disponible por el momento'
          this.errorDomicilioEntrega = true
          this.selectedProvincia = {}
          this.selectedLocalidad = {}
        }
      }
    },
    respDatosEntregaCliente: function(){
      this.closeLoading()
      if (Object.keys(this.respDatosEntregaCliente).length > 0){
        if (parseInt(this.respDatosEntregaCliente.resultado) === 1){
          // guardo en localStorage
          if (parseInt(this.respDatosEntregaCliente.opcion) === 1){
            if (localStorage.getItem('enviarA')){
              localStorage.removeItem('enviarA')
            }
          }
          // vino solo una provincia?
          let datosEntrega = {}
          if (this.respDatosEntregaCliente.datos.provincias.length == 1){
            datosEntrega.provinciaId = this.respDatosEntregaCliente.datos.provincias[0].provincia_id
            datosEntrega.provinciaNombre = this.respDatosEntregaCliente.datos.provincias[0].provincia_nombre
            datosEntrega.retiroSucursal = this.respDatosEntregaCliente.datos.provincias[0].retiro_sucursal
            datosEntrega.sucursalCodigo = this.respDatosEntregaCliente.datos.provincias[0].sucursal_codigo
            datosEntrega.codigoPostal = this.respDatosEntregaCliente.datos.provincias[0].codigo_postal
            datosEntrega.entregaDomicilio = ''
            datosEntrega.localidadId = ''
            datosEntrega.localidadNombre = ''
          }else{
            // comentado el 11-10-2023 por MMURILLO, hacemos que el cliente elija a qué provincia pertenece
            // datosEntrega.provinciaId = ''
            // datosEntrega.provinciaNombre = ''
            // datosEntrega.retiroSucursal = ''
            // datosEntrega.sucursalCodigo = ''
            // datosEntrega.codigoPostal = this.respDatosEntregaCliente.datos.provincias[0].codigo_postal
            // datosEntrega.entregaDomicilio = ''
            // datosEntrega.localidadId = ''
            // datosEntrega.localidadNombre = ''
            this.dialogSelectProv.provincias = this.respDatosEntregaCliente.datos.provincias
            this.dialogSelectProv.activo = true
            return
          }
          if (parseInt(this.respDatosEntregaCliente.opcion) === 1){
            localStorage.setItem('enviarA', JSON.stringify(datosEntrega))
          }
          this.visible = false
          this.provinciaCliente = datosEntrega.provinciaNombre
          this.codigoPostalCliente = datosEntrega.codigoPostal
          if (parseInt(this.respDatosEntregaCliente.opcion) === 1){
            this.$emit('setDatosEntrega')
          }
        }else{
          this.msjErrorCodPostal = 'El servicio Búsqueda de Códigos Postales no se encuentra disponible por el momento'
          this.errorCodigoPostal = true
          this.codigoPostal = ''
        }
      }
    },
    codigoPostal: function(){
      if (this.codigoPostal.length > 0){
        this.errorCodigoPostal = false
      }
    }
  },
}
</script>

<style>
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>