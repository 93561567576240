<template>
  <v-snackbar
    v-model="$store.state.snackBar.activo"
    :color="$store.state.snackBar.color"
  >
    {{ $store.state.snackBar.msj }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="$store.state.snackBar.activo = false"
      >
        Cerrar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {

}
</script>

<style>

</style>