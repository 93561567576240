<template>
  <div>
    <v-dialog
      v-model="activo"
      :max-width="movil == false ? '60%' : '100%'"
    >
      <v-card
        flat
        class="formatoPalabras"
      >
        <v-card-title>
          Buscar un Prestamo
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">mdi-close-circle-outline</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="dni"
                outlined
                dense
                type="number"
                label="Documento"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nroPedido"
                outlined
                dense
                type="number"
                label="Nro de Pedido"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions :class="movil == false ? 'd-flex justify-end mx-2' : 'd-flex justify-center mx-2'">
          <v-btn color="warning" @click="activo = false" class="ma-2">Volver</v-btn>
          <v-btn color="info" @click="buscarPedido()" class="ma-2">Buscar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Aviso -->
    <modalAviso 
      :datos="dialogAviso"
      @setearModalAviso="setModalAviso"
    />
  </div>
</template>

<script>
import modalAviso from '../genericos/modalAviso.vue'
import { mapActions } from 'vuex'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    modalAviso,
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalPrestamos', value)
      }
    },
  },
  data() {
    return {
      dni: '',
      nroPedido: '',
      movil: false,
      dialogAviso: {
        activo: false,
        titulo: '',
        mensaje: ''
      }
    }
  },
  methods: {
    ...mapActions(['showLoading', 'closeLoading']),
    async buscarPedido(){
      if (this.dni == null || this.dni == undefined || this.dni == 0 || this.dni.toString().length == 0 ||
      this.nroPedido == null || this.nroPedido == undefined || this.nroPedido == 0 || this.nroPedido.toString().length == 0){
        this.dialogAviso.titulo = 'Buscar Prestamo'
        this.dialogAviso.mensaje = 'Debe ingresar su DNI y el Nro. de Pedido para realizar la búsqueda.'
        this.dialogAviso.activo = true
        return
      }
      this.showLoading()
      let prestamoPeticion = await this.$store.dispatch('rapiCompra/getPrestamoOracle', {nro_pedido: this.nroPedido, tipo_pedido: 20, dni: this.dni.toString()})
      this.closeLoading()
      if (prestamoPeticion.resultado == 0){
        this.dialogAviso.titulo = 'Buscar Prestamo'
        this.dialogAviso.mensaje = 'Ocurrió un problema general al buscar la solicitud de prestamo. Por favor, reintente más tarde.'
        this.dialogAviso.activo = true
        return
      }
      if (prestamoPeticion.prestamos.length == 0){
        this.dialogAviso.titulo = 'Buscar Prestamo'
        this.dialogAviso.mensaje = 'No se obtuvieron resultados para la búsqueda ingresada.'
        this.dialogAviso.activo = true
        return
      }
      // paso a abrir en una pestaña nueva el prestamo
      let path = '/misPrestamos/' + prestamoPeticion.prestamos[0].pedido_numero
      let routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
      this.activo = false
    },
    limpiarForm(){
      this.dni = ''
      this.nroPedido = '',
      this.movil = false
      this.dialogAviso.activo = false
      this.dialogAviso.titulo = ''
      this.dialogAviso.mensaje = ''
    },
    isMovil(){
			var mobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
        }
      }
      // controlo la salida de mobile
      if (Array.isArray(mobile.any())){
        if (mobile.any().length > 0){
          this.movil = true
        }
      }
    },
    setModalAviso(value){
      this.dialogAviso.activo = value
      if (this.dialogAviso.activo == false){
        this.dialogAviso.titulo = ''
        this.dialogAviso.mensaje = ''
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.isMovil()
    }
  },
}
</script>

<style scoped>
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>