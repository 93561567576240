import store from "../store"
import config from "../../config.js"
import moment from "moment"
import { roundNumber } from "../util/util"

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async generateRapiCompras({dispatch}, datos){
      return await new Promise(async (resolve, reject) => {
        // nro de pedido de oracle
        let pedidoOracle = '0'
        let solicitudPedidoOracle = {}
        // link de respuesta de la api de MACRO
        let link = ''
        try{
          // armo la estructura de articulos que necesito
          let arts = []
          if (datos.pedidoFinal.articulos != null && datos.pedidoFinal.articulos != undefined && typeof datos.pedidoFinal.articulos == 'object' && 
          Object.keys(datos.pedidoFinal.articulos).length > 0 && !Array.isArray(datos.pedidoFinal.articulos)){
            let unArt = {
              description: datos.pedidoFinal.articulos.nombre.toString(),
              quantity: parseInt(datos.pedidoFinal.articulos.cantidad),
              imageUrl: datos.pedidoFinal.articulos.img_principal.toString(),
              codigo: parseInt(datos.pedidoFinal.articulos.codigo_bb),
              price: roundNumber(datos.pedidoFinal.articulos.precio, 2)
            }
            arts.push(unArt)
          }
          if (datos.pedidoFinal.articulos != null && datos.pedidoFinal.articulos != undefined && Array.isArray(datos.pedidoFinal.articulos) &&
          datos.pedidoFinal.articulos.length > 0){
            for (let id in datos.pedidoFinal.articulos){
              let unArt = {
                description: datos.pedidoFinal.articulos[id].nombre.toString(),
                quantity: parseInt(datos.pedidoFinal.articulos[id].cantidad),
                imageUrl: datos.pedidoFinal.articulos[id].img_principal.toString(),
                codigo: parseInt(datos.pedidoFinal.articulos[id].codigo_bb),
                price: roundNumber(datos.pedidoFinal.articulos[id].precio, 2)
              }
              arts.push(unArt)
            }
          }
          if (arts.length == 0){
            return resolve({
              resultado: 0,
              msj: 'No se detectaron artículos en el carrito de compra. Revisar.',
              pedidoOracle: pedidoOracle,
              link: null
            })
          }
          // me pasan el nro de pedido de oracle?
          if (datos.nro_pedido != null && datos.nro_pedido != undefined && datos.nro_pedido != 0 && datos.nro_pedido.toString().length > 0){
            // me pasaron el pedido, lo asigno
            pedidoOracle = datos.nro_pedido
            // verifico si existe este pedido en solicitudes prestamos de oracle
            let prestamoPeticion = await dispatch('getPrestamoOracle', {nro_pedido: pedidoOracle, tipo_pedido: 20, dni: ''})
            if (prestamoPeticion.resultado == 0){
              // ocurrió un problema al obtener el prestamo desde la api de JARVIS
              return resolve({
                resultado: 0,
                msj: prestamoPeticion.msj,
                pedidoOracle: pedidoOracle,
                link: null
              })
            }
            
            // obtuve respuesta, pero tengo pedidos?
            if (prestamoPeticion.prestamos.length > 0) solicitudPedidoOracle = prestamoPeticion.prestamos[0]
            // existe en oracle?
            if (Object.keys(solicitudPedidoOracle).length > 0){
              // tengo el id del prestamos de MACRO? Si no lo tengo (else), ya tengo guardado en pedidoOracle el nro de pedido, por lo que terminaria de solicitar el prestamo más abajo
              if (solicitudPedidoOracle.id_prestamo != null && solicitudPedidoOracle.id_prestamo != undefined && solicitudPedidoOracle.id_prestamo.toString().length != 0){
                // se creó la solicitud de prestamo y el id del prestamo se guardo correctamente, obtengo ese prestamo, esto quiere decir tambien que se actualizo todo correctamente en firebase
                let prestamoMacro = await dispatch('getPrestamoMACRO', {id_prestamo: solicitudPedidoOracle.id_prestamo})
                if (prestamoMacro.resultado == 0){
                  return resolve({
                    resultado: 0,
                    msj: 'No se pudo obtener el estado actual del préstamo en MACRO. Por favor, refresque la página y vuelva a intentarlo.',
                    pedidoOracle: pedidoOracle,
                    link: null
                  })
                }
                // obtuve una respuesta de la api de MACRO, reviso a ver si existe
                if (prestamoMacro.prestamo.hasOwnProperty('code') && (prestamoMacro.prestamo.code == 404 || prestamoMacro.prestamo.code == 409)){
                  // no se encontró la solicitud de prestamo, paso a crear una nueva
                  pedidoOracle = 0
                }
                // el estado es distinto a aprobado o pendiente?
                if (prestamoMacro.prestamo.status != 'ACCEPTED' && prestamoMacro.prestamo.status != 'CREATED' && prestamoMacro.prestamo.status != 'FINALIZED' && parseInt(pedidoOracle) > 0){
                  return resolve({
                    resultado: 0,
                    msj: 'La solicitud de prestamo actual se encuentra expirada o ya no puede ser retomada. Por favor, refresque la página y vuelva a intentar.',
                    pedidoOracle: pedidoOracle,
                    link: null
                  })
                }
                // ya se encuentra aceptado?
                if ((prestamoMacro.prestamo.status == 'ACCEPTED' || prestamoMacro.prestamo.status == 'FINALIZED') && parseInt(pedidoOracle) > 0){
                  return resolve({
                    resultado: 1,
                    msj: 'La solicitud de prestamo actual ya se encuentra APROBADA. Por favor, proceda a consultar el estado de su pedido en link que acabamos de abrir.',
                    pedidoOracle: pedidoOracle,
                    link: config.URL_SITIO + 'misPrestamos/' + pedidoOracle.toString()
                  })
                }
                // esta creado? si lo está, los carritos son iguales?
                if (prestamoMacro.prestamo.status == 'CREATED' && solicitudPedidoOracle.estado_id == 1 && roundNumber(datos.pedidoFinal.importe, 2) == roundNumber(prestamoMacro.prestamo.cartAmount, 2) && parseInt(pedidoOracle) > 0){
                  // podria tratarse del mismo carrito, paso a comparar los carritos
                  let iguales = await dispatch('controlArticulos', {prestamo: prestamoMacro.prestamo, nro_pedido: pedidoOracle})
                  if (iguales.resultado == 0){
                    return resolve({
                      resultado: 0,
                      msj: iguales.msj,
                      pedidoOracle: pedidoOracle,
                      link: null
                    })
                  }
                  if (iguales.distintos == 1){
                    // se compararon los carritos y resulto que son distintos, debo generar otra solicitud de prestamo desde 0, para eso indico que pedidoOracle es 0
                    pedidoOracle = 0
                  }
                }
                if (prestamoMacro.prestamo.status == 'CREATED' && solicitudPedidoOracle.estado_id == 1 && roundNumber(datos.pedidoFinal.importe, 2) != roundNumber(prestamoMacro.prestamo.cartAmount, 2) && parseInt(pedidoOracle) > 0){
                  // los importes son distintos, debo crear una nueva solicitud de prestamo desde 0
                  pedidoOracle = 0
                }
                // estan pendientes?
                if (prestamoMacro.prestamo.status == 'CREATED' && solicitudPedidoOracle.estado_id == 1 && parseInt(pedidoOracle) > 0){
                  // exito
                  return resolve({
                    resultado: 1,
                    msj: 'Su prestamo fue solicitado exitosamente. Por favor, complete el formulario de MACRO para que podamos proseguir con la entrega de su compra.',
                    pedidoOracle: pedidoOracle,
                    link: prestamoMacro.prestamo.urlLenderInitFlow
                  })
                }
                // esta pendiente y el estado de oracle difiere? No deberia pasar
                if (prestamoMacro.prestamo.status == 'CREATED' && solicitudPedidoOracle.estado_id != 1 && parseInt(pedidoOracle) > 0){
                  // esta pendiente de realizar el préstamo, pero en oracle tengo mal el estado, lo actualizo primero
                  let updatePeticion = await dispatch('setEstadoSolPrestamo', {
                    estado: prestamoMacro.prestamo.status,
                    url: prestamoMacro.prestamo.urlLenderInitFlow,
                    nro_pedido: pedidoOracle
                  })
                  if (updatePeticion.resultado == 0){
                    // no se actualizó el estado del prestamo
                    return resolve({
                      resultado: 0,
                      msj: 'No se pudo actualizar el estado de la solicitud del prestamo.',
                      pedidoOracle: pedidoOracle,
                      link: null
                    })
                  }
                  // se actualizó correctamente, devuelvo exito
                  return resolve({
                    resultado: 1,
                    msj: 'OK',
                    pedidoOracle: pedidoOracle,
                    link: prestamoMacro.prestamo.urlLenderInitFlow
                  })
                }
              }  // ------> tengo pedido oracle pero no tengo datos del prestamo de macro, tengo que pasar a crear el prestamo a macro
            }else{
              // no encontré la solicitud de prestamo en oracle, paso a crear una nueva. Poner pedidoOracle en 0
              pedidoOracle = 0
            }
          }
          if (parseInt(pedidoOracle) == 0){
            // debo generar la peticion de prestamo desde 0
            let nroPedPeticion =  await fetch(config.URL_API + 'adminVentas/getVtasSecuencia', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': store.state.tokenApi
              }
            })
            let nroPedido = await nroPedPeticion.json()
            if (nroPedido.resultado == 0){
              return resolve({
                resultado: 0,
                msj: 'No se pudo generar el nuevo Pedido de Prestamo. Intente nuevamente más tarde.',
                pedidoOracle: pedidoOracle,
                link: null
              })
            }
            // se generó el nro de pedido con éxito
            pedidoOracle = nroPedido.nro_pedido
          }
          // hasta este punto, pedidoOracle si o si tiene algo, paso a guardar en Firebase
          let pedidoPeticion = await store.dispatch('firebaseService/setNuevoPedidoCelushop', {
            pedido: datos.pedidoFinal,
            nro_pedido: pedidoOracle
          })
          if (pedidoPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: 'No se pudo guardar el nuevo Pedido de prestamo. Intente nuevamente más tarde.',
              pedidoOracle: pedidoOracle,
              link: null
            })
          }
          // viene con el UID?
          if (datos.pedidoFinal.uid != null && datos.pedidoFinal.uid != undefined && datos.pedidoFinal.uid.toString().length > 0){
            // inserto el pedido en el cliente
            let usuarioPedido = await store.dispatch('firebaseService/setNuevoPedidoCliente', {
              uid: datos.pedidoFinal.uid,
              key: pedidoOracle,
              pedido: datos.pedidoFinal
            })
            if (usuarioPedido.resultado == 0){
              return resolve({
                resultado: 0,
                msj: 'No se pudo guardar el nuevo Pedido de prestamo para el Cliente. Intente nuevamente más tarde.',
                pedidoOracle: pedidoOracle,
                link: null
              })
            }
          }
          // termina creado en firebase, paso a crear la solicitud en ORACLE. Este proceso verifica si ya existe el pedido en solicitudes prestamos y la crea en caso de no existir
          // en caso de existir lo unico que hace es crear otra transaccion y marcar la solicitud como pendiente de crear por la api de MACRO
          let pedidoOraclePeticion = await dispatch('nuevaSolicitudPrestamoOracle', {
            nro_pedido: pedidoOracle,
            importe: roundNumber(datos.pedidoFinal.importe, 2),
            cliente: {
              cliente_nombre: datos.pedidoFinal.datos_facturacion.apellido + ', ' + datos.pedidoFinal.datos_facturacion.nombre,
              cliente_cuil: datos.pedidoFinal.datos_facturacion.documento,
              cliente_email: datos.pedidoFinal.datos_facturacion.email,
              cliente_telefono: datos.pedidoFinal.datos_facturacion.telefono
            },
            empresa_codigo: 2,
            tipo_pedido: 20
          })
          if (pedidoOraclePeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: 'No se pudo guardar la nueva solicitud de prestamo. Intente nuevamente más tarde.',
              pedidoOracle: pedidoOracle,
              link: null
            })
          }
          // termina de crear en ORACLE, voy a crear en la api de MACRO
          let prestamoMacroPeticion = await dispatch('generarNuevoPrestamoMACRO', {
            cartAmount: roundNumber(datos.pedidoFinal.importe, 2),
            currencyCode: 'ARS',
            merchantOrderID: pedidoOracle.toString(),
            merchantReturnSuccessURL: `${config.URL_SITIO}misPrestamos/${pedidoOracle}`,
            merchantReturnFailURL: `${config.URL_SITIO}misPrestamos/${pedidoOracle}`,
            providerMerchantId: 'pm-002',  // NRO DE COMERCIO, IDENTIFICA A NUESTRO COMERCIO EN EL SISTEMA DE MACRO
            providerOperationId: pedidoOraclePeticion.sol_prestamo_tra_id.toString(),  // ID DE TRANSACCIONES PRESTAMOS
            cartInfo: arts,
            customerInfo: {
              fullName: (datos.pedidoFinal.datos_facturacion.nombre + ' ' + datos.pedidoFinal.datos_facturacion.apellido).toString(),
              customerCode: datos.pedidoFinal.datos_facturacion.documento.toString(),
              cellphone: datos.pedidoFinal.datos_facturacion.telefono.toString(),
              email: datos.pedidoFinal.datos_facturacion.email.toString()
            }
          })
          if (prestamoMacroPeticion.resultado == 0 || (prestamoMacroPeticion.prestamo.hasOwnProperty('code') && (prestamoMacroPeticion.prestamo.code == 409 || prestamoMacroPeticion.prestamo.code == 404))){
            // no se creó la solicitud de prestamo
            return resolve({
              resultado: 0,
              msj: 'No se pudo solicitar un nuevo prestamo en la plataforma de MACRO. Intente nuevamente más tarde.',
              pedidoOracle: pedidoOracle,
              link: null
            })
          }
          // solicitud exitosa, paso a actualizar la solicitud de prestamo en ORACLE
          let updatePeticion = await dispatch('updateSolPrestamoOracle', {
            nro_pedido: pedidoOracle,
            estado: prestamoMacroPeticion.prestamo.status,
            link: prestamoMacroPeticion.prestamo.urlLenderInitFlow,
            id: prestamoMacroPeticion.prestamo.id,
            transaccion_id: pedidoOraclePeticion.sol_prestamo_tra_id
          })
          if (updatePeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: 'No se pudo actualizar los datos de la solicitud de prestamo. Intente nuevamente más tarde.',
              pedidoOracle: pedidoOracle,
              link: null
            })
          }
          link = prestamoMacroPeticion.prestamo.urlLenderInitFlow
          // exito, devuelvo el pedido
          return resolve({
            resultado: 1,
            msj: 'Su prestamo fue generado exitosamente. Por favor, complete el formulario de MACRO para que podamos proseguir con la entrega de su compra.',
            pedidoOracle: pedidoOracle,
            link: link
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrió un problema al ejecutar el método generateRapiCompras: ' + error.message,
            pedidoOracle: null,
            link: null
          })
        }
      })
    },
    async getPrestamoOracle({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let tokenPeticion = await store.dispatch('usuarios/executeLoginApi')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: tokenPeticion.msj
            })
          }
          let prestamoPeticion = await fetch(config.URL_API + `/ecommerce/getSolicitudPrestamo?nro_pedido=${datos.nro_pedido}&tipo_pedido=${datos.tipo_pedido}&dni=${datos.dni}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            }
          })
          let prestamo = await prestamoPeticion.json()
          prestamo.msj = prestamo.message
          return resolve(prestamo)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getPrestamoOracle: ' + error.message
          })
        }
      })
    },
    async generarNuevoPrestamoMACRO({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let prestamoPeticion = await fetch(config.URL_API_MACRO + `/bnpl-operations`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-TENANT': 'MACRO',
              'Authorization': 'Bearer ' + config.TOKEN_MACRO
            },
            body: JSON.stringify(datos)
          })
          let prestamo = await prestamoPeticion.json()
          return resolve({
            resultado: 1,
            msj: 'OK',
            prestamo: prestamo
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo generarNuevoPrestamoMACRO: ' + error.message,
            prestamo: null
          })
        }
      })
    },
    async getPrestamoMACRO({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let prestamoPeticion = await fetch(config.URL_API_MACRO + `/bnpl-operations/${datos.id_prestamo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-TENANT': 'MACRO',
              'Authorization': 'Bearer ' + config.TOKEN_MACRO
            }
          })
          let prestamo = await prestamoPeticion.json()
          return resolve({
            resultado: 1,
            msj: 'OK',
            prestamo: prestamo
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getPrestamoMACRO: ' + error.message,
            prestamo: null
          })
        }
      })
    },
    async setEstadoSolPrestamo({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let tokenPeticion = await store.dispatch('usuarios/executeLoginApi')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: tokenPeticion.msj
            })
          }
          let updatePeticion = await fetch(config.URL_API + `/ecommerce/setEstadoSolicitudPrestamo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            },
            body: JSON.stringify(datos)
          })
          let update = await updatePeticion.json()
          update.msj = update.message
          return resolve(update)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo setEstadoSolPrestamo: ' + error.message
          })
        }
      })
    },
    async controlArticulos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // primero obtengo el pedido de Firebase
          let pedidoPeticion = await store.dispatch('firebaseService/getUnPedidoFirebase', {nro_pedido: datos.nro_pedido})
          if (pedidoPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: pedidoPeticion.msj
            })
          }
          let artsPedido = []
          // obtuve el pedido de firebase, armo los articulos del pedido
          if (pedidoPeticion.pedido.articulos != null && pedidoPeticion.pedido.articulos != undefined && typeof pedidoPeticion.pedido.articulos == 'object' &&
          Object.keys(pedidoPeticion.pedido.articulos).length > 0 && !Array.isArray(pedidoPeticion.pedido.articulos)){
            let unArt = {
              description: pedidoPeticion.pedido.articulos.nombre.toString(),
              quantity: parseInt(pedidoPeticion.pedido.articulos.cantidad),
              imageUrl: pedidoPeticion.pedido.articulos.img_principal.toString()
            }
            artsPedido.push(unArt)
          }
          if (pedidoPeticion.pedido.articulos != null && pedidoPeticion.pedido.articulos != undefined && Array.isArray(pedidoPeticion.pedido.articulos)){
            for (let id in pedidoPeticion.pedido.articulos){
              let unArt = {
                description: pedidoPeticion.pedido.articulos[id].nombre.toString(),
                quantity: parseInt(pedidoPeticion.pedido.articulos[id].cantidad),
                imageUrl: pedidoPeticion.pedido.articulos[id].img_principal.toString()
              }
              artsPedido.push(unArt)
            }
          }
          if (artsPedido.length == 0){
            return resolve({
              resultado: 0,
              msj: 'No se pudieron determinar los artículos que tenía el préstamo originalmente.'
            })
          }
          // carrito del prestamo que obtuve de la web de MACRO
          let artsPrestamo = datos.prestamo.cartInfo
          // tienen la misma longitud?
          if (artsPrestamo.length != artsPedido.length){
            // es exito, indico que no son los mismos carritos
            return resolve({
              resultado: 1,
              msj: 'OK',
              distintos: 1
            })
          }
          // tienen la misma longitud, entonces controlo que cada articulo contenido en el prestamo de macro coincida con su respectiva cantidad en los articulos del pedido que obtuve de firebase
          let distinto = 0
          for (let id in artsPrestamo){
            let existe = artsPedido.filter(element => element.description.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == artsPrestamo[id].description.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") && parseInt(element.quantity) == parseInt(artsPrestamo[id].quantity))
            if (existe.length == 0) distinto = 1
          }
          // respondo exito
          return resolve({
            resultado: 1,
            msj: 'OK',
            distintos: distinto
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo controlArticulos: ' + error.message
          })
        }
      })
    },
    async nuevaSolicitudPrestamoOracle({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let tokenPeticion = await store.dispatch('usuarios/executeLoginApi')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: tokenPeticion.msj
            })
          }
          let nuevoPeticion = await fetch(config.URL_API + `/ecommerce/nuevaSolicitudPrestamo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            },
            body: JSON.stringify(datos)
          })
          let nuevo = await nuevoPeticion.json()
          nuevo.msj = nuevo.message
          return resolve(nuevo)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo nuevaSolicitudPrestamoOracle: ' + error.message
          })
        }
      })
    },
    async updateSolPrestamoOracle({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let tokenPeticion = await store.dispatch('usuarios/executeLoginApi')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: tokenPeticion.msj
            })
          }
          let updatePeticion = await fetch(config.URL_API + `/ecommerce/updateSolPrestamo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            },
            body: JSON.stringify(datos)
          })
          let update = await updatePeticion.json()
          update.msj = update.message
          return resolve(update)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo nuevaSolicitudPrestamoOracle: ' + error.message
          })
        }
      })
    },
    async getEstadosRapiCompra({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let tokenPeticion = await store.dispatch('usuarios/executeLoginApi')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: tokenPeticion.msj
            })
          }
          let estadosPeticion = await fetch(config.URL_API + `/ecommerce/getEstadosRapiCompra`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            }
          })
          let estados = await estadosPeticion.json()
          estados.msj = estados.message
          return resolve(estados)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getEstadosRapiCompra: ' + error.message
          })
        }
      })
    },
    async reportEntregaMACRO({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let updatePeticion = await fetch(config.URL_API_MACRO + `/bnpl-operations/${datos.id_prestamo}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'X-TENANT': 'MACRO',
              'Authorization': 'Bearer ' + config.TOKEN_MACRO
            },
            body: JSON.stringify(datos.body)
          })
          let update = await updatePeticion.json()
          if (update.hasOwnProperty('status') && update.status != null && update.status != undefined && update.status.toString().length > 0){
            return resolve({
              resultado: 1,
              msj: 'OK'
            })
          }
          if (update.hasOwnProperty('code') && update.hasOwnProperty('errorCode') && update.hasOwnProperty('httpStatusCode')){
            return resolve({
              resultado: 0,
              msj: 'Error ' + update.code + ', errorCode' + update.errorCode
            })
          }else{
            return resolve({
              resultado: 0,
              msj: 'Ocurrió un error no tratado al informar el cambio de estado a MACRO.'
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo reportEntregaMACRO: ' + error.message
          })
        }
      })
    },
    async getConfigRapi({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let configPeticion = await fetch(config.URL_CELUSHOP + 'configuraciones/rapi_compras.json')
          let configura = await configPeticion.json()
          return resolve({
            resultado: 1,
            msj: 'OK',
            settings: configura
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getConfigRapi: ' + error.message
          })
        }
      })
    }
  }
}