import store from "../store"
import config from "../../config"
import {storage, firebase} from '../firebase'
import moment from "moment"

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getEstadosCobros({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let listadoEstados = []
          let estadosPeticion = await fetch(config.URL_CELUSHOP + 'cobros_estados.json')
          let estados = await estadosPeticion.json()
          // me quedo solo con los estados que tengan estado_decidir
          for (let id in estados){
            if (estados[id].hasOwnProperty('estado_decidir')){
              listadoEstados.push(estados[id])
            }
          }
          return resolve({
            resultado: 1,
            msj: 'OK',
            estados: listadoEstados
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getEstadosCobros: ' + error.message,
            estados: null
          })
        }
      })
    },
    async setNuevoPedidoCelushop({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // defino mi variable database
          let database = firebase.database()
          let nuevo = await database.ref('pedidos/' + datos.nro_pedido).set(datos.pedido).key
          return resolve({
            resultado: 1,
            msj: 'OK',
            key: datos.nro_pedido
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo setNuevoPedidoCelushop: ' + error.message,
            key: null
          })
        }
      })
    },
    async setNuevoPedidoCliente({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // defino mi variable database
          let database = firebase.database()
          // guardo el nuevo pedido
          await database.ref(`clientes/${datos.uid}/pedidos/${datos.key}`).update(datos.pedido)
          return resolve({
            resultado: 1,
            msj: 'OK'
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo setNuevoPedidoCliente: ' + error.message
          })
        }
      })
    },
    async updatePedidoOracle({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // defino mi variable database
          let database = firebase.database()
          // obtengo el pedido
          let pedidoActualizado = datos.pedido
          // obtengo la fecha actual
          let fechaCobro = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          // obtengo el estado del cobro
          let estadoCliente = datos.estados.filter(element => element.estado_decidir.toUpperCase() == datos.resCobro.body.status.toUpperCase())
          // evaluo la llamada a la api
          if (datos.resCobro.exito == 1){
            pedidoActualizado.Comercio_codigo = null
            pedidoActualizado.Ipg_TransaccionID = datos.resCobro.body.id
            pedidoActualizado.MensajeResponse = null
            pedidoActualizado.NumeroRastreo = null
            pedidoActualizado.ProcessorReferenceCode = null
            pedidoActualizado.ProcessorReferenceNumber = null
            pedidoActualizado.ReferenceNumber = null
            pedidoActualizado.TransactionResult = datos.resCobro.body.status.toUpperCase()
            pedidoActualizado.codigo_autorizacion = datos.resCobro.body.status_details.card_authorization_code
            pedidoActualizado.comercialserviceprovider = null
            pedidoActualizado.cuotas = pedidoActualizado.forma_pago.cuota_interna
            pedidoActualizado.documento_numero = datos.datosCobroCliente.documento
            pedidoActualizado.estado = estadoCliente[0].descripcion.toUpperCase()
            pedidoActualizado.fecha_cobro = fechaCobro
            pedidoActualizado.importe = datos.datosCobroCliente.importe.substr(0, datos.datosCobroCliente.importe.length-2) + '.' + datos.datosCobroCliente.importe.substr(datos.datosCobroCliente.importe.length-2, 2)
            pedidoActualizado.lote = datos.resCobro.body.status_details.address_validation_code.substr(datos.resCobro.body.status_details.address_validation_code.length - 4, 4)
            pedidoActualizado.marca = pedidoActualizado.forma_pago.tarjeta_marca
            pedidoActualizado.numero = datos.resCobro.body.status_details.ticket
            pedidoActualizado.operacion = 'SALE'
            pedidoActualizado.orderID = datos.key
            pedidoActualizado.pais = null
            pedidoActualizado.paymenttype = null
            pedidoActualizado.processorCcv = null
            pedidoActualizado.processorEndPointID = null
            pedidoActualizado.tarjeta_numero = datos.datosCobroCliente.tarjeta_numero.substr(datos.datosCobroCliente.tarjeta_numero.length - 4, 4)
            pedidoActualizado.tdate = datos.resCobro.body.date
            pedidoActualizado.terminalID = datos.resCobro.body.id
            pedidoActualizado.titular = datos.datosCobroCliente.titular
            pedidoActualizado.tracerecipt = null
            pedidoActualizado.transaccionTime = null
            pedidoActualizado.uid = datos.pedido.uid
            pedidoActualizado.verificationservice = null
            pedidoActualizado.numero_pedido = datos.pedidoNum
            // 18-10-2023 por MMURILLO, actualizamos el estado del pedido en caso que se haya aprobado el cobro
            if (datos.resCobro.hasOwnProperty('body') && datos.resCobro.body != null && datos.resCobro.body != undefined && datos.resCobro.body.hasOwnProperty('status') &&
            datos.resCobro.body.status != null && datos.resCobro.body.status != undefined && datos.resCobro.body.status.toString().toUpperCase() == 'APPROVED'){
              pedidoActualizado.estado_pedido = {
                codigo: 1,
                estado: 'Pendiente de Envio'
              }
            }
            // actualizamos el pedido en pedidos y en el cliente en caso de ser necesario
            await database.ref(`pedidos/${datos.key}`).update(pedidoActualizado)
            if (datos.pedido.uid != null && datos.pedido.uid != undefined && datos.pedido.uid.toString().length > 0){
              // guardo el nuevo pedido
              await database.ref(`clientes/${datos.pedido.uid}/pedidos/${datos.key}`).update(pedidoActualizado)
            }
            // respuesta
            return resolve({
              resultado: 1,
              msj: 'OK'
            })
          }else{
            // no toco nada en firebase
            return resolve({
              resultado: 1,
              msj: 'OK'
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo updatePedidoOracle: ' + error.message
          })
        }
      })
    },
    async updatePedido({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // defino mi variable database
          let database = firebase.database()
          // actualizamos el pedido en pedidos y en el cliente en caso de ser necesario
          await database.ref(`pedidos/${datos.nro_pedido}`).update(datos.pedido)
          if (datos.pedido.uid != null && datos.pedido.uid != undefined && datos.pedido.uid.toString().length > 0){
            // guardo el nuevo pedido
            await database.ref(`clientes/${datos.pedido.uid}/pedidos/${datos.nro_pedido}`).update(datos.pedido)
          }
          // respuesta
          return resolve({
            resultado: 1,
            msj: 'OK'
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo updatePedidoOracle: ' + error.message
          })
        }
      })
    },
    async getNumPedido({dispatch}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // pido un token para firebase
          let fechaLog = ''
          let numPedido = '0'
          let tokenPeticion = await dispatch('excecuteLoginFirebase')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: 'No se pudo encontrar el pedido.',
              pedido: null,
              fecha_log: null
            })
          }
          // en caso de tener el token, hago la peticion del pedido
          let pedidoPeticion = await fetch(config.URL_CELUSHOP + `pedidos/${datos.key}.json?auth=${tokenPeticion.token}`)
          let pedido = await pedidoPeticion.json()
          // let logPeticion = await dispatch('genericos/regLogRequestSize', {
          //   sistema: 'CELUSHOP',
          //   request_bytes: pedidoPeticion.headers.get('Content-Length'),
          //   usuario: 'CeluShop',
          //   nodo: 'pedidos',
          //   metodo: 'getNumPedido'
          // })
          if (pedido == null || pedido == undefined){
            return resolve({
              resultado: 0,
              msj: 'El pedido no existe, no se puede proceder.',
              pedido: null,
              fecha_log: null
            })
          }
          if (Array.isArray(pedido) && pedido.length == 0){
            return resolve({
              resultado: 0,
              msj: 'El pedido no existe, no se puede proceder.',
              pedido: null,
              fecha_log: null
            })
          }
          if (typeof pedido == 'object' && Object.keys(pedido).length == 0){
            return resolve({
              resultado: 0,
              msj: 'El pedido no existe, no se puede proceder.',
              pedido: null,
              fecha_log: null
            })
          }
          // pregunto a ver si tengo la fecha log y el numero de pedido
          if (pedido.hasOwnProperty('numero_pedido')){
            numPedido = parseInt(pedido.numero_pedido)
          }
          if (pedido.hasOwnProperty('fecha_log')){
            fechaLog = pedido.fecha_log
          }
          return resolve({
            resultado: 1,
            msj: 'OK',
            pedido: numPedido,
            fecha_log: fechaLog
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getNumPedido: ' + error.message,
            pedido: null,
            fecha_log: null
          })
        }
      })
    },
    async excecuteLoginFirebase({}){
      return await new Promise(async (resolve, reject) => {
        try{
          // 01-08-2023 por MMURILLO, empezamos a pedir el token directamente a la API de JARVIS
          let tokenPeticion = await store.dispatch('usuarios/executeLoginApi')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: tokenPeticion.msj
            })
          }
          // paso a pedir el token de firebase
          let tokenFirePeticion = await fetch(config.URL_API + `adminCelushop/executeLogInCelushop`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            }
          })
          let tokenFire = await tokenFirePeticion.json()
          tokenFire.msj = tokenFire.message
          return resolve(tokenFire)
          // comentado el 01-08-2023 por MMURILLO
          /*let headers = {
            'Content-Type': 'application/json'
          }
          let loginPeticion = await fetch(config.URL_AUTH + `accounts:signInWithPassword?key=${config.KEY_API_WEB}&email=${config.USUARIO_FIREBASE}&password=${config.PASSWORD_FIREBASE}&returnSecureToken=true`, {
            method: 'POST',
            headers: headers
          })
          let login = await loginPeticion.json()
          // obtengo un objeto con los datos del usuario o bien con un error
          if (login.hasOwnProperty('error')){
            // marco la respuesta como error
            return resolve({
              resultado: 0,
              msj: 'El Usuario y/o Clave proporcionados son incorrectos',
              token: null
            })
          }else{
            // login exitoso
            return resolve({
              resultado: 1,
              msj: 'OK',
              token: login.idToken
            })
          }*/
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo excecuteLoginFirebase: ' + error.message,
            token: null
          })
        }
      })
    },
    async getUnPedidoFirebase({dispatch}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let tokenPeticion = await dispatch('excecuteLoginFirebase')
          if (tokenPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: 'No se pudo encontrar el pedido.',
              pedido: null
            })
          }
          // en caso de tener el token, hago la peticion del pedido
          let pedidoPeticion = await fetch(config.URL_CELUSHOP + `pedidos/${datos.nro_pedido}.json?auth=${tokenPeticion.token}`)
          let pedido = await pedidoPeticion.json()
          // let logPeticion = await dispatch('genericos/regLogRequestSize', {
          //   sistema: 'CELUSHOP',
          //   request_bytes: pedidoPeticion.headers.get('Content-Length'),
          //   usuario: 'CeluShop',
          //   nodo: 'pedidos',
          //   metodo: 'getUnPedidoFirebase'
          // })
          if (pedido == null || pedido == undefined){
            return resolve({
              resultado: 0,
              msj: 'El pedido no existe, no se puede proceder.',
              pedido: null
            })
          }
          return resolve({
            resultado: 1,
            msj: 'OK',
            pedido: pedido
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getUnPedidoFirebase: ' + error.message,
            pedido: null
          })
        }
      })
    },
    async updateRapiCompraFirebase({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // defino mi variable database
          let database = firebase.database()
          // actualizamos el pedido en pedidos y en el cliente en caso de ser necesario
          await database.ref(`pedidos/${datos.nro_pedido}`).update(datos.pedido)
          // cliente
          if (datos.pedido.uid != null && datos.pedido.uid != undefined && datos.pedido.uid.toString().length > 0) await database.ref(`clientes/${datos.pedido.uid}/pedidos/${datos.nro_pedido}`).update(datos.pedido)
          return resolve({
            resultado: 1,
            msj: 'OK'
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo updateRapiCompraFirebase: ' + error.message
          })
        }
      })
    }
  }
}