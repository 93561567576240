<template>
  <v-dialog
    v-model="activo"
    max-width="50%"
    persistent
  >
    <v-card
      flat
      max-width="auto"
    >
      <v-card-title>
        Seleccione una provincia para poder continuar
        <v-spacer></v-spacer>
        <v-btn icon @click="activo = false" title="Cerrar"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" class="py-0">
            <v-data-table
              class="cebra elevation-2"
              :headers="headers"
              :items="provincias"
              dense
              :search="search"
              hide-default-footer
            >
              <!-- Msj que se mostrara si no existen resultados -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  Sin Provincias para mostrar.
                </v-alert>
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="success" @click="seleccionarProvincia(item)" v-on="on">
                      <v-icon>mdi-check-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleccionar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="warning" @click="activo = false">Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalSelectProv', value)
      }
    },
    provincias: {
      get(){
        return this.datos.provincias
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Provincia', align: 'center', value: 'provincia_nombre' },
        { text: 'Código Postal', align: 'center', value: 'codigo_postal' },
        { text: 'Acciones', align: 'center', value: 'acciones' },
      ],
      search: ''
    }
  },
  methods: {
    clearForm(){
      this.search = ''
    },
    seleccionarProvincia(item){
      this.$emit('setSelectProvincia', item)
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.clearForm()
    }
  },
}
</script>

<style>

</style>