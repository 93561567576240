<template>
  <v-dialog
    v-model="activo"
    persistent
    max-width="600"
  >
    <v-card
      flat
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card-title class="formatoPalabras">
        <v-toolbar
          color="primary"
          class="white--text"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title>
            {{ titulo }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <p class="text-center formatoPalabras" style="font-size: 18px;">{{ mensaje }}</p>
      </v-card-text>
      <v-card-actions class="formatoPalabras">
        <v-row justify="center" style="margin: 0px">
          <v-btn
            style="float: right;font-size:11px"
            color="#04B404"
            dark
            @click="emitirAction()"
            class="formatoPalabras ma-2"
          >
            <v-icon small>mdi-check</v-icon>&nbsp;Aceptar
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalAviso', value)
      }
    },
    titulo: {
      get(){
        return this.datos.titulo
      }
    },
    mensaje: {
      get(){
        return this.datos.mensaje
      }
    }
  },
  methods: {
    emitirAction(){
      this.$emit('action')
      this.activo = false
    }
  },
}
</script>

<style scoped>
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>