<template>
  <!-- Dashboard -->
  <v-navigation-drawer
    v-model="drawer"
    absolute
    temporary
    style="position:fixed; top:0; left:0; overflow-y:scroll;"
  >
    <v-menu
      bottom
      left
      v-for="item in menusMovil"
      :key="item.nombre"
      offset-y
      open-on-hover
    >
      <template v-slot:activator="{ on, attrs }">
        <v-col>
          <a :href="item.to" style="text-decoration:none">
            <v-btn
              text
              class="formatoPalabras align-self-center mr-4"
              v-bind="attrs"
              v-on="on"
              height="50px"
            >
              {{item.nombre }}
            </v-btn>
          </a>
          <v-btn
            icon
            v-show="tieneSubmenu(item.nombre)" 
            @click="desplegarSubmenu(item.nombre)"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-menu-down
            </v-icon>
          </v-btn>
          <v-list class="grey lighten-3" v-show="item.mostrarSubmenu">
            <v-list-item
              v-for="sub in item.submenu"
              :key="sub.nombre"
              class="formatoPalabras"
            >
              <a :href="sub.to" style="text-decoration:none; color: black;">
                {{ sub.nombre.toUpperCase() }}
              </a>
            </v-list-item>
          </v-list>
        </v-col>
      </template>
    </v-menu>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    drawerParam: Boolean,
    groupParam: String,
    menus: Array,
    subMenus: Array
  },
  data() {
    return {
      drawer: false,
      group: null,
      selectedSubMenu: [],
      submenusMovil: [],
      menusMovil: [],
      selectedMenu: ''
    }
  },
  methods: {
    desplegarSubmenu(item){
      // esconder todos los menus
      for (let id in this.menusMovil){
        this.menusMovil[id].mostrarSubmenu = false
      }
      let tieneSubM = this.menusMovil.filter(element => element.nombre.toUpperCase() == item.toUpperCase())
      if (tieneSubM.length > 0){
        // tiene submenu?
        if (tieneSubM[0].hasOwnProperty('submenu')){
          // encuentro la posicion del objeto dentro del array
          let posMenu = 0
          for (let id in this.menusMovil){
            if (this.menusMovil[id].nombre.toUpperCase() == item.toUpperCase()){
              posMenu = id
            }
          }
          this.menusMovil[posMenu].mostrarSubmenu = true
        }
      }
    },
    tieneSubmenu(menu){
      let tieneSubM = this.menusMovil.filter(element => element.nombre.toUpperCase() == menu.toUpperCase())
      if (tieneSubM.length > 0){
        // tiene submenu?
        if (tieneSubM[0].hasOwnProperty('submenu')){
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    }
  },
  watch: {
    drawerParam: function(){
      this.drawer = this.drawerParam
    },
    drawer: function(){
      if (!this.drawer){
        if (this.group == null || this.group == undefined){
          this.group = ''
        }
        this.group = this.group.toString()
        this.$emit('closeMenuMovil', this.group)
      }
    },
    group: function(){
      if (this.group == null || this.group == undefined){
        this.group = ''
      }
      this.group = this.group.toString()
      this.$emit('closeMenuMovil', this.group)
    },
    menus: function(){
      if (this.menus.length > 0){
        this.menusMovil = this.menus
        for (let id in this.menusMovil){
          this.menusMovil[id].mostrarSubmenu = false
        }
      }
    },
    subMenus: function(){
      if (this.subMenus.length > 0){
        this.submenusMovil = this.subMenus
      }
    }
  },
}
</script>

<style>
  .btn:focus {
    outline: none;
    box-shadow: none;
  }
</style>