import store from "../store"
import config from "../../config"

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getDiasVencimientoPedidos({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let vencimientoPeticion = await fetch(config.URL_API + `ecommerce/getDiasVencPedidos?empresa=2`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            }
          })
          let vencimiento = await vencimientoPeticion.json()
          vencimiento.msj = vencimiento.message
          return resolve(vencimiento)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getDiasVencimientoPedidos: ' + error.message,
            dias: null
          })
        }
      })
    }
  }
}