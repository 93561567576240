<template>
  <v-app-bar
    color="info"
    style="z-index: 2"
    :flat="$vuetify.breakpoint.mdAndUp"
    dense
    dark
    app
  >
    <v-app-bar-nav-icon
      @click.stop="$vuetify.breakpoint.smAndDown ? drawer = !drawer : expand = !expand"
    ></v-app-bar-nav-icon>

    <span style="cursor: default;">
      {{ 
        $store.state.menuAdminNombres.find(item => item.path == $route.path) ?
        $store.state.menuAdminNombres.find(item => item.path == $route.path).nombre :
        $route.name 
      }}
    </span>

    <v-spacer></v-spacer>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :title="$vuetify.theme.dark ? 'Tema claro' : 'Tema oscuro'"
          :loading="load"
          icon
          @click="set_theme"
        >
          <v-icon>mdi-{{ $vuetify.theme.dark ? 'white-balance-sunny' : 'weather-night' }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $vuetify.theme.dark ? 'Tema Claro' : 'Tema Oscuro' }}</span>
    </v-tooltip>

    <!-- <v-menu
      max-width="300"
      transition="scale-transition"
      :origin="$vuetify.breakpoint.smAndDown ? 'center top' : 'right top'"
      :nudge-width="250"
      :nudge-left="$vuetify.breakpoint.smAndDown ? 0 : 250"
      :disabled="$route.name === 'Notificaciones'"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          title="Notificaciones"
          :disabled="$route.name === 'Notificaciones'"
          icon
        >
          <v-badge
            :content="$store.state.notificaciones.length"
            :value="$store.state.notificaciones.length"
            color="error"
            overlap
          >
            <v-icon>fas fa-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <ModalNotificaciones />
    </v-menu> -->

    <!-- <v-menu offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>fas fa-user-cog</v-icon>
        </v-btn>
      </template>
      <v-list class="py-2">
        <v-list-item
          class="my-n1"
          style="cursor: pointer"
          @click.stop="dialog_clave = true"
        >
          <v-list-item-title>
            <v-icon left small>fas fa-key</v-icon>
            Cambiar clave
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="my-n1"
          style="cursor: pointer"
          @click.stop="dialog_perfil = true"
        >
          <v-list-item-title>
            <v-icon left small>fas fa-user-circle</v-icon>
            Foto de perfil
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="my-n1"
          style="cursor: pointer"
          @click.stop="sincronizar()"
        >
          <v-list-item-title>
            <v-icon left small>fas fa-sync</v-icon>
            Sincronizar
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->

    <!-- <div
      class="d-flex align-center font-weight-medium px-2"
      style="font-size: 14px; letter-spacing: 1.374px; cursor: default;"
      :icon="$vuetify.breakpoint.smAndDown"
    >
      <v-avatar size="36">
        <v-img :src="$store.state.img_perfil === '' ? require('./assets/bb.webp') : `data:image/jpeg;base64,${$store.state.img_perfil}`"></v-img>
      </v-avatar>
      <div v-if="!$vuetify.breakpoint.smAndDown" class="ml-2">{{ $store.state.username }}</div>
    </div> -->

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="logout"
        >
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </template>
      <span>Cerrar sesión</span>
    </v-tooltip>

    <!-- <CambiarClave
      v-model="dialog_clave"
      title="Cambiar clave"
      text="Por favor ingresar una nueva clave:"
      :user="$store.state.username"
    />

    <CambiarImgPerfil
      v-model="dialog_perfil"
    /> -->

  </v-app-bar>
</template>

<script>
// import CambiarClave from './components/CambiarClave'
// import CambiarImgPerfil from './components/CambiarImgPerfil'
// import ModalNotificaciones from './components/ModalNotificaciones'
export default {
  data () {
    return {
      dialog_clave: false,
      dialog_perfil: false,
      load: false,
    }
  },
  created () {
    this.$vuetify.theme.dark = JSON.parse(localStorage.getItem('dark'))
  },
  props: {
    p_expand: Boolean,
    p_drawer: Boolean,
  },
  components: {
    // CambiarClave,
    // CambiarImgPerfil,
    // ModalNotificaciones,
  },
  computed: {
    expand: {
      get () {
        return this.p_expand
      },
      set (value) {
        this.$emit('update:p_expand', value)
      }
    },
    drawer: {
      get () {
        return this.p_drawer
      },
      set (value) {
        this.$emit('update:p_drawer', value)
      }
    },
  },
  methods: {
    async sincronizar () {
      this.$store.state.loading = true
      await this.$store.dispatch('sincronizar')
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
          window.location.reload(true)
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async set_theme () {
      this.load = true
      if (localStorage.getItem('dark')) localStorage.removeItem('dark')
      localStorage.setItem('dark', JSON.parse(!this.$vuetify.theme.dark))
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.load = false
    },
    logout () {
      this.$vuetify.theme.dark = false
      this.$store.dispatch('logoutAdmin')
    }
  }
}
</script>